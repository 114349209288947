<script>
export default {
  name: 'CarbonAiRecommend',
}
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
  >
    <path
      fill="currentColor"
      d="M18 20v-2h1v-7h-1V9h4v2h-1v7h1v2zm-3.5 0h2L13 9h-3L6.503 20h2l.601-2h4.778zm-4.794-4l1.628-5.411l.256-.003L13.264 16zm16.511 5l-1.787 3.621l-3.996.58l2.892 2.82L22.643 32l3.574-1.879L29.791 32l-.683-3.98L32 25.202l-3.996-.581z"
    />
    <path
      fill="currentColor"
      d="M17 30H0V0h30v17h-2V2H2v26h15z"
    />
  </svg>
</template>
