<template>
  <b-modal
    ref="user-review-warning-modal"
    hide-footer
    size="lg"
    title="Revisão de cadastro"
  >
    <b-alert
      show
      variant="warning"
      class="px-2 py-1 mb-1"
    >
      Pontos de atenção detectados no cadastro
    </b-alert>

    <b-list-group>
      <b-list-group-item
        v-for="(warning, index) in warnings"
        :key="index"
        class="flex-column align-items-start"
      >
        <div class="d-flex justify-content-between">
          <p>
            {{ warning.text }}
          </p>
          <small
            v-if="warning.section"
            class="ml-1"
          >
            {{ warning.section }}
          </small>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
/* eslint-disable global-require */
import {
  BAlert,
  BModal,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'
import LocalStorageService from '@/services/localStorageService'
import DnsService from '@/services/dnsService'
import {
  dnsStatuses,
  knownDomainsOptions,
} from '@/utils/options'

export default {
  components: {
    BAlert,
    BModal,
    BListGroup,
    BListGroupItem,
  },
  data: () => ({
    warnings: [],
    user: null,
    zones: [],
    segments: [],
    subsegments: [],
  }),
  created() {
    this.dnsService = new DnsService()

    this.zones = LocalStorageService.getArray(LocalStorageService.zones) ?? []

    const segments = (LocalStorageService.getArray(LocalStorageService.segments) ?? [])
    this.segments = segments.sort((a, b) => (b.parent_id ? -1 : 1)).reduce((segs, segment) => {
      if (segment.parent_id) {
        const rootSegments = segs
        const parentIndex = rootSegments.findIndex(f => f.id === segment.parent_id)

        if (parentIndex > -1) {
          rootSegments[parentIndex] = {
            ...rootSegments[parentIndex],
            subsegments: [
              ...(rootSegments[parentIndex].subsegments ?? []),
              segment,
            ],
          }
        }

        return rootSegments
      }

      return [
        ...segs,
        segment,
      ]
    }, [])
  },
  methods: {
    shouldHaveProfilePictureWhenSiteOrSocialMediaIsFilled() {
      const siteAndSocialFields = [
        'link_facebook',
        'link_instagram',
        'link_linkedin',
        'link_site',
      ]

      if (siteAndSocialFields.some(field => this.user.profile[field].trim() !== '') && !this.user.avatar) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Informações Principais',
            text: 'Não possui foto de perfil, mas possui site ou rede social.',
          },
        ]
      }
    },
    shouldHaveOneSocialMediaWhenSiteIsFilled() {
      const socialFields = [
        'link_facebook',
        'link_instagram',
        'link_linkedin',
      ]

      if (this.user.profile.link_site.trim() !== '' && socialFields.every(field => this.user.profile[field].trim() === '')) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Sites e Redes Sociais',
            text: 'Não possui redes sociais, mas possui site.',
          },
        ]
      }
    },
    shouldPreferOneState() {
      if (this.user.type !== 'representative') return

      const statesInitials = this.zones.map(zone => zone.initials)

      let statesFound = 0
      if (
        (this.user.profile.preferred_zones ?? []).some(zone => {
          if (statesInitials.includes(zone)) {
            statesFound += 1
          }

          return statesFound > 1
        })
      ) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Região e Segmento',
            text: 'Representante selecionou mais de um estado.',
          },
        ]
      }
    },
    shouldPreferAZoneWhenPreferringAState() {
      if (this.user.type !== 'representative') return
      const userStates = (this.user.profile.preferred_zones ?? []).reduce((states, zone) => {
        const zoneFound = this.zones.find(f => f.initials === zone)

        return [
          ...states,
          ...zoneFound ? [zoneFound] : [],
        ]
      }, []).map(state => ({
        ...state,
        zones: state.zones.find(zone => (this.user.profile.preferred_zones ?? []).includes(zone.id)),
      }))

      if (userStates.some(state => !state.zones)) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Região e Segmento',
            text: 'Representante possui estado sem região.',
          },
        ]
      }
    },
    shouldNotPreferAStateWithoutAZoneInIt() {
      const userSegments = (this.user.profile.preferred_segments ?? [])

      if (
        userSegments.some(userSegmentId => {
          const segmentFound = this.segments.find(f => f.id === userSegmentId)
          return segmentFound && !segmentFound.subsegments.some(subsegment => userSegments.includes(subsegment.id))
        })
      ) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Região e Segmento',
            text: 'Não foi selecionado subsegmento para um dos segmentos.',
          },
        ]
      }
    },
    async shouldDNSFromEmailNotReturnErrorAndSiteShouldExist() {
      const knownDomains = knownDomainsOptions.map(m => m.value)

      const emailMatch = this.user.email.match(/(.*)@(.*)/)
      if (!emailMatch) {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Informações Principais',
            text: 'Email sem @.',
          },
        ]

        return
      }

      const domain = emailMatch[2]

      if (knownDomains.some(knownDomain => knownDomain === domain || `${knownDomain}.br` === domain)) return

      await this.dnsService.resolve(domain).then(response => {
        const rcode = (response.rcode ?? '').toLowerCase()
        if (rcode === 'noerror') {
          if ((this.user.profile.link_site ?? '').trim() === '') {
            this.warnings = [
              ...this.warnings,
              {
                section: 'Sites e Redes Sociais',
                text: 'Aparenta ter email corporativo e não tem site preenchido.',
              },
            ]
          }

          return
        }

        const knownError = dnsStatuses.find(option => option.value.toLowerCase() === rcode)

        if (knownError) {
          this.warnings = [
            ...this.warnings,
            {
              section: 'Informações Principais',
              text: `DNS do Email: ${knownError.text}`,
            },
          ]
        } else {
          this.warnings = [
            ...this.warnings,
            {
              section: 'Informações Principais',
              text: `DNS do Email: Erro desconhecido (RCODE: ${response.rcode ?? 'Não encontrado'}`,
            },
          ]
        }
      }).catch(() => {
        this.warnings = [
          ...this.warnings,
          {
            text: 'Não foi possível verificar o DNS do Email',
          },
        ]
      })
    },
    shouldHaveKeywords() {
      if ((this.user.tags ?? '').trim() === '') {
        this.warnings = [
          ...this.warnings,
          {
            section: 'Adicionais',
            text: 'Palavras-chave não preenchidas',
          },
        ]
      }
    },
    async verify(user, formZone = 'all') {
      if (!user) return false

      this.warnings = []
      this.user = user

      if (['all', 'socials', 'primary'].includes(formZone)) {
        await this.shouldDNSFromEmailNotReturnErrorAndSiteShouldExist()
        this.shouldHaveProfilePictureWhenSiteOrSocialMediaIsFilled()
        this.shouldHaveOneSocialMediaWhenSiteIsFilled()
      }

      if (['all', 'zones'].includes(formZone)) {
        this.shouldPreferOneState()
        this.shouldPreferAZoneWhenPreferringAState()
        this.shouldNotPreferAStateWithoutAZoneInIt()
      }

      if (['all', 'additions'].includes(formZone)) {
        this.shouldHaveKeywords()
      }

      if (this.warnings.length > 0) {
        this.$refs['user-review-warning-modal'].show()
        return false
      }

      return true
    },
  },
}
</script>
