<!-- eslint-disable vue/no-v-html -->
<template>
  <section id="email-tool">
    <b-card
      title="Enviar E-mail"
      no-body
    >
      <b-card-header class="nopadding">
        <b-card-title>Email</b-card-title>
        <b-card-title>
          <b-button
            variant="flat-secondary"
            @click="$refs['report-modal'].show()"
          >
            Ver log
          </b-button>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row
          v-if="isLoading"
          class="text-center"
        >
          <b-col>
            <b-spinner
              label="Loading..."
            />
            <strong> Carregando...</strong>
          </b-col>
        </b-row>
        <b-row
          v-else-if="!isEnable"
          class="text-center"
        >
          <b-col class="mt-4 mb-5">
            <h3>
              Você não pode enviar e-mail para este usuário!
            </h3>
            <p>{{ enableReason }}</p>
          </b-col>
        </b-row>
        <b-row
          v-else-if="emailSent"
          class="text-center"
        >
          <b-col class="mt-4">
            <h3>E-mail enviado com sucesso!</h3>

            <b-button
              variant="primary"
              class="mt-3 mb-5"
              @click="init"
            >
              Enviar novo email
            </b-button>
          </b-col>
        </b-row>
        <div v-else>
          <validation-observer ref="rulesRegistration">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Template"
                  label-for="template"
                >
                  <v-select
                    id="template"
                    v-model="templateSelected"
                    :options="templateOptions"
                    :disabled="isLoading || isLoadingConfigs || isSubmiting"
                    @option:selected="onSelectTemplate"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Assunto"
                  label-for="subject"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-input
                      id="subject"
                      v-model="email.subject"
                      :disabled="isLoading || isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  v-if="managerData.permission_type === 'root'"
                  label="De"
                  label-for="from"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <v-select
                      id="from"
                      v-model="fromSelected"
                      :options="fromOptions"
                      :disabled="isSubmiting"
                      @option:selected="onSelectFrom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  v-else
                  label="De"
                  label-for="from"
                >
                  <b-form-input
                    id="from"
                    v-model="email.from"
                    readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Para"
                  label-for="to"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="to"
                  >
                    <v-select
                      id="to"
                      v-model="toSelected"
                      :options="toOptions"
                      :disabled="isSubmiting"
                      @option:selected="onSelectTo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  label="Cópia"
                  label-for="cc"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="cc"
                  >
                    <v-select
                      id="cc"
                      v-model="ccSelected"
                      multiple
                      :options="ccOptions"
                      :disabled="isSubmiting"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-for="(attachment, i) in email.attachments"
              :key="attachment.id"
            >
              <b-col cols="6">
                <b-form-group
                  :label="`Anexo ${i + 1}`"
                  :label-for="`attachment_file_${attachment.id}`"
                >
                  <b-form-input
                    v-if="attachment.isFromTemplate && attachment.name"
                    readonly
                    :value="`Arquivo anexo: ${attachment.name}`"
                  >
                  </b-form-input>

                  <b-form-input
                    v-if="!attachment.isFromTemplate && attachment.file && attachment.file.name"
                    readonly
                    :value="`Arquivo anexo: ${attachment.file.name}`"
                  >
                  </b-form-input>

                  <b-form-file
                    v-if="attachment.isFromTemplate === false && attachment.file === null"
                    :id="`attachment_file_${attachment.id}`"
                    v-model="attachment.file"
                    :disabled="isSubmiting"
                    @change="changeAttachment()"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-button
                  v-if="attachment.isFromTemplate"
                  class="mt-2 mr-2"
                  size="sm"
                  @click="attachmentDownload(attachment.name, attachment.path)"
                >
                  <b-spinner
                    v-if="isDownloading"
                    small
                  />
                  Baixar
                </b-button>

                <b-button
                  v-if="attachment.file !== null || attachment.path !== null"
                  class="mt-2"
                  size="sm"
                  @click="attachmentRemove(attachment.id)"
                >
                  Remover
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Intro"
                  label-for="intro"
                >
                  <b-form-textarea
                    id="intro"
                    v-model="email.intro"
                    rows="5"
                    :disabled="isSubmiting"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Mensagem"
                  label-for="message"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|restrictedWords"
                  >
                    <quill-editor
                      id="message"
                      ref="message"
                      v-model="email.message"
                      :class="{ 'disabled': isSubmiting }"
                      rows="10"
                      :disabled="isSubmiting"
                      :options="editorOption"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="Assinatura"
                  label-for="message"
                >
                  <div
                    v-if="isLoadingConfigs"
                    class="text-left"
                  >
                    <b-spinner
                      small
                      label="Loading..."
                    />
                    <strong> Carregando...</strong>
                  </div>
                  <p
                    v-else
                    :class="{ 'signature': 1, 'disabled': isSubmiting }"
                    v-html="email.signature"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-button
                  :disabled="isLoading || isLoadingConfigs || !canUseTemplate"
                  variant="primary"
                  @click="sendEmail"
                >
                  Enviar
                </b-button>

                <span
                  v-if="!canUseTemplate"
                  class="text-danger ml-1"
                >Para enviar este e-mail, gere um link de pagamento para o usuário na aba lateral 'Financeiro', botão '+ Solicitação de Pagamento'</span>
              </b-col>
            </b-row>
          </validation-observer>
        </div>
      </b-card-body>
    </b-card>

    <b-modal
      id="report-modal"
      ref="report-modal"
      title="Log de permissão"
    >
      <p
        class="p-log"
        v-html="ruleReport.join('\n')"
      />
    </b-modal>

  </section>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BSpinner,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import EmailService from '@/services/emailService'
import UserService from '@/services/userService'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import downloadFile from '@/mixins/downloadFile'
import isMyUser from '@/mixins/isMyUser'
import requestErrorMessage from '../../../mixins/requestErrorMessage'

extend('restrictedWords', {
  // params: ['target'],
  validate(value) {
    const r = value.match(/\[!\](.*)\[!\]/gm)
    return r === null || r.length === 0
  },
  message: 'Existem partes da mensagem que devem ser substituídas antes do envio.',
})

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormTextarea,
    BSpinner,
    BFormFile,
    BButton,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [
    requestErrorMessage,
    downloadFile,
    isMyUser,
  ],
  props: {
    userId: {
      type: Number,
      default: null,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      isLoadingConfigs: true,
      isSubmiting: false,
      isDownloading: false,
      canUseTemplate: true,
      isEnable: false,
      enableReason: '',
      emailSent: false,
      emailService: null,
      userService: null,
      email: {},
      variables: null,
      templateOptions: [],
      fromOptions: [],
      templateSelected: null,
      toSelected: null,
      ccSelected: null,
      fromSelected: null,
      showAttachmentField: false,
      showRuleReport: false,
      ruleReport: [],
      managerData: {
        permission_type: null,
      },
      editorOption: {
        syntax: false,
        placeholder: 'Mensagem',
        modules: {
          toolbar: [
            ['bold', 'italic'],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ['link'],
          ],
        },
      },
    }
  },

  computed: {
    toOptions() {
      return [
        { label: this.user.email },
        ...(this.user.emails ? this.user.emails.map(m => ({ label: m })) : []),
        ...(this.user.emails_cms ? this.user.emails_cms.reduce((r, i) => [...i.trim() !== '' ? [...r, { label: i }] : [...r]], []) : []),
      ]
    },

    ccOptions() {
      return [
        { label: this.user.email },
        ...(this.user.emails ? this.user.emails.map(m => ({ label: m })) : []),
        ...(this.user.emails_cms ? this.user.emails_cms.reduce((r, i) => [...i.trim() !== '' ? [...r, { label: i }] : [...r]], []) : []),
      ].filter(f => f.label !== this.email.to)
    },
  },

  async created() {
    this.emailService = new EmailService()
    this.userService = new UserService()

    const managerDataString = await localStorage.getItem('userData')
    this.managerData = JSON.parse(managerDataString)

    this.init()
  },

  methods: {
    coloring() {
      let text = this.$refs.message.quill.getText()

      // remove todas as cores "red" para limpar todos os erros
      let contents = this.$refs.message.quill.getContents()
      if (contents && contents.length) {
        contents = contents.map(content => {
          if (content.attributes) {
            if (content.attributes.background && content.attributes.background === '#fe6f6f') {
              return { insert: content.insert }
            }
          }
          return content
        })
        this.$refs.message.quill.setContents(contents)
      }

      // adiciona a core red para identificar os trechos com erro
      const matches = text.match(/\[!\](.*)\[!\]/gm)
      if (matches) {
        let i = 0
        let init = 0
        let end = 0
        const coloring = []
        while (text.indexOf('[!]') !== -1) {
          if (init !== 0) {
            end = text.indexOf('[!]')
          }

          if (end !== 0) {
            coloring.push({
              init,
              end: end - init + 3,
            })
            init = 0
            end = 0
          } else {
            init = text.indexOf('[!]')
          }

          text = text.replace('[!]', '333')
          i += 1
          if (i > 100) {
            break
          }
        }

        if (coloring && coloring.length) {
          coloring.forEach(position => {
            this.$refs.message.quill.formatText(position.init, position.end, 'background', '#fe6f6f')
          })
        }
      }
    },
    async init() {
      this.isLoading = true
      this.emailSent = false
      this.templateSelected = {
        id: null,
        label: null,
        subject: null,
        message: null,
        attachment_name: null,
        attachment_path: null,
      }
      this.fromSelected = null
      this.email = {
        template_id: '',
        subject: '',
        intro: '',
        message: '',
        signature: '',
        from: '',
        attachment_file: null,
        attachments: [
          {
            id: 0,
            file: null,
            name: null,
            path: null,
            isFromTemplate: false,
          },
        ],
        to: '',
        cc: [],
      }

      // se for root, seleciona o próprio e-mail no v-select do campo remetente
      if (this.managerData.permission_type === 'root') {
        this.fromSelected = {
          id: this.managerData.id,
          label: this.managerData.name,
          text: this.managerData.email,
        }
      }
      this.email.from = this.managerData.email
      this.email.from_id = this.managerData.id
      this.email.to = this.user.email
      this.ccSelected = null
      this.toSelected = {
        label: this.user.email,
      }

      this.getInitialConfigs()
      await this.getTemplates()
      await this.getManagers()

      if (!this.user || !this.user.id || !this.managerData) {
        return
      }

      // validar se o consultor pode enviar email para o usuario
      this.isEnable = await this.canSendEmail(this.user, this.managerData)
      this.ruleReport = this.getRuleReport()
      if (!this.isEnable) {
        this.enableReason = 'Este usuário já pertence a outro consultor de vendas'
      }

      // seleciona o e-mail de login no v-select do campo destinatário
      this.toSelected = {
        label: this.user.email,
      }
      this.email.to = this.user.email

      this.isLoading = false
    },

    attachmentDownload(name, file) {
      this.isDownloading = true
      return this.emailService.getUrlDownloadAttachment(name, file).then(response => {
        this.isDownloading = false
        this.openModalToSaveFile(response, name)
      })
    },

    changeAttachment() {
      setTimeout(() => {
        const exists = this.email.attachments.find(f => f.file === null && f.path === null)
        if (!exists) {
          this.addEmptyAttachment()
        }
      }, 100)
    },

    sortAttachments() {
      this.email.attachments.sort((a, b) => {
        const aFilled = a.path === null || a.name === null ? 1 : 0
        const bFilled = b.path === null || b.name === null ? 1 : 0
        return (aFilled - bFilled)
      })
    },

    addEmptyAttachment() {
      this.email.attachments.push({
        id: (new Date()).getTime() + Math.round(Math.random() * 100),
        file: null,
        path: null,
        name: null,
        isFromTemplate: false,
      })
      this.sortAttachments()
    },

    attachmentRemove(id) {
      this.email.attachments = this.email.attachments.filter(f => f.id !== id)

      if (this.email.attachments.length === 0) {
        this.addEmptyAttachment()
      }
    },

    sendEmail() {
      return this.$refs.rulesRegistration.validate().then(success => {
        this.coloring()
        if (success) {
          this.isSubmiting = true

          const message = `<p>${this.email.intro}</p>
${this.email.message}`
          const formData = new FormData()
          formData.append('user_id', this.userId)
          formData.append('from_id', this.email.from_id)
          formData.append('template_id', this.email.template_id)
          formData.append('subject', this.email.subject)
          formData.append('from', this.email.from)
          formData.append('to', this.email.to)

          if (this.ccSelected && this.ccSelected.length) {
            this.ccSelected.forEach(cc => {
              formData.append('cc[]', cc.label)
            })
          }

          formData.append('intro', this.email.intro)
          formData.append('message', message)

          this.email.attachments.forEach((attachment, index) => {
            if (attachment.file !== null) {
              formData.append(`attachment_file_${index}`, attachment.file)
            }

            if (attachment.name !== null) {
              formData.append(`attachment_file_${index}`, 'template_attachment')
            }
          })

          this.emailService.sendMessage(formData).then(() => {
            this.isSubmiting = false
            this.emailSent = true
            this.toast('success', 'E-mail enviado!')
          }).catch(error => {
            this.isSubmiting = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ops!',
                icon: 'BellIcon',
                variant: 'danger',
                text: this.getResponseErrorMessage(error.response.data),
              },
            })
          })
        } else {
          this.$swal({
            title: 'Ops!',
            text: 'Preencha todos os campos',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },

    async getManagers() {
      this.userService.getAndStoreManagers().then(response => {
        this.fromOptions = response.map(m => ({
          id: m.id,
          label: m.name,
          text: m.email,
        }))
      })
    },

    async getInitialConfigs() {
      if (this.user && this.user.id && this.managerData) {
        this.isLoadingConfigs = true

        const fromId = (this.managerData.permission_type === 'root') ? this.fromSelected.id : this.managerData.id
        this.emailService.getInitialConfig(fromId, this.user.id).then(response => {
          this.email.intro = response.data.data.config.intro
          this.email.signature = `Espero ter ajudado e coloco-me à disposição.\n\nAtenciosamente, \n\n${response.data.data.config.signature}`
          this.variables = response.data.data.config.variables
          this.isLoadingConfigs = false
        })
      }
    },

    async getTemplates() {
      this.emailService.listAndStoreTemplates().then(response => {
        this.templateOptions = response.map(m => ({
          id: m.id,
          label: m.shortcut,
          subject: m.subject,
          message: m.message,
          attachment_name: m.attachment_name,
          attachment_path: m.attachment_path,
        }))
      })
    },

    onSelectTemplate(event) {
      this.email.template_id = event.id
      this.email.subject = event.subject
      this.email.message = event.message

      // adiciona o anexo do template no corpo do email
      if (event.attachment_path && event.attachment_path !== '' && event.attachment_path !== 'null') {
        this.email.attachments = this.email.attachments.filter(f => !f.isFromTemplate)

        this.email.attachments.push({
          id: (new Date()).getTime() + Math.round(Math.random() * 100),
          file: null,
          path: event.attachment_path,
          name: event.attachment_name,
          isFromTemplate: true,
        })
        this.sortAttachments()
      }

      let needPaymentLink = false
      let havePaymentLink = false
      if (this.email.message.includes('link_pagamento}')) {
        needPaymentLink = true
        havePaymentLink = false
        this.canUseTemplate = false
      } else {
        this.canUseTemplate = true
      }

      Object.entries(this.variables).forEach(item => {
        const index = item[0]
        const value = item[1]

        if (this.email.message.includes('link_pagamento}') && value.length > 0) {
          havePaymentLink = true
        }

        if (index.includes('usuario_assinatura_nota_fiscal_link') && this.email.message.match(/\{(usuario_assinatura_nota_fiscal_link)([^}]+)/)) {
          this.email.message = this.email.message.replace(/\{(usuario_assinatura_nota_fiscal_link\|)([^}]+)(\})/, `<a href="${value}">$2</a>`)
        }

        if (index.includes('link_pagamento')) {
          this.email.message = this.email.message.replaceAll(`{${index}}`, `<a href="${value}">${value}</a>`)
        } else {
          this.email.message = this.email.message.replaceAll(`{${index}}`, value)
        }
      })

      if (!needPaymentLink || havePaymentLink) {
        this.canUseTemplate = true
      }

      this.$nextTick(() => {
        this.coloring()
      })
    },

    onSelectTo(event) {
      this.email.to = event.label
    },

    onSelectFrom(event) {
      this.email.from = event.text
      this.email.from_id = event.id
      this.getInitialConfigs()
    },

    toast(type, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: type === 'success' ? 'EditIcon' : 'BellIcon',
          variant: type,
        },
      })
    },

  },
}
</script>

<style lang="scss">
  @import '~@core/scss/base/bootstrap-extended/_variables';

  #report-modal {
    .p-log {
      white-space: pre-wrap;
    }
  }

  #email-tool {
    textarea {
      font-family: Verdana;
      font-size: 12px;
    }

    #message{
      height: 350px;
      padding-bottom: 45px;
      p {
        font-family: Verdana;
        font-size: 12px;
      }

      &.disabled{
        background-color: $input-disabled-bg;
      }
    }

    .signature {
      border: 1px solid $custom-control-border-color;
      border-radius: 5px;
      padding: 10px 15px;
      white-space: pre;
      display: block;
      unicode-bidi: embed;
      font-family: Verdana;
      font-size: 12px;

      &.disabled{
        background-color: $input-disabled-bg;
      }
    }
  }

</style>
