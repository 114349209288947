import useJwt from '@/auth/jwt/useJwt'

export default class SusiService {
  static get(userId, field) {
    return useJwt.get(`/susi/${field}/${userId}`)
  }

  static byPrompt(prompt, replaces) {
    return useJwt.post('/susi/by-prompt', {}, {
      prompt,
      replaces,
    })
  }
}
