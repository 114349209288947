<template>
  <div class="avatar-input">
    <input
      id="avatar-input"
      type="file"
      class="d-none"
      @change="handleInputChangeAndSave"
    >
    <label
      class="custom-image-input"
      :class="{edit: avatar || avatarImage}"
    >
      <b-spinner
        v-if="saving"
        label=""
      />
      <img
        v-else-if="!saving && avatar && avatar.preview"
        :src="avatar.preview"
        class="preview"
      >
      <img
        v-else-if="!saving && avatarImage"
        :src="avatarImage.md"
        class="preview"
      >
      <b-avatar
        v-else
        size="100px"
        variant="light-danger"
        :text="getInitials(namePhoto)"
      />
      <label
        for="avatar-input"
        class="label-for"
      >
        <feather-icon
          size="26"
          icon="EditIcon"
          class="feather change bg-gradient-primary"
        />
      </label>

      <feather-icon
        v-if="avatarImage || avatar"
        size="26"
        icon="TrashIcon"
        class="feather remove bg-gradient-danger"
        @click="removeAvatar"
      />

      <feather-icon
        v-if="avatarImage || avatar"
        size="26"
        icon="CornerLeftDownIcon"
        class="feather rotate-left bg-gradient-danger"
        @click="rotateLeft"
      />

      <feather-icon
        v-if="avatarImage || avatar"
        size="26"
        icon="CornerRightDownIcon"
        class="feather rotate-right bg-gradient-danger"
        @click="rotateRight"
      />
    </label>
  </div>
</template>

<script>
import {
  BSpinner,
  BAvatar,
} from 'bootstrap-vue'
import UserService from '@/services/userService'
import { getInitials } from '@/utils/helpers'

export default {
  name: 'AvatarInput',
  components: {
    BSpinner,
    BAvatar,
  },
  mixins: [],
  props: {
    avatarImage: {
      type: Object,
      default: null,
    },
    methodToSave: {
      type: Function,
      default: null,
    },
    methodToRemove: {
      type: Function,
      default: null,
    },
    methodToRotate90: {
      type: Function,
      default: null,
    },
    methodToRotate270: {
      type: Function,
      default: null,
    },
    userId: {
      type: Number,
      required: true,
    },
    namePhoto: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      getInitials,
      userService: null,
      saving: false,
      avatar: null,
    }
  },
  created() {
    this.userService = new UserService()
  },
  methods: {
    handleInputChangeAndSave(e) {
      if (!e.target.files.length) {
        this.avatar = null
        return false
      }
      this.saving = true
      const file = e.target.files[0]

      this.avatar = {
        file,
        preview: URL.createObjectURL(file),
      }

      this.saveAvatar(file)
      return true
    },
    rotateRight() {
      this.saving = true
      this.methodToRotate90(this.userId).then(response => {
        const rand = Math.floor(Math.random() * 100)
        const data = {
          sm: `${response.data.data.avatar.sm}?t=${rand}`,
          lg: `${response.data.data.avatar.lg}?t=${rand}`,
          md: `${response.data.data.avatar.md}?t=${rand}`,
        }
        this.$emit('preview', data)
        this.avatar = null
        this.saving = false
      }).catch(() => {
        this.saving = false
      })
    },
    rotateLeft() {
      this.saving = true
      this.methodToRotate270(this.userId).then(response => {
        const rand = Math.floor(Math.random() * 100)
        const data = {
          sm: `${response.data.data.avatar.sm}?t=${rand}`,
          lg: `${response.data.data.avatar.lg}?t=${rand}`,
          md: `${response.data.data.avatar.md}?t=${rand}`,
        }
        this.$emit('preview', data)
        this.avatar = null
        this.saving = false
      }).catch(() => {
        this.saving = false
      })
    },
    removeAvatar() {
      this.saving = true
      if (this.avatar != null || this.avatarImage != null) {
        this.methodToRemove(this.userId).then(response => {
          if (response.data.status) {
            this.$emit('preview', null)
            this.avatar = null
          }
          this.saving = false
        }).catch(() => {
          this.saving = false
        })
      }
    },
    saveAvatar(file) {
      if (this.avatar != null) {
        const imageType = /image.*/
        if (!file.type.match(imageType)) return

        const formData = new FormData()
        formData.append('avatar', file)

        this.methodToSave(this.userId, formData).then(response => {
          this.$emit('preview', response.data.data.avatar)
          this.saving = false
        }).catch(() => {
          this.saving = false
        })
      }
    },
    getAvatar() {
      return this.avatar ? this.avatar.file : null
    },
  },
}
</script>

<style lang="scss">

.avatar-input {
    .custom-image-input {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background-color: #f6f6f6;

        .spinner-border{
            width: 30px!important;
            height: 30px!important;
            margin: 34px auto;
        }

        .preview {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
            border: 1px solid #000000;
        }

        .label-for{
          position: absolute;
          bottom: 0px;
          right: 0px;
          cursor: pointer;
          margin: 0px;
          padding: 0px;
        }

        .feather{
          color: #ffffff;
          padding: 5px;
          border-radius: 50%;

          &.rotate-right{
            position: absolute;
            top: 0px;
            right: 0px;
            cursor: pointer;
          }

          &.rotate-left{
            position: absolute;
            top: 0px;
            left: 0px;
            cursor: pointer;
          }

          &.remove{
            position: absolute;
            bottom: 0px;
            left: 0px;
            cursor: pointer;
          }
        }

        span {
            width: 100%;
            line-height: 100px;
            text-align: center;
            display: block;
        }
    }
}
</style>
