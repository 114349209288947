<template>
  <section id="report">
    <b-breadcrumb
      v-if="!userIdFromProps"
      :items="breadcrumb"
    />

    <b-card-actions
      v-if="!userIdFromProps"
      ref="filters"
      title="Filtros"
      class="form-filters mt-1"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Usuário"
            label-for="user_id"
          >
            <b-form-input
              id="user_id"
              v-model="filtersValues.user_id"
              placeholder="ID do usuário"
              @keyup.enter="getHealthScores()"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="3"
        >
          <b-form-group
            label="Gerente"
            label-for="account_manager"
          >
            <b-form-select
              id="success_manager"
              v-model="filtersValues.success_manager"
              :options="filtersOptions.success_manager"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="3"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 20px"
          >
            <b-button
              id="btn-buscar"
              variant="primary"
              @click="getHealthScores()"
            >
              Buscar
            </b-button>

            <b-button
              id="btn-limpar"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      ref="results"
      :title="userIdFromProps ? 'Health Score' : 'Resultados'"
    >
      <b-row>
        <b-col cols="12">
          <health-score-list
            :is-loading="isLoading"
            :results="results"
            :fields="[
              { key: 'date', sortable: false, thStyle: 'vertical-align: middle; width: 150px;' },
              ...!userIdFromProps ? [{ key: 'user', sortable: false, thStyle: 'vertical-align: middle; width: 150px;' }] : [],
              { key: 'cs_contact', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'aud', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'connections', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'advanced_tools', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'recurrences', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'messages_read', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'best_ranking', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'add_ons', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'email_opening', sortable: false, thStyle: 'vertical-align: middle;' },
              { key: 'score', sortable: false, thStyle: 'vertical-align: middle; white-space: nowrap;' },
              { key: 'actions', sortable: false, thStyle: 'vertical-align: middle; width: 110px;' },
            ]"
          />
        </b-col>
      </b-row>

      <b-row v-if="!userIdFromProps && results.length > 0">
        <b-col cols="6">
          <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
        </b-col>

        <b-col cols="6">
          <!-- pagination nav -->
          <b-pagination-nav
            v-model="currentPage"
            :number-of-pages="pages.last_page === undefined ? 99 : pages.last_page"
            base-url="#"
            align="end"
          />
        </b-col>
      </b-row>
    </b-card>
  </section>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserService from '@/services/userService'
import HealthScoreList from './List.vue'

const components = {
  BAvatar,
  BBadge,
  BBreadcrumb,
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BLink,
  BPaginationNav,
  BRow,
  BSpinner,
  BTable,
  BCardActions,
  HealthScoreList,
}

export default {
  name: 'HealthScore',
  components,
  props: {
    userIdFromProps: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Relatórios',
          active: true,
        },
        {
          text: 'Health Scores',
          active: true,
        },
      ],

      userService: null,

      filtersInitialValues: {},
      filtersValues: {
        user_id: '',
        success_manager: 'all',
      },
      filtersOptions: {
        success_manager: [
          { value: 'all', text: 'Todos' },
          { value: 'all', text: '-- Carregando --', disabled: true },
        ],
      },

      pages: {},
      currentPage: 1,
      results: [],
      isLoading: true,
    }
  },

  watch: {
    currentPage() {
      this.getHealthScores()
    },
  },

  async created() {
    this.userService = new UserService()
    await this.getManagers()

    this.filtersInitialValues = { ...this.filtersValues }
    if (this.userIdFromProps) {
      this.filtersValues.user_id = this.userIdFromProps
    }

    if (this.$route.params.managerIdFromProps) {
      this.filtersValues.success_manager = this.$route.params.managerIdFromProps
    }

    this.getHealthScores()
  },

  methods: {
    getManagers() {
      return this.userService.getAndStoreManagers().then(managers => {
        this.filtersOptions.success_manager = [
          { value: 'all', text: 'Todos' },
          ...managers.reduce((c, r) => [
            ...c,
            ...r.deleted_at || r.permission_type !== 'success' ? [] : [
              { value: r.id, text: r.name },
            ],
          ], []).sort((a, b) => {
            if (a.text > b.text) {
              return 1
            }

            return (b.text > a.text) ? -1 : 0
          }),
        ]
      })
    },

    getHealthScores() {
      this.isLoading = true
      UserService.getHealthScoreFromPayingUsers({
        ...this.filtersValues,
        page: this.currentPage,
      }).then(response => {
        if (response.data !== null) {
          this.pages = response.data.data.health_scores

          const { data } = this.pages
          this.results = data

          delete this.pages.data
        }
        this.isLoading = false
      }).catch(response => {
        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })
    },

    reset() {
      this.filtersValues = JSON.parse(JSON.stringify(this.filtersInitialValues))
      this.currentPage = 1
      this.getHealthScores()
    },
  },
}
</script>
