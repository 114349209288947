<template>
  <b-badge
    pill
    :variant="salesLeadOrigin.variant"
  >
    <feather-icon
      :icon="salesLeadOrigin.icon"
      size="16"
    />
    {{ salesLeadOrigin.text }}
  </b-badge>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import {
  salesLeadOriginOptions,
} from '@/utils/options'

export default {
  components: {
    BBadge,
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      salesLeadOriginOptions,
    }
  },
  computed: {
    salesLeadOrigin() {
      return salesLeadOriginOptions.find(f => f.value === this.card.sales_lead_origin)
    },
  },
}
</script>
