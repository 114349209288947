<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-card>
        <b-spinner />
        <p>Carregando...</p>
      </b-card>
    </div>

    <div
      v-if="!isLoading"
      :class="{
        'pointer-events-none': isSubmiting
      }"
    >
      <account-list
        :show-filters="false"
        :user-id="userId"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import { formatDateTimeDbToView } from '@/utils/helpers'
import CrmService from '@/services/crmService'
import AccountList from '@/views/crm/account/AccountList.vue'

export default {
  components: {
    AccountList,
    BCard,
    BSpinner,
  },

  props: {
    userId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      formatDateTimeDbToView,

      isLoading: true,
      isSubmiting: false,

      accounts: [],
      user: {
        accounts: [],
      },

    }
  },

  async created() {
    this.getUser()
    this.getAccounts()
  },

  methods: {
    copyUser() {
      this.isSubmiting = true
      CrmService.copyUser(this.userId).then(response => {
        this.user = response.data.user
      }).finally(() => {
        this.isSubmiting = false
      })
    },

    getAccounts() {
      this.isLoading = true
      CrmService.listAccounts({ user_id: this.userId }).then(response => {
        this.accounts = response.data.data.accounts
      }).finally(() => {
        this.isLoading = false
      })
    },

    getUser() {
      this.isLoading = true
      CrmService.showUser(this.userId).then(response => {
        this.user = response.data.user
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>
