<template>
  <div>
    <div
      :class="{
        'pointer-events-none': isSubmiting || isSendingSearch || isSavingSearch
      }"
    >
      <div
        v-if="isLoading"
        class="text-center"
      >
        <b-card>
          <b-spinner />
          <p>Carregando...</p>
        </b-card>
      </div>
      <b-card-actions
        v-else
        ref="filters"
        title="Busca Interna"
        :action-close="false"
        :action-refresh="false"
        :action-collapse="true"
      >
        <div>
          <b-alert
            v-if="!currentUser.settings.receive_alert_of_new_opportunities"
            show
            variant="danger"
          >
            Usuário optou por não receber oportunidades
          </b-alert>
          <span
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              pill
              :variant="selected.states.length ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-states'].show()"
            >
              Por Estado
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-states"
              hide-footer
              size="lg"
            >
              <h4
                class="mb-2"
              >
                Filtrar por Estado
                <b-button
                  variant="link"
                  class="mr-1 p-0"
                  @click="clearSelectedStates()"
                >
                  (Limpar filtros salvos)
                </b-button>
              </h4>

              <b-row>
                <b-col
                  v-for="state in states"
                  :key="state.initials"
                  cols="4"
                >
                  <b-form-checkbox
                    :checked="state.selected"
                    switch
                    @change="changeState(state.initials, $event)"
                  >
                    {{ state.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-modal>
          </span>
          <span
            v-if="selected.states.length === 1"
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              :variant="[...selected.zones, ...selected.cities].length ? 'gradient-primary' : 'gradient-secondary'"
              pill
              @click="$refs['modal-internal-search-zones-cities'].show()"
            >
              Por Zona/Cidade
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-zones-cities"
              hide-footer
              size="lg"
              :lazy="false"
              static
            >
              <b-tabs
                align="center"
                @activate-tab="changeTab"
              >
                <b-tab
                  title="Filtrar por Zona"
                  :active="activeTab === 'state'"
                >
                  <h4
                    class="mb-2"
                  >
                    Filtrar por Zona
                    <b-button
                      variant="link"
                      class="mr-1 p-0"
                      @click="clearSelectedZones()"
                    >
                      (Limpar filtros salvos)
                    </b-button>
                  </h4>
                  <b-row>
                    <b-col
                      v-for="zone in zones"
                      :key="zone.id"
                      cols="4"
                    >
                      <b-form-checkbox
                        :checked="zone.selected"
                        switch
                        @change="changeZone(zone.id, $event)"
                      >
                        {{ zone.name }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </b-tab>
                <b-tab
                  title="Filtrar por Cidade"
                  :active="activeTab === 'city'"
                >
                  <h4
                    class="mb-2"
                  >
                    Filtrar por Cidade
                    <b-button
                      variant="link"
                      class="mr-1 p-0"
                      @click="clearSelectedCities()"
                    >
                      (Limpar filtros salvos)
                    </b-button>
                  </h4>

                  <v-select
                    v-model="selectedCities"
                    :options="citiesOptions"
                    :close-on-select="false"
                    multiple
                  />
                </b-tab>
              </b-tabs>
            </b-modal>
          </span>
          <span
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              pill
              :variant="selected.segments.length ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-segments'].show()"
            >
              Por Segmento
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-segments"
              hide-footer
              size="lg"
            >
              <h4
                class="mb-2"
              >
                Filtrar por Segmento
                <b-button
                  variant="link"
                  class="mr-1 p-0"
                  @click="clearSelectedSegments()"
                >
                  (Limpar filtros salvos)
                </b-button>
              </h4>

              <b-row>
                <b-col
                  v-for="segment in parentSegments"
                  :key="segment.id"
                  cols="4"
                >
                  <b-form-checkbox
                    switch
                    :checked="segment.selected"
                    @change="changeSegment(segment.id, $event)"
                  >
                    {{ segment.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-modal>
          </span>
          <span
            v-if="subSegments.length"
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              pill
              :variant="selected.subSegments.length ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-sub-segments'].show()"
            >
              Por Sub-Segmento
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-sub-segments"
              hide-footer
              size="lg"
            >
              <h4
                class="mb-2"
              >
                Filtrar por Sub-Segmento
                <b-button
                  variant="link"
                  class="mr-1 p-0"
                  @click="clearSelectedSubSegments()"
                >
                  (Limpar filtros salvos)
                </b-button>
              </h4>

              <b-row>
                <b-col
                  v-for="subSegment in subSegments"
                  :key="subSegment.id"
                  cols="4"
                >
                  <b-form-checkbox
                    switch
                    :checked="subSegment.selected"
                    @change="changeSegment(subSegment.id, $event)"
                  >
                    {{ subSegment.name }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-modal>
          </span>
          <span
            v-if="currentUser.type === 'company'"
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              pill
              :variant="Object.values(selectedDifferentials).some(o => o) ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-diferentials'].show()"
            >
              Diferenciais
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-diferentials"
              hide-footer
            >
              <h4
                class="mb-2"
              >
                Mostrar apenas
              </h4>

              <div
                v-for="(label, value) in diferentialOptions"
                :key="value"
              >
                <b-form-checkbox
                  v-model="selectedDifferentials[value]"
                  switch
                >
                  {{ label }}
                </b-form-checkbox>
              </div>
            </b-modal>
          </span>
          <span
            class="mr-1 mb-1 d-inline-block"
          >
            <b-button
              pill
              :variant="Object.values(selectedOptionals).some(o => o) ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-optionals'].show()"
            >
              Opcionais de filtro
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-optionals"
              hide-footer
            >
              <h4
                class="mb-2"
              >
                Mostrar apenas
              </h4>

              <div
                v-for="(label, value) in optionalOptions"
                :key="value"
              >
                <b-form-checkbox
                  v-model="selectedOptionals[value]"
                  switch
                >
                  {{ label }}
                </b-form-checkbox>
              </div>
            </b-modal>
          </span>
          <span class="mr-1 mb-1 d-inline-block">
            <b-button
              pill
              :variant="tags.length ? 'gradient-primary' : 'gradient-secondary'"
              @click="$refs['modal-internal-search-tags'].show()"
            >
              Adicionar palavra chave
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-internal-search-tags"
              size="lg"
              hide-footer
            >
              <h4
                class="mb-2"
              >
                Adicionar Palavra-chave
              </h4>
              <p>
                Com essa ferramenta, você pode fazer um super refinamento de sua pesquisa. <br>
                Digite qualquer palavra (ex: tijolo, vinhos, modinha, etc) e o sistema irá
                pesquisar por esta palavra em todas as áreas do Perfil. Quem citou a palavra
                no seu Perfil será achado, mas apenas eles. Os outros resultados serão excluídos.
              </p>

              <p><b>LEMBRE-SE:</b> Você pode adicionar apenas UMA única palavra por busca.</p>

              <b-row>
                <b-col
                  cols="7"
                >
                  <b-form-group>
                    <b-form-input
                      v-model="tags"
                      @keydown.space.prevent
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="5"
                >
                  <b-button
                    v-if="tags.length"
                    @click="tags = ''"
                  >
                    Limpar
                  </b-button>
                </b-col>
              </b-row>
            </b-modal>
          </span>
          <span
            class="mr-1 mb-1 d-inline-block"
          >
            <b-card-text class="mb-0">
              Somente ativos
            </b-card-text>
            <b-form-checkbox
              v-model="onlyActive"
              checked="true"
              class="custom-control-primary"
              name="check-button"
              switch
            >
            </b-form-checkbox>
          </span>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <b-dropdown
              variant="outline-secondary"
              class="mr-1"
            >
              <template #button-content>
                Ordenar por: {{ orderByOptions.find(o => o.value === orderBySelected).text }}
              </template>

              <b-dropdown-item
                v-for="order in orderByOptions"
                :key="order.value"
                @click="orderBySelected = order.value"
              >
                {{ order.text }}
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              variant="gradient-primary"
              class="mr-1"
              @click="$refs['modal-reopen-search'].show()"
            >
              Buscas salvas
              <feather-icon icon="ChevronDownIcon" />
            </b-button>
            <b-modal
              ref="modal-reopen-search"
              hide-footer
            >
              <h4
                class="mb-2"
              >
                Buscas salvas
              </h4>

              <b-list-group>
                <b-list-group-item
                  v-for="search in savedSearches"
                  :key="search.id"
                  v-b-toggle="search.filter ? `searches-${search.id}` : null"
                >
                  <b-media right-align>
                    <b-media>
                      <template #aside>
                        <div class="d-flex flex-column">
                          <b-avatar
                            size="40px"
                            :src="search.manager.avatar ? search.manager.avatar.sm : ''"
                            variant="light-success"
                            class="mb-1"
                          >
                            <feather-icon
                              icon="UserIcon"
                              size="22"
                            />
                          </b-avatar>
                          <b-badge
                            fill
                            variant="light-success"
                            class="mb-1"
                          >
                            ID: {{ search.id }}
                          </b-badge>
                          <b-badge
                            v-if="search.sent"
                            fill
                            variant="light-warning"
                            class="mb-1"
                          >
                            Enviada
                          </b-badge>
                        </div>
                      </template>
                      <h5 class="mt-0 mb-1">
                        {{ search.manager.name }}
                      </h5>
                      <p class="mb-0">
                        às {{ formatDateTimeDbToView(search.created_at) }}
                      </p>
                    </b-media>
                    <template #aside>
                      <div class="d-flex flex-column">
                        <b-button
                          fill
                          variant="gradient-primary"
                          class="mb-1"
                          @click.stop="reopenSavedSearch(search.id)"
                        >
                          Abrir busca
                        </b-button>
                        <b-button
                          v-if="search.filter"
                          fill
                          variant="gradient-primary"
                          class="mb-1"
                          @click.stop="selectFromFilters(search.filter)"
                        >
                          Aplicar filtros<br>
                          desta busca
                        </b-button>
                      </div>
                    </template>
                  </b-media>

                  <div
                    v-if="search.filter"
                    class="text-center"
                  >
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                    <b-collapse
                      :id="`searches-${search.id}`"
                      class="text-left"
                    >
                      <b-table-simple
                        small
                        stacked
                        responsive
                      >
                        <b-tbody>
                          <b-tr
                            v-for="(values, key) in search.filter"
                            :key="key"
                          >
                            <b-td
                              v-if="key === 'preferred_segments' && values && values.length > 0"
                              stacked-heading="Segmentos"
                            >
                              <segment-pills :segments="values" />
                            </b-td>
                            <b-td
                              v-else-if="key === 'preferred_zones' && values && values.length > 0"
                              stacked-heading="Zonas"
                            >
                              <zone-pills :zones="values" />
                            </b-td>
                            <b-td
                              v-else-if="key === 'optionals' && values && values.length > 0"
                              stacked-heading="Opcionais"
                            >
                              <div
                                v-for="optional in values"
                                :key="optional"
                              >
                                {{ optionalOptions[optional] ? optionalOptions[optional] : '' }}
                              </div>
                            </b-td>
                            <b-td
                              v-else-if="key === 'diferentials' && values && values.length > 0"
                              stacked-heading="Diferenciais"
                            >
                              <div
                                v-for="diferential in values"
                                :key="diferential"
                              >
                                {{ diferentialOptions[diferential] ? diferentialOptions[diferential] : '' }}
                              </div>
                            </b-td>
                            <b-td
                              v-else-if="key === 'tags' && values"
                              stacked-heading="Palavra chave"
                            >
                              {{ values }}
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </b-collapse>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-modal>
          </div>
          <div class="text-right">
            <b-button
              variant="secondary"
              :disabled="isSubmiting || isLoadingMore"
              class="ml-1"
              @click="() => resetSelected()"
            >
              Resetar
            </b-button>
            <b-button
              variant="gradient-primary"
              :disabled="isSubmiting || isLoadingMore"
              class="ml-1"
              @click="() => searchUsers(false)"
            >
              Buscar
              <b-spinner
                v-if="isSubmiting || isLoadingMore"
                class="ml-1"
                small
              />
            </b-button>
          </div>
        </div>
      </b-card-actions>

      <b-card v-if="submited">
        <div
          v-if="isSubmiting"
          class="text-center"
        >
          <b-spinner />
          <p>Carregando...</p>
        </div>
        <div v-else>
          <h4
            v-b-visible="triggerVisibilityResultTitle"
            class="mb-1"
          >
            Resultados
          </h4>
          <div v-if="results.length === 0">
            <p>
              Infelizmente não foi encontrado nenhum resultado para a sua busca. Sugerimos alterar alguns filtros para torná-la um pouco menos específica.
            </p>
          </div>
          <div v-else>
            <div
              class="pb-1"
              :style="{marginTop: `${actionResultsStickySize}px`}"
            >
              <b-card
                ref="actions-results"
                style="
                  top: 80px;
                  right: 2rem;
                  z-index: 12;
                "
                :style="{position: actionResultsStickySize ? 'fixed' : 'static'}"
                :body-class="`d-flex justify-content-end ${actionResultsStickySize ? '' : 'p-0'}`"
                class="mb-0 text-right"
              >
                <div
                  class="mr-1"
                >
                  <div>
                    Total:
                    <b-badge
                      pill
                      variant="primary"
                    >
                      {{ totalResults }}
                    </b-badge>
                  </div>
                  <div v-if="!openedSearch">
                    Selecionados:
                    <b-badge
                      pill
                      variant="primary"
                    >
                      {{ selected.users.length }}
                    </b-badge>
                  </div>
                </div>

                <b-button
                  v-if="!openedSearch"
                  class="mr-1"
                  variant="gradient-primary"
                  :disabled="isSavingSearch"
                  @click="saveSearch()"
                >
                  Salvar selecionados
                  <b-spinner
                    v-if="isSavingSearch"
                    class="ml-1"
                    small
                  />
                </b-button>

                <b-button
                  v-if="openedSearch"
                  class="mr-1"
                  variant="gradient-primary"
                  @click="copySearchUrl()"
                >
                  <feather-icon
                    icon="LinkIcon"
                    size="12"
                  />
                  Copiar endereço da busca
                </b-button>

                <b-button
                  v-if="openedSearch"
                  class="mr-1"
                  variant="gradient-primary"
                  :disabled="!currentUser.settings.receive_alert_of_new_opportunities || isSendingSearch"
                  :title="!currentUser.settings.receive_alert_of_new_opportunities ? 'Usuário optou por não receber oportunidades' : ''"
                  @click="sendSearch()"
                >
                  <s v-if="!currentUser.settings.receive_alert_of_new_opportunities">
                    Enviar por email
                  </s>
                  <span v-else>
                    Enviar por email
                    <b-spinner
                      v-if="isSendingSearch"
                      class="ml-1"
                      small
                    />
                  </span>
                </b-button>
              </b-card>
            </div>
            <div
              v-for="(user, resultIndex) in results"
              :key="user.id"
            >
              <b-alert
                v-if="shouldShowDislikedDivider(resultIndex)"
                show
                variant="danger"
                class="px-4 py-2 font-weight-bold"
              >
                Veja abaixo os Perfis que se encaixam nesta busca, mas que o usuário marcou para não serem mais mostrados.
              </b-alert>
              <b-alert
                v-else-if="shouldShowOutOfZoneDivider(resultIndex)"
                show
                variant="warning"
                class="px-4 py-2 font-weight-bold"
              >
                Nos resultados abaixo estão Representantes que residem e/ou atuam nas demais regiões do Estado.
              </b-alert>
              <b-alert
                v-else-if="shouldShowOutOfCityDivider(resultIndex)"
                show
                variant="warning"
                class="px-4 py-2 font-weight-bold"
              >
                Nos resultados abaixo estão Representantes que atuam na região mas não residem na(s) cidade(s) especificada(s).
              </b-alert>
              <b-card
                :bg-variant="user.is_disliked ? 'light-danger' : 'light-secondary'"
                body-text-variant="body"
                class="mb-1"
              >
                <b-media no-body>
                  <b-media-aside>
                    <div class="d-flex flex-column">
                      <b-avatar
                        size="55px"
                        :src="user.avatar ? user.avatar.sm : ''"
                        variant="light-success"
                        class="align-self-center"
                      >
                        <feather-icon
                          icon="UserIcon"
                          size="22"
                        />
                      </b-avatar>
                      <b-badge
                        variant="dark"
                        class="mt-1"
                      >
                        ID: {{ user.id }}
                      </b-badge>
                      <b-badge
                        :variant="user.status === 'active' ? 'success' : 'dark'"
                        class="mt-1"
                      >
                        {{ $t(`users.columns.status.${user.status}`) }}
                      </b-badge>
                      <b-form-checkbox
                        v-if="!user.isConnected && !user.searchSentWithThisUser && !openedSearch"
                        v-model="user.selected"
                        class="mt-1"
                      />

                      <b-badge
                        v-if="user.searchSavedWithThisUser"
                        variant="info"
                        class="mt-1"
                      >
                        <feather-icon
                          icon="SaveIcon"
                          class="d-inline-block"
                        />
                        Salvo (Busca {{ user.searchSavedWithThisUser.id }})
                      </b-badge>
                      <b-badge
                        v-if="user.searchSentWithThisUser"
                        variant="warning"
                        class="mt-1"
                      >
                        <feather-icon
                          icon="MailIcon"
                          class="d-inline-block"
                        />
                        Enviado (Busca {{ user.searchSentWithThisUser.id }})
                      </b-badge>
                      <b-badge
                        v-if="user.isConnected"
                        variant="success"
                        class="mt-1"
                      >
                        <feather-icon
                          icon="MessageCircleIcon"
                          class="d-inline-block"
                        />
                        Conectado
                      </b-badge>
                      <b-badge
                        v-if="user.is_disliked"
                        variant="danger"
                        class="mt-1"
                      >
                        <feather-icon
                          icon="EyeOffIcon"
                          class="d-inline-block"
                        />
                        Ocultado
                      </b-badge>
                    </div>
                  </b-media-aside>

                  <b-media-body>
                    <div @click="$refs[`user-details-modal-${user.id}`][0].show()">
                      <h4>{{ parseUserName(user.name) }}</h4>
                      <p
                        class="text-break"
                      >
                        <small>
                          <feather-icon
                            icon="MapPinIcon"
                            class="d-inline-block"
                          />
                          {{ user.city }} - {{ user.state }}
                        </small>
                      </p>

                      <p
                        class="text-break"
                      >
                        {{ user.presentation_abbreviation }}
                      </p>

                      <p
                        v-if="user.preferred_segments"
                      >
                        <b-badge
                          v-for="segment in prepareSegments(user.preferred_segments)"
                          :key="user.id+'-'+segment.id"
                          :variant="filter.preferred_segments.length === 0 || segment.matchesFilter ? 'success' : 'secondary'"
                          pill
                          class="mr-1 mb-1"
                        >
                          {{ segment.name }}
                        </b-badge>
                      </p>
                      <p v-if="user.startConnections > 0">
                        <b-badge
                          variant="primary"
                          pill
                        >
                          +{{ user.startConnections }} conexões recentemente
                        </b-badge>
                      </p>
                      <p>
                        <b-badge
                          v-if="user.email_verified_at"
                          variant="warning"
                          pill
                          class="mr-1 mb-1 text-left"
                        >
                          Email verificado:<br><span class="font-weight-normal">{{ formatDateTimeDbToView(user.email_verified_at) }}</span>
                        </b-badge>
                        <b-badge
                          v-if="user.phone_verified_at"
                          variant="warning"
                          pill
                          class="mr-1 mb-1 text-left"
                        >
                          Telefone verificado:<br><span class="font-weight-normal">{{ formatDateTimeDbToView(user.phone_verified_at) }}</span>
                        </b-badge>
                        <b-badge
                          v-if="user.activity_at"
                          variant="warning"
                          pill
                          class="mr-1 mb-1 text-left"
                        >
                          Última atividade:<br><span class="font-weight-normal">{{ formatDateTimeDbToView(user.activity_at) }}</span>
                        </b-badge>
                        <b-badge
                          v-if="user.last_message_sent_at"
                          variant="warning"
                          pill
                          class="mr-1 mb-1 text-left"
                        >
                          Última mensagem:<br><span class="font-weight-normal">{{ formatDateTimeDbToView(user.last_message_sent_at) }}</span>
                        </b-badge>
                      </p>
                    </div>
                  </b-media-body>
                </b-media>

                <b-modal
                  :ref="`user-details-modal-${user.id}`"
                  hide-footer
                  static
                  lazy
                >
                  <user-details-modal
                    :user="{
                      ...user,
                      type: currentUser.type === 'company' ? 'representative' : 'company',
                    }"
                  />
                </b-modal>
              </b-card>
            </div>
            <div
              v-if="hasNextPage"
              v-b-visible="triggerInfiniteScroll"
              class="text-center"
            >
              <b-spinner />
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BAlert,
  BAvatar,
  BCardText,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
  BRow,
  BSpinner,
  BTab,
  BTabs,
  BCollapse,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  VBToggle,
  VBVisible,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserService from '@/services/userService'
import ZoneService from '@/services/zoneService'
import SegmentService from '@/services/segmentService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import LocalStorageService from '@/services/localStorageService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserDetailsModal from '@/views/cms/components/DetailsModal.vue'
import SegmentPills from '@/views/cms/components/SegmentPills.vue'
import ZonePills from '@/views/cms/components/ZonePills.vue'
import {
  orderByOptions,
  optionalOptions,
  diferentialOptions,
} from '@/utils/options'

export default {
  components: {
    BAlert,
    BCardText,
    BAvatar,
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BMedia,
    BMediaAside,
    BMediaBody,
    BModal,
    BRow,
    BSpinner,
    BTab,
    BTabs,
    BCollapse,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BCardActions,
    UserDetailsModal,
    vSelect,
    SegmentPills,
    ZonePills,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-visible': VBVisible,
  },
  data() {
    return {
      formatDateTimeDbToView,
      submited: false,
      isLoading: true,
      isSubmiting: false,
      isSendingSearch: false,
      isLoadingMore: false,
      currentUser: null,
      savedSearches: null,
      userService: null,
      zoneService: null,
      selectedCities: [],
      segments: [],
      states: [],
      optionalOptions: optionalOptions.reduce((carry, option) => ({ ...carry, [option.value]: option.text }), {}),
      diferentialOptions: diferentialOptions.reduce((carry, option) => ({ ...carry, [option.value]: option.text }), {}),
      orderBySelected: null,
      orderByOptions,
      selectedOptionals: {},
      selectedDifferentials: {},
      onlyActive: false,
      activeTab: 'state',
      tags: '',
      page: 1,
      filter: {},
      showInfiniteScroll: false,
      results: [],
      totalResults: 0,
      hasNextPage: false,
      preparedCurrentUserSegments: [],
      actionResultsStickySize: false,
      openedSearch: null,
      isSavingSearch: false,
    }
  },
  computed: {
    parentSegments() {
      return this.segments.filter(segment => !segment.parent_id)
    },
    selected() {
      const selectedCitiesIds = this.selectedCities.map(option => option.value)

      const states = this.states.filter(state => state.selected)
      const zones = states.reduce((carry, state) => [
        ...carry,
        ...state.zones.filter(zone => (
          zone.selected
          || zone.cities.some(city => selectedCitiesIds.includes(city.id))
        )),
      ], [])
      const cities = states.reduce((carryState, state) => [
        ...carryState,
        ...state.zones.reduce((carryZone, zone) => [
          ...carryZone,
          ...zone.cities.filter(city => selectedCitiesIds.includes(city.id)),
        ], []),
      ], [])

      const segments = this.segments.filter(segment => !segment.parent_id && segment.selected)
      const subSegments = this.segments.filter(subSegment => subSegment.parent_id && subSegment.selected)

      const users = this.results.filter(u => u.selected)

      return {
        states,
        zones,
        cities,
        segments,
        subSegments,
        users,
      }
    },
    zones() {
      if (this.selected.states.length === 1) {
        return this.selected.states[0].zones
      }

      return []
    },
    cities() {
      if (this.selected.states.length === 1) {
        return this.selected.states[0].zones.reduce((carryCities, zone) => [
          ...carryCities,
          ...zone.cities,
        ], [])
      }

      return []
    },
    citiesOptions() {
      return this.cities.map(city => ({
        label: city.name,
        value: city.id,
      }))
    },
    subSegments() {
      if (this.selected.segments.length === 1) {
        return this.segments.filter(segment => segment.parent_id === this.selected.segments[0].id)
      }

      return []
    },
  },
  async created() {
    this.isLoading = true

    this.selectedOptionals = Object.keys(this.optionalOptions).reduce((carry, optional) => ({
      ...carry,
      [optional]: false,
    }), {})

    this.selectedDifferentials = Object.keys(this.diferentialOptions).reduce((carry, diferential) => ({
      ...carry,
      [diferential]: false,
    }), {})

    this.orderBySelected = this.orderByOptions[0].value

    this.userService = new UserService()
    this.zoneService = new ZoneService()
    this.segmentService = new SegmentService()

    await Promise.allSettled([
      this.getUser(),
      this.getSavedSearches(),
      this.zoneService.getAndStoreZones(),
      this.segmentService.getAndStoreSegments(),
    ])

    this.states = [...LocalStorageService.getArray(LocalStorageService.zones)]
    this.segments = [...LocalStorageService.getArray(LocalStorageService.segments)].sort((a, b) => (a.name < b.name ? -1 : 1))

    this.preparedCurrentUserSegments = this.currentUser.profile.preferred_segments.reduce((carry, segmentId) => {
      const segment = this.segments.find(f => (f.id).toString() === (segmentId).toString())

      return [
        ...carry,
        ...segment ? [segment] : [],
      ]
    }, [])

    this.isLoading = false
  },
  methods: {
    changeTab(newTabIndex) {
      if (newTabIndex === 0) {
        this.activeTab = 'state'
      } else {
        this.activeTab = 'city'
      }
      this.clearSelectedZones()
      this.clearSelectedCities()
    },

    shouldShowDislikedDivider(resultIndex) {
      const previousResult = this.results[resultIndex - 1]
      const currentResult = this.results[resultIndex]

      return (
        previousResult
        && !previousResult.is_disliked
        && currentResult.is_disliked
      )
    },
    shouldShowOutOfCityDivider(resultIndex) {
      const previousResult = this.results[resultIndex - 1]
      const currentResult = this.results[resultIndex]

      return (
        previousResult
        && previousResult.found_city
        && !currentResult.found_city
      )
    },
    shouldShowOutOfZoneDivider(resultIndex) {
      const previousResult = this.results[resultIndex - 1]
      const currentResult = this.results[resultIndex]

      return (
        previousResult
        && previousResult.found_zone
        && !currentResult.found_zone
      )
    },
    clearSelectedStates() {
      this.clearSelectedZones()
      this.clearSelectedCities()
      this.states = this.states.map(state => ({
        ...state,
        selected: false,
      }))
    },
    clearSelectedZones() {
      this.states = this.states.map(state => ({
        ...state,
        zones: state.zones.map(zone => ({
          ...zone,
          selected: false,
        })),
      }))
    },
    clearSelectedCities() {
      this.selectedCities = []
    },
    clearSelectedSegments() {
      this.segments = this.segments.map(segment => ({
        ...segment,
        selected: false,
      }))
    },
    clearSelectedSubSegments() {
      this.segments = this.segments.map(segment => ({
        ...segment,
        selected: segment.parent_id ? false : segment.selected,
      }))
    },
    changeState(initials, selected) {
      const stateIndex = this.states.findIndex(f => f.initials === initials)
      if (stateIndex > -1) {
        this.states.splice(stateIndex, 1, {
          ...this.states[stateIndex],
          selected,
        })

        this.clearSelectedZones()
        this.clearSelectedCities()
      }
    },
    changeZone(zoneId, selected) {
      const zoneIndex = this.zones.findIndex(f => f.id === zoneId)
      if (zoneIndex > -1) {
        this.zones.splice(zoneIndex, 1, {
          ...this.zones[zoneIndex],
          selected,
        })
      }
    },
    changeSegment(segmentId, selected) {
      const segmentIndex = this.segments.findIndex(f => f.id === segmentId)
      if (segmentIndex > -1) {
        this.segments.splice(segmentIndex, 1, {
          ...this.segments[segmentIndex],
          selected,
        })

        if (!this.segments[segmentIndex].parent_id) {
          this.clearSelectedSubSegments()
        }
      }
    },
    getUser() {
      return this.userService.getAllInformations(this.$route.params.id)
        .then(response => {
          const { user } = response.data.data
          this.currentUser = user
        })
    },
    getSavedSearches() {
      return this.userService.getSavedSearches(this.$route.params.id)
        .then(response => {
          this.savedSearches = response.data.data.searches
        })
    },
    // eslint-disable-next-line camelcase
    searchUsers(loadMore, user_id, openSearch = null) {
      this.submited = true

      this.openedSearch = openSearch

      if (!loadMore) {
        this.isSubmiting = true

        this.results = []
        this.totalResults = 0
        this.page = 1

        const segments = this.selected.segments.map(segment => segment.id)
        const subSegments = this.selected.subSegments.map(segment => segment.id)
        const cities = this.selected.cities.map(city => city.id)
        const zones = this.selected.zones.map(zone => zone.id)
        const states = this.selected.states.map(state => state.initials)

        this.filter = {
          type: this.currentUser.type,
          order_by: this.orderBySelected,

          preferred_zones: [
            ...states,
            ...zones,
            ...cities,
          ],

          tags: this.tags,

          only_actives: this.onlyActive,
          internal_search: true,

          optionals: Object.keys(this.selectedOptionals).filter(optional => this.selectedOptionals[optional]),

          differentials: Object.keys(this.selectedDifferentials).filter(diferential => this.selectedDifferentials[diferential]),

          preferred_segments: subSegments.length ? subSegments : segments,

          currentUserId: this.$route.params.id,
          // eslint-disable-next-line camelcase
          ...user_id ? {
            user_id,
          } : {},
        }
      } else {
        this.isLoadingMore = true
      }

      this.filter.page = this.page

      return this.userService.search(this.filter)
        .then(response => {
          this.searchUrl = response.request.responseURL

          this.results.push(...response.data.data.data.map(user => {
            const searchSavedWithThisUser = this.savedSearches.find(search => search.search_user_ids.includes(user.id))
            const searchSentWithThisUser = this.savedSearches.find(search => search.search_user_ids.includes(user.id) && search.sent)

            return {
              ...user,
              searchSavedWithThisUser,
              searchSentWithThisUser,
            }
          }))

          this.totalResults = response.data.data.total

          this.hasNextPage = response.data.data.current_page < response.data.data.last_page
        })
        .catch(error => {
          this.results = []
          this.totalResults = 0

          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isSubmiting = false
          this.isLoadingMore = false
        })
    },
    parseUserName(name) {
      const nameArray = name.split(' ')
      let ret = nameArray[0]
      if (nameArray[0].length <= 2) {
        ret += ` ${nameArray[1]}.`
      } else if (nameArray[1] && nameArray[1].length) {
        ret += ` ${nameArray[1][0]}.`
      }

      return ret
    },
    prepareSegments(segmentIds) {
      const preparedSegments = []

      if (
        this.filter.preferred_segments
        && segmentIds
      ) {
        segmentIds.forEach(segmentId => {
          const segment = this.parentSegments.find(f => (f.id).toString() === (segmentId).toString())

          if (segment) {
            preparedSegments.push({
              ...segment,
              matchesFilter: this.filter.preferred_segments.some(s => (s).toString() === (segmentId).toString()),
            })
          }
        })
      }

      return preparedSegments.sort((a, b) => (
      // When comparing matched with non-matcheds segments, the matched segment comes first
        b.matchesFilter - a.matchesFilter

      // Otherwise, compare their names
      ) || a.name.localeCompare(b.name))
    },
    triggerInfiniteScroll(visible) {
      if (!visible || this.isLoadingMore) {
        return
      }

      this.page += 1
      this.searchUsers(true, null, this.openedSearch)
    },
    resetSelected() {
      this.clearSelectedStates()
      this.clearSelectedSegments()

      this.selectedDifferentials = Object.keys(this.selectedDifferentials).reduce((carry, k) => ({ ...carry, [k]: false }), {})
      this.selectedOptionals = Object.keys(this.selectedOptionals).reduce((carry, k) => ({ ...carry, [k]: false }), {})

      this.tags = ''
    },
    validateUserSelection() {
      if (this.selected.users.length > 20) {
        const overflowingContacts = this.selected.users.length - 20
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Não é possível enviar mais do que 20 contatos na búsca. Por favor remova ${overflowingContacts > 1 ? `${overflowingContacts} contatos` : '1 contato'} e tente novamente.`,
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
        return false
      }

      if (this.selected.users.length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Nenhum usuário selecionado',
            icon: 'BellIcon',
            variant: 'danger',
          },
        })

        return false
      }

      return true
    },
    copySearchUrl() {
      const urlParamsUserIds = this.openedSearch.search_user_ids.map(userId => `user_id[]=${userId}`)

      const baseUrl = process.env.NODE_ENV !== 'development' ? 'https://www.hafidme.com.br/busca?' : 'http://127.0.0.1/busca?'

      const el = document.createElement('textarea')
      el.value = `${baseUrl}${urlParamsUserIds.join('&')}`
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Endereço copiado com sucesso',
          icon: 'LinkIcon',
          variant: 'success',
        },
      })

      if (selected) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(selected)
      }

      return true
    },
    triggerVisibilityResultTitle(v) {
      this.actionResultsStickySize = !v ? this.$refs['actions-results'].parentElement.clientHeight : 0
    },
    sendSearch() {
      if (this.isSendingSearch) {
        return false
      }

      this.isSendingSearch = true

      return this.userService.sendSearch(this.openedSearch.id)
        .then(() => {
          this.savedSearches.find(f => f.id === this.openedSearch.id).sent = true

          this.results.forEach((user, key) => {
            if (this.openedSearch.search_user_ids.includes(user.id)) {
              this.results[key].searchSentWithThisUser = this.openedSearch
              this.results[key].selected = false
            }
          })

          this.$forceUpdate()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Busca enviada',
              icon: 'LinkIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isSendingSearch = false
        })
    },
    reopenSavedSearch(searchId) {
      this.resetSelected()

      const search = this.savedSearches.find(s => s.id === searchId)
      this.searchUsers(false, search.search_user_ids, search)

      this.$refs['modal-reopen-search'].hide()
    },
    selectFromFilters(searchFilters) {
      this.resetSelected()

      const {
        order_by: orderBy,
        tags,
        optionals,
        differentials,
        preferred_zones: preferredZones,
        preferred_segments: preferredSegments,
      } = searchFilters

      if (orderBy) {
        this.orderBySelected = orderBy
      }

      if (tags) {
        this.tags = tags
      }

      if (optionals && optionals.length) {
        Object.keys(this.selectedOptionals).forEach(optional => {
          if (optionals.includes(optional)) {
            this.selectedOptionals[optional] = true
          }
        })

        this.selectedOptionals = { ...this.selectedOptionals }
      }

      if (differentials && differentials.length) {
        Object.keys(this.selectedDifferentials).forEach(differential => {
          if (differentials.includes(differential)) {
            this.selectedDifferentials[differential] = true
          }
        })

        this.selectedDifferentials = { ...this.selectedDifferentials }
      }

      if (preferredZones) {
        const cities = []
        this.selectedCities = []
        preferredZones.forEach(zone => {
          if (typeof zone === 'number') {
            this.changeZone(zone, true)
          } else if (typeof zone === 'string' && zone[0] === 'C') {
            cities.push(this.citiesOptions.find(f => f.value === zone))
          } else if (typeof zone === 'string') {
            this.changeState(zone, true)
          }
        })

        if (cities) {
          this.selectedCities = cities
          this.activeTab = 'city'
        }
      }

      if (preferredSegments) {
        preferredSegments.forEach(segment => {
          const currentSegment = this.segments.find(f => f.id === segment)
          if (currentSegment.parent_id) {
            this.changeSegment(currentSegment.parent_id, true)
          }

          this.changeSegment(segment, true)
        })
      }
    },
    saveSearch() {
      if (this.isSavingSearch || !this.validateUserSelection()) {
        return null
      }

      this.isSavingSearch = true

      const userIds = this.selected.users.map(user => user.id)

      return this.userService.saveSearch(this.$route.params.id, {
        userIds,
        filter: this.filter,
      })
        .then(response => {
          const { search } = response.data.data
          this.savedSearches = [search, ...this.savedSearches]

          this.results.forEach((user, key) => {
            if (search.search_user_ids.includes(user.id)) {
              this.results[key].searchSavedWithThisUser = search
              this.results[key].selected = false
            }
          })

          this.$forceUpdate()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Busca salva',
              icon: 'LinkIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.errors ? error.response.data.errors.join('\n') : error.response,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.isSavingSearch = false
        })
        .then(this.getSavedSearches)
    },
  },
}
</script>
