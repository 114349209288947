<template>
  <div>
    <b-card-actions
      ref="filters"
      class="form-filters"
      :action-close="false"
      :action-refresh="false"
      :action-collapse="true"
      title="Reforço de Interesse"
      sub-title="Conexões iniciadas e não lidas na pasta principal"
    >
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="Período"
            label-for="date-filter-reinforcement"
            invalid-feedback="Selecione um período"
          >
            <flat-pickr
              id="date-filter-reinforcement"
              ref="datePicker"
              v-model="filtersValues.dateRange"
              class="form-control"
              :config="{
                mode: 'range',
                allowInput: false,
                position: 'auto center',
                enableTime: false,
                dateFormat: 'Y-m-d',
                altFormat: 'd/m/Y',
                altInput: true,
                locale: Portuguese,
              }"
            />
            <!-- @on-close="onCloseCalendar(getMetrics)"-->
          </b-form-group>
        </b-col>

        <b-col
          cols="4"
          class="form-buttons"
        >
          <b-form-group
            label=""
            label-for="btn-buscar"
            style="margin-top: 21px"
          >
            <b-button
              id="btn-buscar"
              variant="primary"
              :disabled="isSubmitting"
              @click="getConversations()"
            >
              Buscar
            </b-button>

            <b-button
              id="btn-limpar"
              :disabled="isSubmitting"
              variant="outline-secondary"
              @click="reset()"
            >
              Limpar
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-actions>

    <b-card
      v-if="submitted"
      :class="{
        'pointer-events-none': isSubmitting
      }"
    >
      <h4>Resultados</h4>
      <div class="text-right">
        <b-button
          :class="{invisible: !selectedResults.length}"
          variant="gradient-primary"
          :disabled="isSubmitting"
          @click="interestReinforcement(false)"
        >
          Reforçar Interesse
        </b-button>
      </div>

      <b-table
        ref="table-interest-reinforcement-results"
        fixed
        class="table-responsive-xl mt-1"
        :busy="isLoadingResults"
        :items="results"
        selected-variant="none"
        show-empty
        :fields="[
          { key: 'selected', thStyle: 'vertical-align: middle; width: 75px;' },
          { key: 'conversation_created_at', thStyle: 'vertical-align: middle; width: 150px;' },
          { key: 'name', thStyle: 'vertical-align: middle;', tdClass: 'text-break', },
        ]"
        selectable
        no-local-sorting
      >
        <template #empty>
          <h4 class="text-center mt-2 mb-2">
            Nenhuma conexão encontrada
          </h4>
        </template>

        <template #table-busy>
          <div class="text-center table-spinner">
            <b-spinner label="Loading..." />
            <strong>Carregando...</strong>
          </div>
        </template>

        <template #head(selected)>
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedResults.length === results.length"
              @change="toggleAll"
            />
          </div>
        </template>

        <template #cell(selected)="row">
          <div class="text-nowrap">
            <b-form-checkbox
              tabindex="-1"
              :checked="table.isRowSelected(row.index)"
              @change="table.isRowSelected(row.index) ? table.unselectRow(row.index) : table.selectRow(row.index)"
            />
          </div>
        </template>

        <template #head(conversation_created_at)>
          <div class="text-nowrap">
            Data de<br>
            conexão
          </div>
        </template>

        <template #cell(conversation_created_at)="row">
          {{ formatDateTimeDbToView(row.item.conversation_created_at) }}
        </template>

        <template #head(name)>
          <div class="text-nowrap">
            Destinatário
          </div>
        </template>

        <template #cell(name)="row">
          <div
            class="d-flex align-items-start justify-content-start"
          >
            <b-avatar
              size="40"
              :src="row.item.avatar ? row.item.avatar.sm : ''"
              variant="light-primary"
              class="mr-1"
            >
              <feather-icon
                v-if="!row.item.name"
                icon="UserIcon"
                size="22"
              />
            </b-avatar>
            <div>
              ({{ row.item.id }})<br>
              {{ row.item.name }}
            </div>
          </div>
        </template>
      </b-table>

    </b-card>
    <b-modal
      ref="modal-interest-reinforcement-confirmation"
      title="Reforçar Interesse"
      no-close-on-esc
      no-close-on-backdrop
      :hide-header-close="isSubmitting"
    >
      <p>
        Você está prestes a reforçar o interesse desse usuário em {{ selectedResults.length }} contatos.
      </p>
      <p>
        Por quais meios você deseja realizar o envio do link da mensagem?
      </p>
      <b-form-checkbox
        v-for="channel in channelsToSend"
        :key="channel.value"
        v-model="channel.selected"
        :disabled="isSubmitting"
        switch
      >
        {{ channel.text }}
      </b-form-checkbox>

      <template #modal-footer>
        <button
          type="button"
          class="btn btn-outline-secondary"
          :disabled="isSubmitting"
          @click.prevent="$refs['modal-interest-reinforcement-confirmation'].hide()"
        >
          Voltar
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="isSubmitting"
          @click.prevent="interestReinforcement(true)"
        >
          Enviar
          <b-spinner
            v-if="isSubmitting"
            small
          />
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatCamelCase, formatDateTimeDbToView } from '@/utils/helpers'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import ConnectionService from '@/services/connectionService'

export default {
  components: {
    BAvatar,
    BButton,
    BCard,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BRow,
    BSpinner,
    BTable,

    BCardActions,

    flatPickr,
  },
  data() {
    return {
      formatDateTimeDbToView,
      formatCamelCase,
      Portuguese,

      isSubmitting: false,
      submitted: false,
      isLoadingResults: false,

      userService: null,
      connectionService: null,

      results: [],

      filtersInitialValues: {},
      filtersValues: {
        dateRange: '',
      },

      channelsToSend: [
        {
          value: 'email',
          text: 'E-mail',
          selected: false,
        },
        {
          value: 'whatsapp',
          text: 'WhatsApp',
          selected: false,
        },
        {
          value: 'campaign',
          text: 'Campanha 70 em 10',
          selected: false,
        },
      ],

      removeCampaignWatcher: () => {
      },
      removeOthersWatcher: () => {
      },
    }
  },
  computed: {
    table() {
      return this.$refs['table-interest-reinforcement-results']
    },
    selectedResults() {
      return this.results.reduce((carry, result, currentIndex) => ([
        ...carry,
        ...this.table && this.table.isRowSelected(currentIndex) ? [result] : [],
      ]), [])
    },
  },
  async created() {
    this.userService = new UserService()
    this.connectionService = new ConnectionService()
    this.filtersInitialValues = { ...this.filtersValues }
  },
  mounted() {
    this.removeCampaignWatcher = this.$watch(
      () => this.channelsToSend.find(channel => channel.value === 'campaign').selected,
      checked => {
        if (!checked) {
          return
        }

        // Campaign was selected
        // Uncheck others
        this.channelsToSend
          .filter(channel => channel.value !== 'campaign')
          .forEach(channel => {
            const c = channel
            c.selected = false
          })
      },
    )

    this.removeOthersWatcher = this.$watch(
      () => this.channelsToSend.some(channel => channel.value !== 'campaign' && channel.selected),
      checked => {
        if (!checked) {
          return
        }

        // Something other than the campaign was checked
        // Uncheck campaign
        this.channelsToSend.find(channel => channel.value === 'campaign').selected = false
      },
    )
  },
  beforeDestroy() {
    this.removeCampaignWatcher()
    this.removeOthersWatcher()
  },
  methods: {
    reset() {
      this.filtersValues = { ...this.filtersInitialValues }
    },
    toggleAll() {
      if (this.selectedResults.length === this.results.length) {
        this.table.clearSelected()
      } else {
        this.table.selectAllRows()
      }
    },
    getConversations() {
      this.isSubmitting = true
      this.submitted = true
      this.isLoadingResults = true

      let startDate = ''
      let endDate = ''

      const split = this.filtersValues.dateRange.split(' até ')
      if (split.length === 2) {
        startDate = split[0].trim()
        endDate = split[1].trim()
      } else {
        startDate = this.filtersValues.dateRange
        endDate = this.filtersValues.dateRange
      }

      this.connectionService.unreadOpenConversations(this.$route.params.id, {
        ...startDate ? { startDate } : {},
        ...endDate ? { endDate } : {},
      }).then(response => {
        if (response.data.status) {
          this.results = response.data.data.connections
        }
      }).finally(() => {
        this.isSubmitting = false
        this.isLoadingResults = false
      })
    },
    interestReinforcement(send) {
      if (!send) {
        if (!this.selectedResults.length) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: 'Nenhum resultado selecionado',
            },
          })
          return
        }

        this.$refs['modal-interest-reinforcement-confirmation'].show()
        return
      }

      this.isSubmitting = true
      this.connectionService.interestReinforcement({
        'channels[]': this.channelsToSend.filter(f => f.selected).map(m => m.value),
        'conversationIds[]': this.selectedResults.map(connection => connection.conversation_id),
      }).then(response => {
        if (response.data.status) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Reforços de interesse enviados com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          this.$refs['modal-interest-reinforcement-confirmation'].hide()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }

        this.isSubmitting = false
      }).catch(response => {
        this.isSubmitting = false

        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })
    },
  },
}
</script>
