import useJwt from '@/auth/jwt/useJwt'
import LocalStorageService from '@/services/localStorageService'

export default class PresentationTemplateService {
  // eslint-disable-next-line class-methods-use-this
  list() {
    return useJwt.get('/presentation-templates')
  }

  // eslint-disable-next-line class-methods-use-this
  getAndStore() {
    if (LocalStorageService.getArray(LocalStorageService.presentationTemplates) !== null) {
      return new Promise(resolve => {
        resolve(LocalStorageService.getArray(LocalStorageService.presentationTemplates))
      })
    }

    return this.list().then(response => {
      if (response.data !== null) {
        let templates = response.data.data.presentation_templates
        if (Array.isArray(templates)) {
          templates = templates.map(m => ({
            ...m,
            variables: []
              .concat(m.template.indexOf('%segmento%') > -1 ? 'segmento' : [])
              .concat(m.template.indexOf('%tempo%') > -1 ? 'tempo' : [])
              .concat(m.template.indexOf('%qtd-cliente%') > -1 ? 'qtd-cliente' : [])
              .concat(m.template.indexOf('%cliente%') > -1 ? 'cliente' : [])
              .concat(m.template.indexOf('%produto%') > -1 ? 'produto' : [])
              .concat(m.template.indexOf('%estado%') > -1 ? 'estado' : [])
              .concat(m.template.indexOf('%cidade%') > -1 ? 'cidade' : [])
              .concat(m.template.indexOf('%regiao%') > -1 ? 'regiao' : [])
              .concat(m.template.indexOf('%zona%') > -1 ? 'zona' : [])
              .concat(m.template.indexOf('%perfil%') > -1 ? 'perfil' : [])
              .concat(m.template.indexOf('%marca%') > -1 ? 'marca' : []),
          }))
        }

        LocalStorageService.setArray(LocalStorageService.presentationTemplates, templates)
        return templates
      }

      return []
    })
  }
}
