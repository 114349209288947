<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <div style="height: 20px" />
    <b-row>
      <b-col cols="12">
        <b-tabs
          ref="tabs"
          v-model="activeTab"
          vertical
          pills
          content-class="mt-0 overflow-auto"
          class="flex-nowrap"
          @activate-tab="changeTab"
        >
          <b-tab
            id="tab-foto"
            title=""
          >
            <template #title>
              <div class="text-center w-100">
                <b-avatar
                  v-if="userEditing.avatar && userEditing.avatar.sm"
                  size="70px"
                  :src="userEditing.avatar.sm"
                />
                <b-avatar
                  v-else
                  size="70px"
                  variant="light-danger"
                  :text="getInitials(userEditing.name)"
                />
              </div>
            </template>

            <div
              v-if="isLoading"
              class="text-center"
            >
              <b-spinner />
              <p>Carregando...</p>
            </div>
          </b-tab>

          <!--        principal -->
          <b-tab
            id="tab-principal"
            title="Dados de uso"
            class="registration-info"
          >
            <b-row>
              <b-col>
                <b-card>
                  <b-card-header>
                    <b-card-title>Estatísticas por data</b-card-title>

                    <b-input-group style="width: 300px">
                      <flat-pickr
                        v-model="rangeDate"
                        class="form-control"
                        placeholder="Filtrar por período"
                        :config="pickrConfig"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          :disabled="isLoadingStatistics"
                          @click="getStatistics"
                        >
                          <feather-icon
                            animation="spin-pulse"
                            icon="SearchIcon"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-card-header>

                  <b-card-body>
                    <b-table
                      :busy="isLoadingStatistics"
                      responsive
                      :items="statistic_items.by_date"
                      :fields="statistic_fields"
                      sort-icon-left
                    >
                      <template #table-busy>
                        <div class="text-center table-spinner">
                          <b-spinner label="Loading..." />
                          <strong>Carregando...</strong>
                        </div>
                      </template>

                      <template #head(name)>
                        Estatística
                      </template>

                      <template #head(first_4_days)>
                        Primeiros 4 dias
                        <feather-icon
                          v-b-tooltip.hover="'Pasta Principal (Pasta Outros)'"
                          icon="HelpCircleIcon"
                          size="15"
                          class="align-text-top"
                        />
                      </template>

                      <template #head(first_15_days)>
                        Primeiros 15 dias
                        <feather-icon
                          v-b-tooltip.hover="'Pasta Principal (Pasta Outros)'"
                          icon="HelpCircleIcon"
                          size="15"
                          class="align-text-top"
                        />
                      </template>

                      <template #head(all_time)>
                        Todo período
                        <feather-icon
                          v-b-tooltip.hover="'Principal (Outros)'"
                          icon="HelpCircleIcon"
                          size="15"
                          class="align-text-top"
                        />
                      </template>

                      <template #head(last_30_days)>
                        Últimos 30 dias
                        <feather-icon
                          v-b-tooltip.hover="'Pasta Principal (Pasta Outros)'"
                          icon="HelpCircleIcon"
                          size="15"
                          class="align-text-top"
                        />
                      </template>

                      <template #head(selected_time)>
                        Período Selecionado
                        <feather-icon
                          v-b-tooltip.hover="'Pasta Principal (Pasta Outros)'"
                          icon="HelpCircleIcon"
                          size="15"
                          class="align-text-top"
                        />
                      </template>

                      <template #cell(first_4_days)="row">
                        <span v-if="['Mensagens enviadas', 'Mensagens recebidas', 'Mensagens lidas'].includes(row.item.name)">
                          {{ row.item.first_4_days.primary }} ({{ row.item.first_4_days.others }})
                        </span>
                        <span v-else>
                          {{ row.item.first_4_days }}
                        </span>
                      </template>

                      <template #cell(first_15_days)="row">
                        <span v-if="['Mensagens enviadas', 'Mensagens recebidas', 'Mensagens lidas'].includes(row.item.name)">
                          {{ row.item.first_15_days.primary }} ({{ row.item.first_15_days.others }})
                        </span>
                        <span v-else>
                          {{ row.item.first_15_days }}
                        </span>
                      </template>

                      <template #cell(all_time)="row">
                        <span v-if="['Mensagens enviadas', 'Mensagens recebidas', 'Mensagens lidas'].includes(row.item.name)">
                          {{ row.item.all_time.primary }} ({{ row.item.all_time.others }})
                        </span>
                        <span v-else>
                          {{ row.item.all_time }}
                        </span>
                      </template>

                      <template #cell(last_30_days)="row">
                        <span v-if="['Mensagens enviadas', 'Mensagens recebidas', 'Mensagens lidas'].includes(row.item.name)">
                          {{ row.item.last_30_days.primary }} ({{ row.item.last_30_days.others }})
                        </span>
                        <span v-else>
                          {{ row.item.last_30_days }}
                        </span>
                      </template>

                      <template #cell(selected_time)="row">
                        <span v-if="['Mensagens enviadas', 'Mensagens recebidas', 'Mensagens lidas'].includes(row.item.name)">
                          {{ row.item.selected_time.primary }} ({{ row.item.selected_time.others }})
                        </span>
                        <span v-else>
                          {{ row.item.selected_time }}
                        </span>
                      </template>
                    </b-table>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row v-if="userEditing && userEditing.situation === 'paying'">
              <b-col>
                <health-score :user-id-from-props="userEditing.id" />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="6">
                <b-card>
                  <b-card-header>
                    <b-card-title>Ferramentas Utilizadas</b-card-title>
                  </b-card-header>

                  <b-card-body v-if="isLoadingStatistics">
                    <div
                      v-if="isLoadingStatistics"
                      class="text-center"
                    >
                      <b-spinner />
                      <p>Carregando...</p>
                    </div>
                  </b-card-body>

                  <b-card-body v-else>
                    <b-form-group
                      label="Mensagem Padrão salva"
                      label-for="message-default"
                      label-cols="10"
                      content-cols="2"
                    >
                      <b-form-checkbox
                        id="message-default"
                        :disabled="true"
                        :checked="statistic_items.static.default_response"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      label="Resposta automática salva"
                      label-for="response-default"
                      label-cols="10"
                      content-cols="2"
                    >
                      <b-form-checkbox
                        id="response-default"
                        :disabled="true"
                        :checked="statistic_items.static.automatic_response"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      label="Turbine seu perfil"
                      label-for="turbo_profile"
                      label-cols="10"
                      content-cols="2"
                    >
                      <b-form-checkbox
                        id="turbo_profile"
                        :disabled="true"
                        :checked="statistic_items.static.turbo_profile"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-form-group
                      label="Favoritos"
                      label-for="favorites"
                      label-cols="10"
                      content-cols="2"
                    >
                      <b-form-checkbox
                        id="favorites"
                        :disabled="true"
                        :checked="statistic_items.static.favorites"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                        inline
                      >
                        <span class="switch-icon-left">
                          <feather-icon icon="CheckIcon" />
                        </span>
                        <span class="switch-icon-right">
                          <feather-icon icon="XIcon" />
                        </span>
                      </b-form-checkbox>
                    </b-form-group>

                    <div
                      class="d-flex justify-content-between align-items-center mt-3"
                    >
                      <h4>
                        Campanhas Ativas
                      </h4>
                      <b-button
                        variant="flat-primary"
                        :disabled="isLoadingWhatsappCampaigns"
                        @click="getWhatsappCampaigns()"
                      >
                        <feather-icon
                          v-if="!isLoadingWhatsappCampaigns"
                          icon="RefreshCwIcon"
                        />
                      </b-button>
                    </div>

                    <div
                      v-if="isLoadingWhatsappCampaigns"
                      class="text-center"
                    >
                      <b-spinner />
                      <p>Carregando...</p>
                    </div>

                    <div
                      v-if="!isLoadingWhatsappCampaigns"
                    >
                      <div v-if="whatsappCampaigns.length === 0">
                        <span>Nenhuma</span>
                      </div>
                      <div
                        v-for="campaign in whatsappCampaigns"
                        :key="campaign.id"
                        class="d-flex justify-content-between align-items-center"
                      >
                        <span>{{ $t(`whatsapp_campaign.campaign.${campaign.campaign}`) }} (Etapa {{ campaign.stage }})</span>
                        <b-button
                          variant="flat-primary"
                          :disabled="isLoadingRemoveWhatsappCampaign"
                          @click="deleteWhatsappCampaign(campaign.id)"
                        >
                          <b-spinner
                            v-if="isLoadingRemoveWhatsappCampaign"
                            small
                          />
                          <feather-icon
                            v-if="!isLoadingRemoveWhatsappCampaign"
                            icon="TrashIcon"
                          />
                        </b-button>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col
                v-if="userData && userData.permission_type && userData.permission_type === 'sales'"
                cols="6"
              >
                <b-card>
                  <b-card-header>
                    <b-card-title>Agendamento de Tour</b-card-title>
                  </b-card-header>

                  <b-card-body>
                    <div
                      v-if="isLoading"
                      class="text-center"
                    >
                      <b-spinner />
                      <p>Carregando...</p>
                    </div>

                    <div v-else>
                      <div v-if="!userEditing.settings || !userEditing.settings.success_manager">
                        <p>Nenhum consultor de Sucesso atribuído ao usuário.</p>
                        <b-button
                          :disabled="isLoadingSuccessManager"
                          @click="sortSuccessManager"
                        >
                          <b-spinner
                            v-if="isLoadingSuccessManager"
                            small
                          />
                          Atribuir consultor de Sucesso
                        </b-button>
                      </div>

                      <b-form-group
                        v-else
                        :label="`Link de agendamento de ${userEditing.settings.success_manager.name}: `"
                        class="mt-2"
                      >
                        <div class="d-flex justify-content-between align-items-center">
                          <span>
                            {{ userEditing.settings.success_manager.link_schedule_call === '' ? 'Consultor não tem link de agendamento' : userEditing.settings.success_manager.link_schedule_call }}
                          </span>

                          <b-button
                            variant="flat-primary"
                            title="Copiar link"
                            @click="sendToClipboard(userEditing.settings.success_manager.link_schedule_call)"
                          >
                            <feather-icon icon="CopyIcon" />
                          </b-button>
                        </div>
                      </b-form-group>
                    </div>
                  </b-card-body>
                </b-card>
              </b-col>

              <b-col
                v-if="userData && userData.permission_type && ['root', 'management_processes'].includes(userData.permission_type)"
                cols="6"
              >
                <b-card>
                  <b-card-header>
                    <b-card-title>Mover Card do Kanban</b-card-title>
                  </b-card-header>

                  <b-card-body>
                    <move-card
                      v-if="!isLoadingCard"
                      :data="cardData"
                    />
                    <b-spinner v-else />
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-tabs>
                  <b-tab>
                    <template #title>
                      <feather-icon icon="FileTextIcon" />
                      <span>Anotações</span>
                    </template>

                    <b-card
                      no-body
                    >
                      <user-list-annotation
                        :user-id="parseInt($route.params.id, 10)"
                        :manager-id="userEditing.settings ? userEditing.settings.account_manager_id : null"
                      />
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                      <feather-icon icon="EditIcon" />
                      <span>Atividades</span>
                    </template>

                    <b-card
                      ref="cardActions"
                      no-body
                    >
                      <b-card-header>
                        <b-card-title>Atividades</b-card-title>
                        <b-card-title>
                          <b-button
                            variant="flat-secondary"
                            @click="$refs.userListActivities.getActivities()"
                          >
                            <feather-icon icon="RotateCwIcon" />
                          </b-button>
                        </b-card-title>
                      </b-card-header>

                      <user-list-activities
                        ref="userListActivities"
                        name="userListActivities"
                        :user="userEditing"
                        :user-id="parseInt($route.params.id, 10)"
                      />
                    </b-card>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-tab>

          <!-- dados cadastrais -->
          <b-tab
            id="tab-dados-cadastrais"
            title="Dados cadastrais"
          >
            <alert-registration-warnings
              v-if="!isLoading && ['basic', 'filter', 'review'].includes(userBase.status)"
              :registration-warnings="userBase.settings.registration_warnings"
              :user-id="parseInt($route.params.id)"
              @updated="updateUserBase()"
            />

            <b-card :class="parseInt(userEditing.test) === 1 ? 'card-test' : ''">
              <div
                v-if="isLoading"
                class="text-center"
              >
                <b-spinner />
                <p>Carregando...</p>
              </div>

              <b-form v-if="!isLoading">
                <validation-observer ref="rulesRegistration">
                  <b-alert
                    v-if="outdatedMessage"
                    show
                    :variant="outdatedMessage.variant"
                  >
                    <div class="alert-body font-large-1">
                      <feather-icon
                        :icon="outdatedMessage.icon"
                        class="mr-50"
                        size="28"
                      />
                      {{ outdatedMessage.text }}
                    </div>
                  </b-alert>
                  <h4>Informações Principais</h4>
                  <b-row>
                    <b-col
                      cols="4"
                      class="mb-1"
                    >
                      <avatar-input
                        ref="avatar"
                        :name-photo="userEditing.name"
                        :user-id="userEditing.id"
                        :method-to-save="userService.saveAvatar"
                        :method-to-rotate90="userService.rotateAvatar90"
                        :method-to-rotate270="userService.rotateAvatar270"
                        :method-to-remove="userService.removeAvatar"
                        :avatar-image="userEditing.avatar"
                        @preview="previewAvatar"
                      />
                    </b-col>

                    <b-col
                      cols="4"
                      class="text-center"
                    >
                      <file-input
                        ref="fileInput"
                        :user="userEditing"
                        @update="updateFileInUserBase"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label="Status"
                        label-for="status"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-select
                            id="registration_info_status"
                            v-model="userEditing.status"
                            :options="userStatus"
                            :state="errors.length > 0 ? false:null"
                            :disabled="disableNonRootSaveForBlocked|| isSubmiting || userBase.status === 'review' || userBase.status === 'filter' || userBase.deleted_at !== null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="userData.permission_type === 'root'"
                      cols="4"
                    >
                      <b-form-group
                        label="Teste"
                        label-for="test"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-select
                            id="registration_info_test"
                            v-model="userEditing.test"
                            :options="userTest"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="parseInt(userEditing.test) === 1"
                      cols="4"
                    >
                      <h3 class="text-danger">
                        Usuário de TESTE
                      </h3>
                      <p class="text-danger">
                        Nunca aparece nas buscas<br>
                        Não vê o menu de Assinatura<br>
                        Não vê o menu de Serviços adicionais<br>
                        Deve ter o status ATIVO para que seja possível logar no site<br>
                        Deve ter o vencimento de seu plano alterado <strong>manualmente</strong> anos a frente.
                      </p>
                    </b-col>
                    <b-col
                      v-if="userEditing.status === 'blocked'"
                      cols="4"
                    >
                      <b-form-group
                        label="Motivo do bloqueio"
                        label-for="block-reason"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :rules="userEditing.status === 'blocked' ? 'required' : ''"
                        >
                          <b-form-select
                            id="block-reason"
                            v-model="userEditing.status_reason"
                            :options="userBlockReasons"
                            :state="errors.length > 0 ? false:null"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="userEditing.status === 'blocked' && userEditing.status_reason === 'double'"
                      cols="4"
                    >
                      <b-form-group
                        label="Id. do usuário original"
                        label-for="double-user-id"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :rules="userEditing.status === 'blocked' && userEditing.status_reason === 'double' && userBase.status === 'filter'? `required|integer|is-not:${this.$route.params.id}` : ''"
                        >
                          <b-button
                            v-if="userBase.status !== 'filter'"
                            :to="{ name: 'cms-user-form-update', params: { id: userEditing.settings.double_user_id } }"
                            target="_blank"
                            variant="gradient-primary"
                            @click.stop
                          >
                            {{ userEditing.settings.double_user_id }}
                            <feather-icon
                              icon="ExternalLinkIcon"
                              size="14"
                            />
                          </b-button>
                          <b-form-input
                            v-else
                            id="double-user-id"
                            v-model="userEditing.settings.double_user_id"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-button
                        v-if="userBase.settings.double_user_id"
                        :disabled="isSubmiting || isSubmitingMigrateLogin"
                        @click.prevent="migrateLogin"
                      >
                        Migrar dados de acesso
                        <b-spinner
                          v-if="isSubmitingMigrateLogin"
                          small
                        />
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="userBase.deleted_at !== null"
                      class="d-flex align-items-center"
                    >
                      <span class="text-danger">
                        REMOVIDO
                      </span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label="Tipo"
                        label-for="registration_info_type"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-select
                            id="registration_info_type"
                            ref="registration_info_type"
                            v-model="userEditing.type"
                            :options="userTypes"
                            :state="errors.length > 0 ? false:null"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!--                    <b-col cols="4">-->
                    <!--                      <b-button-->
                    <!--                        v-if="!isChangeType"-->
                    <!--                        style="margin-top: 20px"-->
                    <!--                        @click="isChangeType = true"-->
                    <!--                      >-->
                    <!--                        Trocar tipo-->
                    <!--                      </b-button>-->
                    <!--                    </b-col>-->
                  </b-row>

                  <b-row v-if="userEditing.type=='representative'">
                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label="Nome"
                        label-for="registration_info_name_rep"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            id="registration_info_name_rep"
                            v-model="userEditing.name"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label="CPF"
                        label-for="registration_info_cpf"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <cleave
                            id="registration_info_cpf"
                            v-model="userEditing.cpf"
                            :disabled="disableNonQualificationSaveForBlocked || isSubmiting"
                            class="form-control"
                            placeholder="000.000.000-00"
                            :options="maskCpf"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>

                  <b-row>
                    <b-col
                      v-if="userEditing.type === 'representative'"
                      cols="4"
                    >
                      <b-form-group
                        label="Empresa"
                        label-for="registration_info_company_constituted"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <b-form-input
                            id="registration_info_company_constituted"
                            v-model="userEditing.profile.company_constituted"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="userEditing.type === 'company'"
                      cols="4"
                    >
                      <b-form-group
                        label="Empresa"
                        label-for="registration_info_company_name"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <b-form-input
                            id="registration_info_company_name"
                            v-model="userEditing.profile.company_name"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label="CNPJ"
                        label-for="registration_info_cnpj"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <b-input-group>
                            <cleave
                              id="registration_info_cnpj"
                              v-model="userEditing.cnpj"
                              class="form-control"
                              placeholder="00.000.000/0000-00"
                              :options="maskCnpj"
                              :disabled="disableNonQualificationSaveForBlocked || isSubmiting"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>

                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                :disabled="isSubmiting"
                                title="Validar CNPJ"
                                @click="checkCnpj"
                              >
                                <b-spinner
                                  v-if="isLoading"
                                  small
                                />
                                <feather-icon
                                  v-if="!isLoading"
                                  animation="spin-pulse"
                                  icon="SearchIcon"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <user-check-cnpj
                        ref="userCheckCnpj"
                        :user.sync="userEditing"
                      />
                    </b-col>
                  </b-row>

                  <b-row v-if="userEditing.type === 'company'">
                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label="Responsável"
                        label-for="registration_info_name_company"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            id="registration_info_name_company"
                            v-model="userEditing.name"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="4"
                    >
                      <b-form-group
                        label="Cargo"
                        label-for="registration_info_position_in_company"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-input
                            id="registration_info_position_in_company"
                            v-model="userEditing.profile.position_in_company"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="E-mail principal"
                        label-for="registration_info_email"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-row>
                            <b-col cols="6">
                              <b-input-group>
                                <b-form-input
                                  id="registration_info_email"
                                  v-model="userEditing.email"
                                  :disabled="disableNonRootSaveForBlocked || isSubmiting"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small class="text-warning d-block">{{ hasAlertRegistration('email') }}</small>
                            </b-col>

                            <b-col
                              v-if="userEditing.email !== userEditing.email_master.old"
                              cols="6"
                            >
                              <span>Salve os dados antes de enviar o e-mail de confirmação</span>
                            </b-col>

                            <b-col
                              v-else
                              cols="6"
                              class="d-flex align-items-center"
                            >
                              <span
                                v-if="userEditing.email_master.conferred_at !== null"
                                class="ml-1 text-success"
                              >
                                <feather-icon
                                  animation="spin-pulse"
                                  icon="CheckIcon"
                                />
                                Conferido em {{ userEditing.email_master.conferred_at | date('DD/MM/Y HH:mm:ss') }} por {{ userEditing.name }}
                              </span>
                              <span
                                v-else-if="userEditing.email_master.verified_at !== null"
                                class="ml-1 text-success"
                              >
                                <feather-icon
                                  animation="spin-pulse"
                                  icon="CheckIcon"
                                />
                                Confirmado em {{ userEditing.email_master.verified_at | date('DD/MM/Y HH:mm:ss') }} por {{ confirmedBy(userEditing.email_master) }}
                              </span>
                              <div class="d-flex flex-wrap flex-column">
                                <b-button
                                  v-if="userEditing.email_master.verified_at === null"
                                  size="sm"
                                  :disabled="userEditing.email_master.isSubmitingConfirmation"
                                  @click="sendConfirmationEmail()"
                                >
                                  <b-spinner
                                    v-if="userEditing.email_master.isSubmitingConfirmation"
                                    small
                                  />
                                  <feather-icon
                                    v-else
                                    animation="spin-pulse"
                                    icon="PlusSquareIcon"
                                  />
                                  Enviar e-mail de confirmação
                                </b-button>
                                <small v-if="!userEditing.email_master.verified_at && userEditing.email_master.verification_sent_at">Enviado em: {{ userEditing.email_master.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
                              </div>

                              <b-button
                                size="sm"
                                variant="outline-secondary"
                                class="ml-1"
                                :disabled="userEditing.email_master.isSubmiting || isSubmiting"
                                @click="confirmEmail()"
                              >
                                <b-spinner
                                  v-if="userEditing.email_master.isSubmiting"
                                  small
                                />
                                <feather-icon
                                  v-else
                                  animation="spin-pulse"
                                  icon="CheckIcon"
                                />
                                <span v-if="userEditing.email_master.verified_at === null">
                                  Confirmar
                                </span>
                                <span v-else>
                                  Reconfirmar
                                </span>
                                manualmente
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <user-edit-emails
                        id="registration_info_emails_perfil"
                        :user-name="userEditing.name"
                        :emails.sync="userEditing.list_emails"
                        :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                        @setMasterEmail="setMasterEmail"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Emails invisíveis (Apenas para uso no CMS)"
                        label-for="registration_info_emails_cms"
                      >
                        <b-row
                          v-for="(_, i) in userEditing.emails_cms"
                          :key="i"
                          class="margin-bottom"
                        >
                          <b-col cols="4">
                            <b-input-group>
                              <b-form-input
                                v-model="userEditing.emails_cms[i]"
                                class="form-control"
                                placeholder="E-mail"
                                :disabled="isSubmiting"
                              />

                              <b-input-group-append>
                                <b-button
                                  variant="outline-primary"
                                  :disabled="isSubmiting"
                                  @click="userEditing.emails_cms = [...userEditing.emails_cms.filter((v, k) => k !== i)]"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <b-button
                          size="sm"
                          :disabled="isSubmiting"
                          @click="userEditing.emails_cms = [...userEditing.emails_cms, '']"
                        >
                          Adicionar e-mail
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Celular/WhatsApp"
                        label-for="registration_info_cell_phone"
                      >
                        <b-row>
                          <b-col cols="4">
                            <b-input-group>
                              <cleave
                                id="registration_info_cell_phone"
                                v-model="userEditing.cell_phone"
                                class="form-control"
                                placeholder="DDD + Número"
                                :options="userEditing.cell_phone.match(/[0-9]*/g).join('').length == 10 ? maskPhone8 : maskPhone9"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                              <b-input-group-append>
                                <b-button
                                  v-b-tooltip.hover.noninteractive="'Iniciar ligação com VOIP'"
                                  variant="outline-primary"
                                  :href="`callto:0${userEditing.cell_phone.match(/[0-9]*/g).join('')}`"
                                >
                                  <feather-icon icon="PhoneCallIcon" />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                          <b-col
                            v-if="userEditing.cell_phone !== userEditing.phone_master.old"
                            cols="8"
                          >
                            <span>Salve os dados antes de enviar o whats de confirmação</span>
                          </b-col>

                          <b-col
                            v-else
                            cols="8"
                            class="d-flex align-items-center"
                          >
                            <span
                              v-if="userEditing.phone_master.conferred_at !== null"
                              class="ml-1 text-success"
                            >
                              <feather-icon
                                animation="spin-pulse"
                                icon="CheckIcon"
                              />
                              Conferido em {{ userEditing.phone_master.conferred_at | date('DD/MM/Y HH:mm:ss') }} por {{ userEditing.name }}
                            </span>
                            <span
                              v-else-if="userEditing.phone_master.verified_at !== null"
                              class="ml-1 text-success"
                            >
                              <feather-icon
                                animation="spin-pulse"
                                icon="CheckIcon"
                              />
                              Confirmado em {{ userEditing.phone_master.verified_at | date('DD/MM/Y HH:mm:ss') }} por {{ confirmedBy(userEditing.phone_master) }}
                            </span>

                            <div class="d-flex flex-wrap flex-column">
                              <b-button
                                v-if="userEditing.phone_master.verified_at === null"
                                size="sm"
                                :disabled="userEditing.phone_master.isSubmitingConfirmation"
                                @click="sendConfirmationCellPone()"
                              >
                                <b-spinner
                                  v-if="userEditing.phone_master.isSubmitingConfirmation"
                                  small
                                />
                                <feather-icon
                                  v-else
                                  animation="spin-pulse"
                                  icon="PlusSquareIcon"
                                />
                                Enviar WhatsApp de confirmação
                              </b-button>
                              <small v-if="userEditing.phone_master.verified_at === null && userEditing.phone_master.verification_sent_at">Enviado em: {{ userEditing.phone_master.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
                            </div>

                            <b-button
                              size="sm"
                              variant="outline-secondary"
                              class="ml-1"
                              :disabled="userEditing.phone_master.isSubmiting || isSubmiting"
                              @click="confirmCellPhone()"
                            >
                              <b-spinner
                                v-if="userEditing.phone_master.isSubmiting"
                                small
                              />
                              <feather-icon
                                v-else
                                animation="spin-pulse"
                                icon="CheckIcon"
                              />
                              <span v-if="userEditing.phone_master.verified_at === null">
                                Confirmar
                              </span>
                              <span v-else>
                                Reconfirmar
                              </span>
                              manualmente
                            </b-button>

                          </b-col>
                        </b-row>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <user-edit-phones
                        id="registration_info_phones"
                        :user-name="userEditing.name"
                        :phones.sync="userEditing.list_phones"
                        :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                        @setMasterPhone="setMasterPhone"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Telefones invisíveis (Apenas para uso no CMS)"
                        label-for="registration_info_phones_cms"
                      >
                        <b-row
                          v-for="(_, i) in userEditing.phones_cms"
                          :key="i"
                          class="margin-bottom"
                        >
                          <b-col cols="4">
                            <b-input-group>
                              <cleave
                                v-model="userEditing.phones_cms[i]"
                                class="form-control"
                                placeholder="DDD + Número"
                                :options="userEditing.phones_cms[i].match(/[0-9]*/g).join('').length == 10 ? maskPhone8 : maskPhone9"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />

                              <b-input-group-append>
                                <b-button
                                  variant="outline-primary"
                                  :disabled="isSubmiting"
                                  @click="userEditing.phones_cms = [...userEditing.phones_cms.filter((v, k) => k !== i)]"
                                >
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                        </b-row>
                        <b-button
                          size="sm"
                          :disabled="isSubmiting"
                          @click="userEditing.phones_cms = [...userEditing.phones_cms, '']"
                        >
                          Adicionar telefone
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="4">
                      <user-extracted-phones
                        :user-id="userEditing.id"
                      />
                    </b-col>
                    <b-col cols="4" />
                  </b-row>

                  <div v-if="userBase.deleted_at === null">
                    <h4>Dados de Acesso</h4>
                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Alterar Senha"
                          label-for="registration_info_senha"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="min:3"
                          >
                            <b-form-input
                              id="registration_info_senha"
                              v-model="userEditing.password"
                              placeholder="deixar em branco para não alterar a senha"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col>
                        <b-form-group
                          label="Recuperação de senha"
                          label-for="btn_send_password_recovery"
                        >
                          <div class="d-flex justify-content-start">
                            <b-button
                              :disabled="disableNonRootSaveForBlocked || isSubmiting || isSubmitingDirectAccess || isSubmitingPasswordRecovery"
                              @click="sendPasswordRecovery('whatsapp')"
                            >
                              ENVIAR POR WHATSAPP
                            </b-button>
                            <b-button
                              class="ml-1"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting || isSubmitingDirectAccess || isSubmitingPasswordRecovery"
                              @click="sendPasswordRecovery('email')"
                            >
                              ENVIAR POR E-MAIL
                            </b-button>
                            <div
                              class="ml-1 d-flex align-items-center"
                            >
                              <b-spinner
                                v-if="isSubmitingPasswordRecovery"
                                small
                              />
                            </div>
                          </div>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          label="Acesso direto"
                          label-for="btn_direct_access"
                        >
                          <b-button
                            id="btn_direct_access"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isSubmitingDirectAccess || isSubmitingPasswordRecovery"
                            @click="sendDirectAccess()"
                          >
                            ENVIAR ACESSO DIRETO POR E-MAIL

                            <b-spinner
                              v-if="isSubmitingDirectAccess"
                              small
                            />
                          </b-button>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <div v-if="userBase.deleted_at === null">
                    <h4>Site e Redes Sociais</h4>
                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Site"
                          label-for="registration_info_site"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="min:5"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="LinkIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="registration_info_site"
                                v-model="userEditing.profile.link_site"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning">{{ hasAlertRegistration('link_site') }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          label="Facebook"
                          label-for="registration_info_facebook"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="min:5"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FacebookIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="registration_info_facebook"
                                v-model="userEditing.profile.link_facebook"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning">{{ hasAlertRegistration('link_facebook') }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Instagram"
                          label-for="registration_info_instagram"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="min:5"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="InstagramIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="registration_info_instagram"
                                v-model="userEditing.profile.link_instagram"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning">{{ hasAlertRegistration('link_instagram') }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          label="Linkedin"
                          label-for="registration_info_linkedin"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="min:5"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="LinkedinIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="registration_info_linkedin"
                                v-model="userEditing.profile.link_linkedin"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning">{{ hasAlertRegistration('link_linkedin') }}</small>

                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>

                  <h4>Endereço</h4>
                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label="CEP"
                        label-for="registration_info_zip_code"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required|min:8"
                        >
                          <b-input-group>
                            <cleave
                              id="registration_info_zip_code"
                              v-model="userEditing.address.zip_code"
                              class="form-control"
                              placeholder="CEP"
                              :options="maskCep"
                              :raw="false"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                            />

                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                                @click="getAddressByCep(userEditing.address.zip_code)"
                              >
                                <b-spinner
                                  v-if="isLoadingCep"
                                  small
                                />
                                <feather-icon
                                  v-if="!isLoadingCep"
                                  animation="spin-pulse"
                                  icon="SearchIcon"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label="Estado"
                        label-for="registration_info_state"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required|min:2"
                        >
                          <b-form-select
                            id="registration_info_state"
                            v-model="userEditing.address.state"
                            :options="userStates"
                            :state="errors.length > 0 ? false:null"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                            @change="selectState()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="4">
                      <b-form-group
                        label="Cidade"
                        label-for="registration_info_city"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="min:3"
                        >
                          <v-select
                            id="registration_info_city"
                            :value="userEditing.address.city"
                            label="title"
                            :options="cityOptions"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                            @input="selectCity"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                  </b-row>

                  <b-row>
                    <b-col cols="4">
                      <b-form-group
                        label="Bairro"
                        label-for="registration_info_neighbor"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="min:3"
                        >
                          <b-form-input
                            id="registration_info_neighbor"
                            v-model="userEditing.address.neighbor"
                            label="bairro"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="4">
                      <b-form-group
                        label="Rua"
                        label-for="registration_info_street"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="min:3"
                        >
                          <b-form-input
                            id="registration_info_street"
                            v-model="userEditing.address.street"
                            label="rua"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="2">
                      <b-form-group
                        label="Número"
                        label-for="registration_info_number"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="integer"
                        >
                          <b-form-input
                            id="registration_info_number"
                            v-model="userEditing.address.number"
                            label="number"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="2">
                      <b-form-group
                        label="Complemento"
                        label-for="registration_info_complement"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <b-form-input
                            id="registration_info_complement"
                            v-model="userEditing.address.complement"
                            label="number"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-form>

              <b-row>
                <b-col
                  cols="12"
                  class="text-right"
                >
                  <b-button
                    align="right"
                    variant="primary"
                    type="submit"
                    :disabled="disableNonQualificationSaveForBlocked || isSubmiting || isLoading"
                    @click.prevent="saveUserRegistration()"
                  >
                    Salvar
                    <b-spinner
                      v-if="isSubmiting"
                      small
                    />
                  </b-button>
                </b-col>
              </b-row>

            </b-card>
          </b-tab>

          <!--        demográfico -->
          <b-tab
            id="tab-demografico"
            title="Dados demográficos"
          >
            <user-list-demographic
              ref="userListDemographic"
              :user-responsible="userEditing.name"
              :user-type="userEditing.type"
              :user-id="parseInt(this.$route.params.id, 10)"
            />
          </b-tab>

          <!--        módulo oportunidade -->
          <b-tab
            id="tab-mod-oportunidade"
            title="MÓD. Oportunidade"
          >
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="18"
                class="mr-50"
              />
              MÓD. Oportunidade
            </template>
          </b-tab>

          <!--        perfil de busca -->
          <b-tab
            id="tab-perfil-de-busca"
            title="Perfil de busca"
          >
            <template #title>
              <span class="pl-1">Perfil de busca</span>
            </template>

            <div
              v-if="isLoading"
              class="text-center"
            >
              <b-spinner />
              <p>Carregando...</p>
            </div>

            <div v-if="!isLoading">
              <alert-registration-warnings
                v-if="['basic', 'filter', 'review'].includes(userBase.status)"
                :registration-warnings="userBase.settings.registration_warnings"
                :user-id="parseInt($route.params.id)"
                @updated="getUserBase()"
              />

              <b-card>
                <h4>Região e Segmento</h4>
                <div id="zones-perfil">
                  <div class="text-button">
                    <p><span>Regiões</span> <b-button
                      size="sm"
                      :disabled="disableNonRootSaveForBlocked || isSubmiting"
                      @click="loadZones($refs.userEditZonesPerfil)"
                    >
                      CARREGAR
                    </b-button></p>
                    <small class="text-danger">{{ errorsZonesPerfil }}</small>
                  </div>

                  <user-edit-zones
                    ref="userEditZonesPerfil"
                    :preferred-zones="userEditing.profile.preferred_zones"
                    :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                    @changed="changedPreferredZones"
                  />
                </div>

                <div style="height: 30px" />
                <div id="segments-perfil">
                  <div class="text-button">
                    <p>
                      <span>Segmentos</span>
                      <b-button
                        size="sm"
                        :disabled="disableNonRootSaveForBlocked || isSubmiting"
                        @click="loadSegments($refs.userEditSegmentsPerfil)"
                      >
                        CARREGAR
                      </b-button></p>
                    <small class="text-danger">{{ errorsSegmentsPerfil }}</small>
                  </div>

                  <user-edit-segments
                    ref="userEditSegmentsPerfil"
                    :preferred-segments="userEditing.profile.preferred_segments"
                    :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                    :user-type="userEditing.type"
                    @changed="changedPreferredSegments"
                  />
                </div>

                <div style="height: 30px" />

                <h4>Adicionais</h4>
                <validation-observer ref="rulesSearchProfile">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        label="Apresentação"
                        label-for="presentation_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required|min:10"
                        >
                          <b-form-textarea
                            id="presentation_perfil"
                            v-model="userEditing.profile.presentation"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaPresentation"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-warning d-block">{{ hasAlertRegistration('presentation') }}</small>
                        </validation-provider>
                      </b-form-group>

                      <!-- eslint-disable vue/no-v-html -->
                      <p v-html="susi.presentation"></p>

                      <p v-if="userEditing.profile.raw && userEditing.profile.raw.presentation">
                        {{ userEditing.profile.raw.presentation }}
                      </p>

                      <b-button
                        size="sm"
                        :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaPresentation"
                        @click="loadPresentationTemplate()"
                      >
                        Usar modelo
                      </b-button>

                      <b-button
                        size="sm"
                        class="ml-1"
                        :disabled="isLoadingIaPresentation"
                        @click="getIaFromPresentation()"
                      >
                        Carregar
                        <Iconify
                          icon="carbon:ai-recommend"
                          width="14"
                          height="14"
                        />

                        <b-spinner
                          v-if="isLoadingIaPresentation"
                          small
                        />
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row style="margin-top: 20px">
                    <b-col cols="4">
                      <div class="text-button">
                        <p><span>Palavras-chave</span> <b-button
                          size="sm"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click.prevent="loadTagsPerfil()"
                        >
                          CARREGAR
                        </b-button></p>
                      </div>

                      <validation-provider
                        #default="{ errors }"
                        rules=""
                      >
                        <b-form-textarea
                          id="tagsPerfil"
                          v-model="userEditing.tags"
                          rows="10"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <div style="height: 30px" />

                  <h4>Informações Comerciais</h4>
                  <b-row>
                    <b-col cols="3">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label="Produtos que deseja representar"
                        label-for="products_want_to_represent_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="products_want_to_represent_perfil"
                            v-model="userEditing.profile.products_want_to_represent"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaProductsWantToRepresent"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-warning">{{ hasAlertRegistration('products_want_to_represent') }}</small>
                        </validation-provider>

                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="susi.products_want_to_represent"></p>

                        <p v-if="userEditing.profile.raw && userEditing.profile.raw.products_want_to_represent && typeof userEditing.profile.raw.products_want_to_represent == 'object'">
                          {{ userEditing.profile.raw.products_want_to_represent.join("\n") }}
                        </p>

                        <b-button
                          size="sm"
                          class="mt-1"
                          :disabled="isLoadingIaProductsWantToRepresent"
                          @click="getIaFromProductsWantToRepresent()"
                        >
                          Carregar
                          <Iconify
                            icon="carbon:ai-recommend"
                            width="20"
                            height="20"
                          />

                          <b-spinner
                            v-if="isLoadingIaProductsWantToRepresent"
                            small
                          />
                        </b-button>
                      </b-form-group>

                      <b-form-group
                        v-if="userEditing.type=='company'"
                        label="Produtos da empresa"
                        label-for="products_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="products_perfil"
                            v-model="userEditing.profile.products"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="3">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label="Produtos que já representou"
                        label-for="represented_products_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="represented_products_perfil"
                            v-model="userEditing.profile.represented_products"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaRepresentedProducts"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-warning">{{ hasAlertRegistration('represented_products') }}</small>
                        </validation-provider>

                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="susi.represented_products"></p>

                        <p v-if="userEditing.profile.raw && userEditing.profile.raw.represented_products && typeof userEditing.profile.raw.represented_products == 'object'">
                          {{ userEditing.profile.raw.represented_products.join("\n") }}
                        </p>

                        <b-button
                          size="sm"
                          class="mt-1"
                          :disabled="isLoadingIaRepresentedProducts"
                          @click="getIaFromRepresentedProducts()"
                        >
                          Carregar
                          <Iconify
                            icon="carbon:ai-recommend"
                            width="20"
                            height="20"
                          />

                          <b-spinner
                            v-if="isLoadingIaRepresentedProducts"
                            small
                          />
                        </b-button>
                      </b-form-group>

                      <b-form-group
                        v-if="userEditing.type=='company'"
                        label="Marcas da empresa"
                        label-for="company_brands_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="company_brands_perfil"
                            v-model="userEditing.profile.company_brands"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="3">
                      <b-form-group
                        :label="userEditing.type=='representative' ? 'Clientes' : 'Principais clientes ativos'"
                        label-for="main_customers_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="main_customers_perfil"
                            v-model="userEditing.profile.main_customers"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaMainCustomers"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-warning">{{ hasAlertRegistration('main_customers') }}</small>
                        </validation-provider>

                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="susi.main_customers"></p>

                        <p v-if="userEditing.profile.raw && userEditing.profile.raw.main_customers && typeof userEditing.profile.raw.main_customers == 'object'">
                          {{ userEditing.profile.raw.main_customers.join("\n") }}
                        </p>

                        <b-button
                          size="sm"
                          class="mt-1"
                          :disabled="isLoadingIaMainCustomers"
                          @click="getIaFromMainCustomers()"
                        >
                          Carregar
                          <Iconify
                            icon="carbon:ai-recommend"
                            width="20"
                            height="20"
                          />

                          <b-spinner
                            v-if="isLoadingIaMainCustomers"
                            small
                          />
                        </b-button>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="userEditing.type=='representative'"
                      cols="3"
                    >
                      <b-form-group
                        label="Marcas"
                        label-for="where_did_working_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="where_did_working_perfil"
                            v-model="userEditing.profile.where_did_working"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingIaFromWhereDidWorking"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-warning">{{ hasAlertRegistration('where_did_working') }}</small>
                        </validation-provider>

                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="susi.where_did_working"></p>

                        <p v-if="userEditing.profile.raw && userEditing.profile.raw.where_did_working && typeof userEditing.profile.raw.where_did_working == 'object'">
                          {{ userEditing.profile.raw.where_did_working.join("\n") }}
                        </p>

                        <b-button
                          size="sm"
                          class="mt-1"
                          :disabled="isLoadingIaFromWhereDidWorking"
                          @click="getIaFromWhereDidWorking()"
                        >
                          Carregar
                          <Iconify
                            icon="carbon:ai-recommend"
                            width="20"
                            height="20"
                          />

                          <b-spinner
                            v-if="isLoadingIaFromWhereDidWorking"
                            small
                          />
                        </b-button>
                      </b-form-group>
                    </b-col>

                    <b-col
                      v-if="userEditing.type=='company'"
                      cols="3"
                    >
                      <b-form-group
                        label="Regiões onde busca representantes"
                        label-for="zones_where_reps_are_looking_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                        >
                          <b-form-textarea
                            id="zones_where_reps_are_looking_perfil"
                            v-model="userEditing.profile.zones_where_reps_are_looking"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row
                    v-if="userEditing.type=='representative'"
                    style="margin-top: 30px;"
                  >
                    <b-col cols="5">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label=""
                        label-for="company_constituted_perfil"
                      >
                        <div class="switches">
                          <b-form-checkbox
                            v-model="switches.company_constituted"
                            checked="false"
                            name="check-button"
                            switch
                            inline
                            @change="changedCompany"
                          >
                            Empresa constituída
                          </b-form-checkbox>
                        </div>

                        <validation-provider
                          #default="{ errors }"
                          :rules="switches.company_constituted ? 'required' : ''"
                        >
                          <b-form-input
                            id="company_constituted_perfil"
                            v-model="userEditing.profile.company_constituted"
                            class="switches-select"
                            :state="errors.length > 0 ? false:null"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.company_constituted"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="1" />

                    <b-col cols="5">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label=""
                        label-for="board_registration_core_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <div class="switches">
                            <b-form-checkbox
                              v-model="switches.board_registration_core"
                              checked="false"
                              name="check-button"
                              switch
                              inline
                              @change="changedCore"
                            >
                              CORE
                            </b-form-checkbox>
                          </div>

                          <b-input-group
                            class="switches-select"
                          >
                            <b-form-input
                              id="board_registration_core_perfil"
                              v-model="userEditing.profile.board_registration_core"
                              :state="errors.length > 0 ? false:null"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.board_registration_core"
                            />

                            <b-input-group-append>
                              <b-button
                                variant="outline-primary"
                                :disabled="isSubmiting"
                                @click="checkCoreProfile('core')"
                              >
                                <b-spinner
                                  v-if="isLoading"
                                  small
                                />
                                <feather-icon
                                  v-if="!isLoading"
                                  animation="spin-pulse"
                                  icon="SearchIcon"
                                />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>

                          <div
                            class="switches-select mt-1"
                          >
                            <b-button
                              size="sm"
                              variant="outline-primary"
                              class="mr-1"
                              :title="`Buscar pelo CPF ${userEditing.cpf ? userEditing.cpf : ''}`"
                              :disabled="!userEditing.cpf || userEditing.cpf.length === 0"
                              @click="checkCoreProfile('cpf')"
                            >
                              Buscar pelo CPF {{ userEditing.cpf ? userEditing.cpf : '' }}
                            </b-button>

                            <b-button
                              size="sm"
                              variant="outline-primary"
                              class="mr-1"
                              :title="`Buscar pelo CNPJ ${userEditing.cnpj ? userEditing.cnpj : ''}`"
                              :disabled="!userEditing.cnpj || userEditing.cnpj.length === 0"
                              @click="checkCoreProfile('cnpj')"
                            >
                              Buscar pelo CNPJ {{ userEditing.cnpj ? userEditing.cnpj : '' }}
                            </b-button>

                            <b-button
                              size="sm"
                              variant="outline-primary"
                              :title="`Buscar pelo Nome ${userEditing.name ? userEditing.name : ''}`"
                              :disabled="!userEditing.name || userEditing.name.length < 5"
                              @click="checkCoreProfile('name')"
                            >
                              Buscar pelo Nome {{ userEditing.name ? userEditing.name : '' }}
                            </b-button>
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="5">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label=""
                        label-for="representation_experience_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <div class="switches">
                            <b-form-checkbox
                              v-model="switches.representation_experience"
                              checked="false"
                              name="check-button"
                              switch
                              inline
                              @change="changedExperience"
                            >
                              Experiência com representação
                            </b-form-checkbox>
                          </div>

                          <b-form-select
                            id="representation_experience_perfil"
                            v-model="userEditing.profile.representation_experience"
                            class="switches-select"
                            :options="userRepresentationExperience"
                            :state="errors.length > 0 ? false:null"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.representation_experience"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="1" />

                    <b-col cols="5">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label=""
                        label-for="has_a_customer_portfolio_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <div class="switches">
                            <b-form-checkbox
                              id="has_a_customer_portfolio_perfil"
                              v-model="userEditing.profile.has_a_customer_portfolio"
                              checked="false"
                              name="check-button"
                              switch
                              inline
                            >
                              Carteira de clientes já formada
                            </b-form-checkbox>
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="5">
                      <b-form-group
                        v-if="userEditing.type=='representative'"
                        label=""
                        label-for="has_own_vehicle_perfil"
                      >
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <div class="switches">
                            <b-form-checkbox
                              id="has_own_vehicle_perfil"
                              v-model="userEditing.profile.has_own_vehicle"
                              checked="false"
                              name="check-button"
                              switch
                              inline
                            >
                              Veículo próprio
                            </b-form-checkbox>
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <user-check-core
                        ref="userCheckCoreProfile"
                        :user.sync="userEditing"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      class="text-right"
                    >
                      <b-button
                        align="right"
                        variant="primary"
                        type="submit"
                        :disabled="disableNonRootSaveForBlocked || isSubmiting"
                        @click.prevent="saveUserSearchProfile(false)"
                      >
                        Salvar
                        <b-spinner
                          v-if="isSubmiting"
                          small
                        />
                      </b-button>

                      <modal-planner-strategy-removal
                        ref="modal-planner-strategy-removal-search-profile"
                        @ok="saveUserSearchProfile(true)"
                      />
                    </b-col>
                  </b-row>
                </validation-observer>

              </b-card>
            </div>
          </b-tab>

          <!--        conversas -->
          <b-tab
            id="tab-conversas"
            title="Conversas"
            lazy
          >
            <template #title>
              <span class="pl-1">Conversas</span>
            </template>

            <user-list-conversations
              :limit-filters="true"
              :filters="{userId1: this.$route.params.id}"
            />

          </b-tab>

          <!--        favoritos -->
          <b-tab
            id="tab-favoritos"
            title="Favoritos"
          >
            <template #title>
              <span class="pl-1">Favoritos</span>
            </template>

            <user-list-favorites
              ref="userListFavorites"
              :user-id="parseInt($route.params.id, 10)"
            />
          </b-tab>

          <!--        favoritos -->
          <b-tab
            id="tab-plannerDemographics"
            title="Planejador"
            lazy
          >
            <template #title>
              <span class="pl-1">Planejador</span>
            </template>

            <planner-demographic
              ref="plannerDemographic"
              :user-id="parseInt($route.params.id, 10)"
            />
          </b-tab>

          <!--        revisão -->
          <b-tab
            v-if="(userBase.status === 'review' || userBase.status === 'filter') && userBase.deleted_at === null"
            id="tab-revisao"
            :title=" userBase.status === 'review' ? 'Revisão' : 'Filtragem' "
          >
            <template #title>
              <span class="pl-1">{{ userBase.status === 'review' ? 'Revisão' : 'Filtragem' }}</span>
            </template>

            <div
              v-if="isLoading"
              class="text-center"
            >
              <b-spinner />
              <p>Carregando...</p>
            </div>

            <alert-registration-warnings
              v-if="!isLoading && ['basic', 'filter', 'review'].includes(userBase.status)"
              :registration-warnings="userBase.settings.registration_warnings"
              :user-id="parseInt($route.params.id)"
              @updated="getUserBase()"
            />

            <div v-if="!isLoading">
              <b-card>
                <h4>Revisão</h4>
                <b-button
                  :disabled="isSubmiting"
                  :to="{name: 'cms-user-double-registration', id: $route.params.id}"
                  target="_blank"
                >
                  Ver duplos
                </b-button>
              </b-card>

              <b-form>
                <validation-observer ref="rulesPrimary">
                  <b-card ref="zonePrimary">
                    <h4>Informações Principais</h4>
                    <div id="primary_information">
                      <b-row>
                        <b-col
                          cols="12"
                          class="mb-1"
                        >
                          <avatar-input
                            ref="avatar"
                            :name-photo="userEditing.name"
                            :user-id="userEditing.id"
                            :method-to-save="userService.saveAvatar"
                            :method-to-rotate90="userService.rotateAvatar90"
                            :method-to-rotate270="userService.rotateAvatar270"
                            :method-to-remove="userService.removeAvatar"
                            :avatar-image="userEditing.avatar"
                            @preview="previewAvatar"
                          />
                        </b-col>
                        <b-col cols="4">
                          <b-form-group
                            label="Tipo"
                            label-for="type"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules="required"
                            >
                              <b-form-select
                                id="type"
                                ref="type"
                                v-model="userEditing.type"
                                :options="userTypes"
                                :state="errors.length > 0 ? false:null"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col
                          v-if="userEditing.type=='company'"
                          cols="4"
                        >
                          <b-form-group
                            label="Empresa"
                            label-for="company_name"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-form-input
                                id="company_name"
                                v-model="userEditing.profile.company_name"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="4">
                          <b-form-group
                            label="Responsável"
                            label-for="name"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-form-input
                                id="name"
                                v-model="userEditing.name"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="userEditing.type=='company'"
                          cols="4"
                        >
                          <b-form-group
                            label="Cargo"
                            label-for="position_in_company"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-form-input
                                id="position_in_company"
                                v-model="userEditing.profile.position_in_company"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="4">
                          <b-form-group
                            label="CNPJ"
                            label-for="registration_info_cnpj_review"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <b-input-group>
                                <cleave
                                  id="registration_info_cnpj_review"
                                  v-model="userEditing.cnpj"
                                  class="form-control"
                                  placeholder="00.000.000/0000-00"
                                  :options="maskCnpj"
                                  :disabled="disableNonQualificationSaveForBlocked || isSubmiting"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>

                                <b-input-group-append>
                                  <b-button
                                    variant="outline-primary"
                                    :disabled="isSubmiting"
                                    title="Validar CNPJ"
                                    @click="checkCnpjReview"
                                  >
                                    <b-spinner
                                      v-if="isLoading"
                                      small
                                    />
                                    <feather-icon
                                      v-if="!isLoading"
                                      animation="spin-pulse"
                                      icon="SearchIcon"
                                    />
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="4">
                          <b-form-group
                            label="CPF"
                            label-for="registration_info_cpf_review"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >
                              <cleave
                                id="registration_info_cpf_review"
                                v-model="userEditing.cpf"
                                :disabled="disableNonQualificationSaveForBlocked || isSubmiting"
                                class="form-control"
                                placeholder="000.000.000-00"
                                :options="maskCpf"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <user-check-cnpj
                            ref="userCheckCnpjReview"
                            :user.sync="userEditing"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="4">
                          <b-form-group
                            label="Estado"
                            label-for="state"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-form-select
                                id="state"
                                v-model="userEditing.address.state"
                                :options="userStates"
                                :state="errors.length > 0 ? false:null"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                                @change="selectState()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="4">
                          <b-form-group
                            label="Cidade"
                            label-for="city"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <v-select
                                id="city"
                                :value="userEditing.address.city"
                                label="title"
                                :options="cityOptions"
                                @input="selectCity"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="4">
                          <b-form-group
                            label="CEP"
                            label-for="zip_code"
                          >
                            <validation-provider
                              #default="{ errors }"
                              rules=""
                            >

                              <b-input-group>
                                <cleave
                                  id="zip_code"
                                  v-model="userEditing.address.zip_code"
                                  class="form-control"
                                  placeholder="CEP"
                                  :options="maskCep"
                                  :raw="false"
                                  :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                                />

                                <b-input-group-append>
                                  <b-button
                                    variant="outline-primary"
                                    :disabled="disableNonRootSaveForBlocked || isSubmiting || isLoadingCep"
                                    @click="getAddressByCep(userEditing.address.zip_code)"
                                  >
                                    <b-spinner
                                      v-if="isLoadingCep"
                                      small
                                    />
                                    <feather-icon
                                      v-if="!isLoadingCep"
                                      animation="spin-pulse"
                                      icon="SearchIcon"
                                    />
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="E-mail principal"
                            label-for="email"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-row>
                                <b-col cols="6">
                                  <b-input-group>
                                    <b-form-input
                                      id="email"
                                      v-model="userEditing.email"
                                      :disabled="disableNonRootSaveForBlocked || isSubmiting"
                                    />
                                  </b-input-group>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                  <small class="text-warning">{{ hasAlertRegistration('email') }}</small>
                                </b-col>

                                <b-col
                                  cols="6"
                                  class="d-flex align-items-center"
                                >
                                  <div class="d-flex flex-wrap flex-column">
                                    <b-button
                                      v-if="userEditing.email_master.verified_at === null"
                                      size="sm"
                                      :disabled="userEditing.email_master.isSubmitingConfirmation"
                                      @click="sendConfirmationEmail()"
                                    >
                                      <b-spinner
                                        v-if="userEditing.email_master.isSubmitingConfirmation"
                                        small
                                      />
                                      <feather-icon
                                        v-else
                                        animation="spin-pulse"
                                        icon="PlusSquareIcon"
                                      />
                                      Enviar e-mail de confirmação
                                    </b-button>
                                    <small v-if="userEditing.email_master.verified_at === null && userEditing.email_master.verification_sent_at">Enviado em: {{ userEditing.email_master.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
                                  </div>

                                  <b-button
                                    v-if="userEditing.email_master.verified_at === null"
                                    size="sm"
                                    variant="outline-secondary"
                                    class="ml-1"
                                    :disabled="userEditing.email_master.isSubmiting"
                                    @click="confirmEmail()"
                                  >
                                    <b-spinner
                                      v-if="userEditing.email_master.isSubmiting"
                                      small
                                    />
                                    <feather-icon
                                      v-else
                                      animation="spin-pulse"
                                      icon="CheckIcon"
                                    />
                                    Confirmar manualmente
                                  </b-button>

                                  <span
                                    v-if="userEditing.email_master.conferred_at !== null"
                                    class="ml-1 text-success"
                                  ><feather-icon
                                    animation="spin-pulse"
                                    icon="CheckIcon"
                                  /> Conferido em {{ userEditing.email_master.conferred_at | date('DD/MM/Y HH:mm:ss') }} {{ userEditing.name }}</span>
                                  <span
                                    v-else-if="userEditing.email_master.verified_at !== null"
                                    class="ml-1 text-success"
                                  ><feather-icon
                                    animation="spin-pulse"
                                    icon="CheckIcon"
                                  /> Confirmado em {{ userEditing.email_master.verified_at | date('DD/MM/Y HH:mm:ss') }} {{ confirmedBy(userEditing.email_master) }}</span>

                                </b-col>
                              </b-row>

                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          :class="{'pointer-events-none': disableNonRootSaveForBlocked}"
                        >
                          <user-edit-emails
                            id="registration_info_emails"
                            :user-name="userEditing.name"
                            :emails.sync="userEditing.list_emails"
                            :is-submiting="isSubmiting"
                            @setMasterEmail="setMasterEmail"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12">
                          <b-form-group
                            label="Celular/WhatsApp"
                            label-for="cell_phone"
                          >
                            <b-row>
                              <b-col>
                                <b-input-group>
                                  <cleave
                                    id="cell_phone"
                                    v-model="userEditing.cell_phone"
                                    class="form-control"
                                    placeholder="DDD + Número"
                                    :options="userEditing.cell_phone.match(/[0-9]*/g).join('').length == 10 ? maskPhone8 : maskPhone9"
                                    :disabled="disableNonRootSaveForBlocked || isSubmiting"
                                  />
                                </b-input-group>
                                <b-input-group-append>
                                  <b-button
                                    v-b-tooltip.hover.noninteractive="'Iniciar ligação com VOIP'"
                                    variant="outline-primary"
                                    :href="`callto:0${userEditing.cell_phone.match(/[0-9]*/g).join('')}`"
                                  >
                                    <feather-icon icon="PhoneCallIcon" />
                                  </b-button>
                                </b-input-group-append>
                              </b-col>
                              <b-col
                                v-if="userEditing.cell_phone !== userEditing.phone_master.old"
                                cols="8"
                              >
                                <span>Salve os dados antes de enviar o e-mail de confirmação</span>
                              </b-col>

                              <b-col
                                v-else
                                cols="8"
                                class="d-flex align-items-center"
                              >
                                <div class="d-flex flex-wrap flex-column">
                                  <b-button
                                    v-if="userEditing.phone_master.verified_at === null"
                                    size="sm"
                                    :disabled="userEditing.phone_master.isSubmitingConfirmation"
                                    @click="sendConfirmationCellPone()"
                                  >
                                    <b-spinner
                                      v-if="userEditing.phone_master.isSubmitingConfirmation"
                                      small
                                    />
                                    <feather-icon
                                      v-else
                                      animation="spin-pulse"
                                      icon="PlusSquareIcon"
                                    />
                                    Enviar WhatsApp de confirmação
                                  </b-button>
                                  <small v-if="userEditing.phone_master.verified_at === null && userEditing.phone_master.verification_sent_at">Enviado em: {{ userEditing.phone_master.verification_sent_at | date('DD/MM/Y HH:mm:ss') }}</small>
                                </div>

                                <b-button
                                  v-if="userEditing.phone_master.verified_at === null"
                                  size="sm"
                                  variant="outline-secondary"
                                  class="ml-1"
                                  :disabled="userEditing.phone_master.isSubmiting"
                                  @click="confirmCellPhone()"
                                >
                                  <b-spinner
                                    v-if="userEditing.phone_master.isSubmiting"
                                    small
                                  />
                                  <feather-icon
                                    v-else
                                    animation="spin-pulse"
                                    icon="CheckIcon"
                                  />
                                  Confirmar manualmente
                                </b-button>

                                <span
                                  v-if="userEditing.phone_master.conferred_at !== null"
                                  class="ml-1 text-success"
                                ><feather-icon
                                  animation="spin-pulse"
                                  icon="CheckIcon"
                                /> Conferido em {{ userEditing.phone_master.conferred_at | date('DD/MM/Y HH:mm:ss') }} por {{ userEditing.name }}</span>
                                <span
                                  v-else-if="userEditing.phone_master.verified_at !== null"
                                  class="ml-1 text-success"
                                ><feather-icon
                                  animation="spin-pulse"
                                  icon="CheckIcon"
                                /> Confirmado em {{ userEditing.phone_master.verified_at | date('DD/MM/Y HH:mm:ss') }} por {{ confirmedBy(userEditing.phone_master) }}</span>

                              </b-col>
                            </b-row>

                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          :class="{'pointer-events-none': disableNonRootSaveForBlocked}"
                        >
                          <user-edit-phones
                            id="phones"
                            :user-name="userEditing.name"
                            :phones.sync="userEditing.list_phones"
                            :is-submiting="isSubmiting"
                            @setMasterPhone="setMasterPhone"
                          />
                        </b-col>

                        <b-col
                          cols="12"
                          class="text-right"
                        >
                          <b-button
                            align="right"
                            variant="primary"
                            type="submit"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            @click.prevent="saveUserReview('primary')"
                          >
                            Salvar
                            <b-spinner
                              v-if="isSubmiting"
                              small
                            />
                          </b-button>
                        </b-col>

                      </b-row>
                    </div>
                  </b-card>
                </validation-observer>

                <validation-observer ref="rulesSocials">
                  <b-card ref="socials">
                    <h4>Sites e Redes Sociais</h4>
                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Site"
                          label-for="site"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'min:5'"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="LinkIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="site"
                                v-model="userEditing.profile.link_site"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning d-block">{{ hasAlertRegistration('link_site') }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          label="Facebook"
                          label-for="facebook"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'min:5'"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="FacebookIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="facebook"
                                v-model="userEditing.profile.link_facebook"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning d-block">{{ hasAlertRegistration('link_facebook') }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Instagram"
                          label-for="instagram"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'min:5'"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="InstagramIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="instagram"
                                v-model="userEditing.profile.link_instagram"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning d-block">{{ hasAlertRegistration('link_instagram') }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="4">
                        <b-form-group
                          label="Linkedin"
                          label-for="linkedin"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'min:5'"
                          >
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                <feather-icon icon="LinkedinIcon" />
                              </b-input-group-prepend>
                              <b-form-input
                                id="linkedin"
                                v-model="userEditing.profile.link_linkedin"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                            </b-input-group>

                            <small class="text-danger">{{ errors[0] }}</small>
                            <small class="text-warning d-block">{{ hasAlertRegistration('link_linkedin') }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-right"
                      >
                        <b-button
                          align="right"
                          variant="primary"
                          type="submit"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click.prevent="saveUserReview('socials')"
                        >
                          Salvar
                          <b-spinner
                            v-if="isSubmiting"
                            small
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </validation-observer>

                <validation-observer ref="rulesCommercial">
                  <b-card ref="zoneCommercial">
                    <h4>Informações Comerciais</h4>
                    <div id="comercial_information">
                      <b-row>
                        <b-col cols="3">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label="Produtos que deseja representar"
                            label-for="products_want_to_represent"
                          >
                            <b-form-textarea
                              id="products_want_to_represent"
                              v-model="userEditing.profile.products_want_to_represent"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-warning d-block">{{ hasAlertRegistration('products_want_to_represent') }}</small>

                            <!-- eslint-disable vue/no-v-html -->
                            <p v-html="susi.products_want_to_represent"></p>

                            <p v-if="userEditing.profile.raw && userEditing.profile.raw.products_want_to_represent && typeof userEditing.profile.raw.products_want_to_represent == 'object'">
                              {{ userEditing.profile.raw.products_want_to_represent.join("\n") }}
                            </p>

                            <b-button
                              size="sm"
                              class="mt-1"
                              :disabled="isLoadingIaProductsWantToRepresent"
                              @click="getIaFromProductsWantToRepresent()"
                            >
                              Carregar
                              <Iconify
                                icon="carbon:ai-recommend"
                                width="20"
                                height="20"
                              />

                              <b-spinner
                                v-if="isLoadingIaProductsWantToRepresent"
                                small
                              />
                            </b-button>
                          </b-form-group>

                          <b-form-group
                            v-if="userEditing.type=='company'"
                            label="Produtos da empresa"
                            label-for="products"
                          >
                            <b-form-textarea
                              id="products"
                              v-model="userEditing.profile.products"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="3">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label="Produtos que já representou"
                            label-for="represented_products"
                          >
                            <b-form-textarea
                              id="represented_products"
                              v-model="userEditing.profile.represented_products"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-warning d-block">{{ hasAlertRegistration('represented_products') }}</small>

                            <!-- eslint-disable vue/no-v-html -->
                            <p v-html="susi.represented_products"></p>

                            <p v-if="userEditing.profile.raw && userEditing.profile.raw.represented_products && typeof userEditing.profile.raw.represented_products == 'object'">
                              {{ userEditing.profile.raw.represented_products.join("\n") }}
                            </p>

                            <b-button
                              size="sm"
                              class="mt-1"
                              :disabled="isLoadingIaRepresentedProducts"
                              @click="getIaFromRepresentedProducts()"
                            >
                              Carregar
                              <Iconify
                                icon="carbon:ai-recommend"
                                width="20"
                                height="20"
                              />

                              <b-spinner
                                v-if="isLoadingIaRepresentedProducts"
                                small
                              />
                            </b-button>
                          </b-form-group>

                          <b-form-group
                            v-if="userEditing.type=='company'"
                            label="Marcas da empresa"
                            label-for="company_brands"
                          >
                            <b-form-textarea
                              id="company_brands"
                              v-model="userEditing.profile.company_brands"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="3">
                          <b-form-group
                            :label="userEditing.type=='representative' ? 'Clientes' : 'Principais clientes ativos'"
                            label-for="clients"
                          >
                            <b-form-textarea
                              id="main_customers"
                              v-model="userEditing.profile.main_customers"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-warning d-block">{{ hasAlertRegistration('main_customers') }}</small>

                            <!-- eslint-disable vue/no-v-html -->
                            <p v-html="susi.main_customers"></p>

                            <p v-if="userEditing.profile.raw && userEditing.profile.raw.main_customers && typeof userEditing.profile.raw.main_customers == 'object'">
                              {{ userEditing.profile.raw.main_customers.join("\n") }}
                            </p>

                            <b-button
                              size="sm"
                              class="mt-1"
                              :disabled="isLoadingIaMainCustomers"
                              @click="getIaFromMainCustomers()"
                            >
                              Carregar
                              <Iconify
                                icon="carbon:ai-recommend"
                                width="20"
                                height="20"
                              />

                              <b-spinner
                                v-if="isLoadingIaMainCustomers"
                                small
                              />
                            </b-button>
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="userEditing.type=='representative'"
                          cols="3"
                        >
                          <b-form-group
                            label="Marcas"
                            label-for="brands"
                          >
                            <b-form-textarea
                              id="where_did_working"
                              v-model="userEditing.profile.where_did_working"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-warning d-block">{{ hasAlertRegistration('where_did_working') }}</small>

                            <!-- eslint-disable vue/no-v-html -->
                            <p v-html="susi.where_did_working"></p>

                            <p v-if="userEditing.profile.raw && userEditing.profile.raw.where_did_working && typeof userEditing.profile.raw.where_did_working == 'object'">
                              {{ userEditing.profile.raw.where_did_working.join("\n") }}
                            </p>

                            <b-button
                              size="sm"
                              class="mt-1"
                              :disabled="isLoadingIaFromWhereDidWorking"
                              @click="getIaFromWhereDidWorking()"
                            >
                              Carregar
                              <Iconify
                                icon="carbon:ai-recommend"
                                width="20"
                                height="20"
                              />

                              <b-spinner
                                v-if="isLoadingIaFromWhereDidWorking"
                                small
                              />
                            </b-button>
                          </b-form-group>
                        </b-col>

                        <b-col
                          v-if="userEditing.type=='company'"
                          cols="3"
                        >
                          <b-form-group
                            label="Regiões onde busca representantes"
                            label-for="zones_where_reps_are_looking"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <b-form-textarea
                                id="zones_where_reps_are_looking"
                                v-model="userEditing.profile.zones_where_reps_are_looking"
                                rows="10"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row
                        v-if="userEditing.type=='representative'"
                        style="margin-top: 30px;"
                      >
                        <b-col cols="5">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label=""
                            label-for="company_constituted"
                          >
                            <div class="switches">
                              <b-form-checkbox
                                v-model="switches.company_constituted"
                                checked="false"
                                name="check-button"
                                switch
                                inline
                                @change="changedCompany"
                              >
                                Empresa constituída
                              </b-form-checkbox>
                            </div>

                            <validation-provider
                              #default="{ errors }"
                              :rules="switches.company_constituted ? 'required' : ''"
                            >
                              <b-form-input
                                id="company_constituted"
                                v-model="userEditing.profile.company_constituted"
                                class="switches-select"
                                :state="errors.length > 0 ? false:null"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.company_constituted"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="1" />

                        <b-col cols="5">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label=""
                            label-for="board_registration_core"
                          >
                            <div class="switches">
                              <b-form-checkbox
                                v-model="switches.board_registration_core"
                                checked="false"
                                name="check-button"
                                switch
                                inline
                                @change="changedCore"
                              >
                                CORE
                              </b-form-checkbox>
                            </div>

                            <validation-provider
                              #default="{ errors }"
                              :rules="switches.board_registration_core ? 'required' : ''"
                            >
                              <b-input-group
                                class="switches-select"
                              >
                                <b-form-input
                                  id="board_registration_core"
                                  v-model="userEditing.profile.board_registration_core"
                                  :state="errors.length > 0 ? false:null"
                                  :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.board_registration_core"
                                />

                                <b-input-group-append>
                                  <b-button
                                    variant="outline-primary"
                                    :disabled="isSubmiting"
                                    title="Validar existência do Core"
                                    @click="checkCoreReview('core')"
                                  >
                                    <b-spinner
                                      v-if="isLoading"
                                      small
                                    />
                                    <feather-icon
                                      v-if="!isLoading"
                                      animation="spin-pulse"
                                      icon="SearchIcon"
                                    />
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>

                              <div
                                class="switches-select mt-1"
                              >
                                <b-button
                                  size="sm"
                                  variant="outline-primary"
                                  class="mr-1"
                                  :title="`Buscar pelo CPF ${userEditing.cpf ? userEditing.cpf : ''}`"
                                  :disabled="!userEditing.cpf || userEditing.cpf.length === 0"
                                  @click="checkCoreReview('cpf')"
                                >
                                  Buscar pelo CPF {{ userEditing.cpf ? userEditing.cpf : '' }}
                                </b-button>

                                <b-button
                                  size="sm"
                                  variant="outline-primary"
                                  class="mr-1"
                                  :title="`Buscar pelo CNPJ ${userEditing.cnpj ? userEditing.cnpj : ''}`"
                                  :disabled="!userEditing.cnpj || userEditing.cnpj.length === 0"
                                  @click="checkCoreReview('cnpj')"
                                >
                                  Buscar pelo CNPJ {{ userEditing.cnpj ? userEditing.cnpj : '' }}
                                </b-button>

                                <b-button
                                  size="sm"
                                  variant="outline-primary"
                                  :title="`Buscar pelo Nome ${userEditing.name ? userEditing.name : ''}`"
                                  :disabled="!userEditing.name || userEditing.name.length < 5"
                                  @click="checkCoreReview('name')"
                                >
                                  Buscar pelo Nome {{ userEditing.name ? userEditing.name : '' }}
                                </b-button>
                              </div>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="5">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label=""
                            label-for="representation_experience"
                          >
                            <div class="switches">
                              <b-form-checkbox
                                v-model="switches.representation_experience"
                                checked="false"
                                name="check-button"
                                switch
                                inline
                                @change="changedExperience"
                              >
                                Experiência com representação
                              </b-form-checkbox>
                            </div>

                            <validation-provider
                              #default="{ errors }"
                              :rules="switches.representation_experience ? 'required' : ''"
                            >
                              <b-form-select
                                id="representation_experience"
                                v-model="userEditing.profile.representation_experience"
                                class="switches-select"
                                :options="userRepresentationExperience"
                                :state="errors.length > 0 ? false:null"
                                :disabled="disableNonRootSaveForBlocked || isSubmiting || !switches.representation_experience"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="1" />

                        <b-col cols="5">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label=""
                            label-for="has_a_customer_portfolio"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <div class="switches">
                                <b-form-checkbox
                                  v-model="userEditing.profile.has_a_customer_portfolio"
                                  checked="false"
                                  name="check-button"
                                  switch
                                  inline
                                >
                                  Carteira de clientes já formada
                                </b-form-checkbox>
                              </div>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="5">
                          <b-form-group
                            v-if="userEditing.type=='representative'"
                            label=""
                            label-for="has_own_vehicle"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :rules="userBase.status === 'filter' ? '' : 'required'"
                            >
                              <div class="switches">
                                <b-form-checkbox
                                  v-model="userEditing.profile.has_own_vehicle"
                                  checked="false"
                                  name="check-button"
                                  switch
                                  inline
                                >
                                  Veículo próprio
                                </b-form-checkbox>
                              </div>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <user-check-core
                            ref="userCheckCoreReview"
                            :user.sync="userEditing"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col
                          cols="12"
                          class="text-right"
                        >
                          <b-button
                            align="right"
                            variant="primary"
                            type="submit"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            @click.prevent="saveUserReview('commercial')"
                          >
                            Salvar
                            <b-spinner
                              v-if="isSubmiting"
                              small
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card>
                </validation-observer>

                <validation-observer ref="rulesZones">
                  <b-card ref="zoneZones">
                    <h4>Região e Segmento</h4>
                    <div id="zones">
                      <div class="text-button">
                        <p><span>Regiões</span> <b-button
                          size="sm"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click="loadZones($refs.userEditZones)"
                        >
                          CARREGAR
                        </b-button></p>
                        <small class="text-danger">{{ errorsZones }}</small>
                      </div>

                      <user-edit-zones
                        ref="userEditZones"
                        :preferred-zones="userEditing.profile.preferred_zones"
                        :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                        @changed="changedPreferredZones"
                      />
                    </div>

                    <div style="height: 30px" />
                    <div id="segments">
                      <div class="text-button">
                        <p>
                          <span>Segmentos</span>
                          <b-button
                            size="sm"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            @click="loadSegments($refs.userEditSegments)"
                          >
                            CARREGAR
                          </b-button></p>
                        <small class="text-danger">{{ errorsSegments }}</small>
                      </div>

                      <user-edit-segments
                        ref="userEditSegments"
                        :preferred-segments="userEditing.profile.preferred_segments"
                        :is-submiting="disableNonRootSaveForBlocked || isSubmiting"
                        :user-type="userEditing.type"
                        @changed="changedPreferredSegments"
                      />
                    </div>

                    <b-row>
                      <b-col
                        cols="12"
                        class="text-right"
                      >
                        <b-button
                          align="right"
                          variant="primary"
                          type="submit"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click.prevent="saveUserReview('zones')"
                        >
                          Salvar
                          <b-spinner
                            v-if="isSubmiting"
                            small
                          />
                        </b-button>

                        <modal-planner-strategy-removal
                          ref="modal-planner-strategy-removal-user-review-zones"
                          @ok="saveUserReview('zones', true)"
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </validation-observer>

                <validation-observer ref="rulesAdditions">
                  <b-card ref="additions">
                    <h4>Adicionais</h4>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Apresentação"
                          label-for="presentation"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'required'"
                          >
                            <b-form-textarea
                              id="presentation"
                              v-model="userEditing.profile.presentation"
                              rows="10"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>

                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="susi.presentation"></p>

                        <p v-if="userEditing.profile.raw && userEditing.profile.raw.presentation">
                          {{ userEditing.profile.raw.presentation }}
                        </p>

                        <b-button
                          size="sm"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click="loadPresentationTemplate()"
                        >
                          Usar modelo
                        </b-button>

                        <b-button
                          size="sm"
                          class="ml-1"
                          :disabled="isLoadingIaPresentation"
                          @click="getIaFromPresentation()"
                        >
                          Carregar
                          <Iconify
                            icon="carbon:ai-recommend"
                            width="14"
                            height="14"
                          />

                          <b-spinner
                            v-if="isLoadingIaPresentation"
                            small
                          />
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row style="margin-top: 20px">
                      <b-col cols="4">
                        <div class="text-button">
                          <p><span>Palavras-chave</span> <b-button
                            size="sm"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            @click.prevent="loadTags()"
                          >
                            CARREGAR
                          </b-button></p>
                        </div>

                        <validation-provider
                          #default="{ errors }"
                          rules=""
                        >
                          <b-form-textarea
                            id="tags"
                            v-model="userEditing.tags"
                            rows="10"
                            :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-right"
                      >
                        <b-button
                          align="right"
                          variant="primary"
                          type="submit"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click.prevent="saveUserReview('additions')"
                        >
                          Salvar
                          <b-spinner
                            v-if="isSubmiting"
                            small
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card>
                </validation-observer>

                <validation-observer ref="rulesApproval">
                  <b-card>
                    <h4>Aprovação</h4>
                    <b-row>
                      <b-col cols="4">
                        <b-form-group
                          label="Status"
                          label-for="status"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userBase.status === 'filter' ? '' : 'required'"
                          >
                            <b-form-select
                              id="status"
                              v-model="userEditing.status"
                              :options="userStatus"
                              :state="errors.length > 0 ? false:null"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="userEditing.status === 'blocked'"
                        cols="4"
                      >
                        <b-form-group
                          label="Motivo do bloqueio"
                          label-for="block-reason"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userEditing.status === 'blocked' ? 'required' : ''"
                          >
                            <b-form-select
                              id="block-reason"
                              v-model="userEditing.status_reason"
                              :options="userBlockReasons"
                              :state="errors.length > 0 ? false:null"
                              :disabled="disableNonRootSaveForBlocked || isSubmiting"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="userEditing.status === 'blocked' && userEditing.status_reason === 'double'"
                        cols="4"
                      >
                        <b-form-group
                          label="Id. do usuário original"
                          label-for="double-user-id"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :rules="userEditing.status === 'blocked' && userEditing.status_reason === 'double' ? `required|integer|is-not:${this.$route.params.id}` : ''"
                          >
                            <b-form-input
                              id="double-user-id"
                              v-model="userEditing.settings.double_user_id"
                              :disabled="userBase.status !== 'filter' && (disableNonRootSaveForBlocked || isSubmiting)"
                              :plaintext="userBase.status !== 'filter'"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        cols="12"
                        class="text-right"
                      >
                        <b-button
                          align="right"
                          variant="primary"
                          type="submit"
                          :disabled="disableNonRootSaveForBlocked || isSubmiting"
                          @click.prevent="saveUserReview('all')"
                        >
                          Salvar Tudo
                          <b-spinner
                            v-if="isSubmiting"
                            small
                          />
                          <span v-if="submittingTotal > 0">
                            {{ ((submittingProgressed / submittingTotal) * 100).toFixed(0).padStart(2, ' ') }}%
                          </span>
                        </b-button>

                        <modal-planner-strategy-removal
                          ref="modal-planner-strategy-removal-user-review-all"
                          @ok="saveUserReview('all', true)"
                        />
                      </b-col>
                    </b-row>
                  </b-card>
                </validation-observer>
              </b-form>
            </div>
          </b-tab>

          <!-- financeiro -->
          <b-tab
            id="tab-financeiro"
            title="Financeiro"
            lazy
          >
            <user-list-financial
              ref="userListFinancial"
              :filter-by-user="parseInt(this.$route.params.id)"
              :filter-by-id.sync="transactionId"
            />
          </b-tab>

          <!-- e-mail -->
          <b-tab
            id="tab-email"
            title="E-mail"
          >
            <user-email-tool
              ref="userEmailTool"
              :template-type="templateType"
              :user="userEditing"
              :user-id="parseInt(this.$route.params.id)"
            />
          </b-tab>

          <!-- dados fiscais -->
          <b-tab
            id="tab-dados-fiscais"
            title="Dados fiscais"
            lazy
          >
            <user-list-tax-data
              ref="userListTaxData"
              :user="userEditing"
              :user-id="parseInt(this.$route.params.id)"
            />
          </b-tab>

          <!-- planos -->
          <b-tab
            id="tab-planos"
            title="Planos"
          >
            <user-list-quotas
              ref="userListQuotas"
              :user-id="parseInt(this.$route.params.id, 10)"
              @open-transaction="openTransaction"
            />

          </b-tab>

          <!-- ferramentas de customer success -->
          <user-modules-success
            v-if="showSuccessTools"
          />

          <!-- ferramentas CRM -->
          <user-modules-crm
            :user-id="parseInt(this.$route.params.id, 10)"
          />

          <!-- configurações -->
          <b-tab
            id="tab-configuracoes"
            title="Configurações"
          >
            <user-list-settings
              ref="userListSettings"
              :user-id="parseInt(this.$route.params.id, 10)"
              :user-editing="userEditing"
              @sort-success-manager="sortSuccessManager"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>

    <user-review-warning-modal ref="user-review-warning-modal" />
  </section>
</template>

<script>
// import { FormWizard, TabContent } from 'vue-form-wizard'
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import flatPickr from 'vue-flatpickr-component'
import CarbonAiRecommend from '@/libs/iconify/CarbonAiRecommend.vue'
import MarkerCheckIcon from '@iconify/icons-mdi/marker-check'
import Cleave from 'vue-cleave-component'
import {
  required,
  isNot,
  email,
} from '@validations'
import {
  userTypes,
  userStatus,
  userTest,
  userStates,
  userRepresentationExperience,
  userBlockReasons,
} from '@/utils/options'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  maskCep, maskPhone8, maskPhone9, maskCnpj, maskCpf,
} from '@/utils/masks'

import { getInitials, formatDateTimeDbToView } from '@/utils/helpers'
import vSelect from 'vue-select'
import {
  BForm,
  BAlert,
  BFormGroup,
  BSpinner,
  BInputGroupAppend,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BAvatar,
  BTabs,
  BTab,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BBreadcrumb,
  BRow,
  BCol,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import requestErrorMessage from '@/mixins/requestErrorMessage'
import sendToClipboard from '@/mixins/sendToClipboard'
import toast from '@/mixins/toast'
import UserService from '@/services/userService'
import SusiService from '@/services/susiService'
import KanbanService from '@/services/kanbanService'
import ZoneService from '@/services/zoneService'
import PresentationTemplateService from '@/services/presentationTemplateService'
import SegmentService from '@/services/segmentService'
import UserReviewWarningModal from '@/views/cms/components/UserReviewWarningModal.vue'
import HealthScore from '@/views/cms/health-score/HealthScore.vue'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import CommerciaService from '@/services/commerciaService'
import UserCheckCore from '@/views/cms/components/UserCheckCore.vue'
import UserCheckCnpj from '@/views/cms/components/UserCheckCnpj.vue'
import AlertRegistrationWarnings from '@/views/cms/user/AlertRegistrationWarnings.vue'
import ModalPlannerStrategyRemoval from './ModalPlannerStrategyRemoval.vue'
import UserEditZones from '../components/UserEditZones.vue'
import UserEditSegments from '../components/UserEditSegments.vue'
import UserEditPhones from '../components/UserEditPhones.vue'
import UserEditEmails from '../components/UserEditEmails.vue'
import UserExtractedPhones from '../components/UserExtractedPhones.vue'
import UserListConversations from '../components/UserListConversations.vue'
import UserListFinancial from '../components/UserListFinancial.vue'
import UserListTaxData from '../components/UserListTaxData.vue'
import UserListQuotas from '../components/UserListQuotas.vue'
import UserListAnnotation from '../components/UserListAnnotation.vue'
import UserListActivities from '../components/UserListActivities.vue'
import UserListSettings from '../components/UserListSettings.vue'
import UserListDemographic from '../components/UserListDemographic.vue'
import UserListFavorites from '../components/UserListFavorites.vue'
import UserEmailTool from '../components/UserEmailTool.vue'
import AvatarInput from '../components/AvatarInput.vue'
import FileInput from '../components/FileInput.vue'
import PlannerDemographic from '../planner/PlannerDemographic.vue'

import UserModulesSuccess from './modules/success/UserModulesSuccess.vue'
import UserModulesCrm from './modules/crm/UserModulesCrm.vue'
import MoveCard from '../kanban/MoveCard.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AlertRegistrationWarnings,
    UserCheckCore,
    UserCheckCnpj,
    ModalPlannerStrategyRemoval,
    HealthScore,
    MoveCard,
    UserReviewWarningModal,
    UserModulesSuccess,
    UserModulesCrm,
    UserListFavorites,
    UserListConversations,
    UserListFinancial,
    UserListQuotas,
    UserListSettings,
    UserListDemographic,
    UserEmailTool,
    Cleave,
    AvatarInput,
    FileInput,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBreadcrumb,
    BButton,
    BTab,
    BTabs,
    BAvatar,
    BForm,
    BAlert,
    BFormGroup,
    BFormCheckbox,
    BInputGroupAppend,
    BSpinner,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    BCol,
    BTable,
    flatPickr,
    UserEditZones,
    UserEditSegments,
    UserEditPhones,
    UserEditEmails,
    UserExtractedPhones,
    UserListTaxData,
    UserListAnnotation,
    UserListActivities,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PlannerDemographic,
  },
  mixins: [requestErrorMessage, sendToClipboard, toast],
  data() {
    return {
      CarbonAiRecommend,
      console: window.console,
      isLoading: true,
      isLoadingSuccessManager: false,
      isLoadingCep: false,
      isLoadingStatistics: true,
      isLoadingWhatsapp: false,
      isLoadingEmail: false,
      isLoadingCard: true,
      isSubmiting: false,
      submittingProgressed: 0,
      submittingTotal: 0,
      isSubmitingDirectAccess: false,
      isSubmitingPasswordRecovery: false,
      isSubmitingMigrateLogin: false,
      isUploading: false,
      file: null,
      showStatisticsCustomPeriod: false,
      isChangeType: false,
      zoneService: null,
      cardData: null,
      presentationTemplateService: null,
      userService: null,
      kanbanService: null,
      transactionId: null,
      rangeDate: '',
      templateType: null,
      isLoadingIaPresentation: false,
      isLoadingIaProductsWantToRepresent: false,
      isLoadingIaRepresentedProducts: false,
      isLoadingIaMainCustomers: false,
      isLoadingIaFromWhereDidWorking: false,
      isLoadingRemoveWhatsappCampaign: false,
      isLoadingWhatsappCampaigns: false,
      whatsappCampaigns: [],
      susi: {
        products_want_to_represent: '',
        represented_products: '',
        main_customers: '',
        where_did_working: '',
        presentation: '',
      },
      icons: {
        MarkerCheckIcon,
      },
      pickrConfig: {
        mode: 'range',
        allowInput: false,
        position: 'auto center',
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Portuguese,
      },
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Usuários',
          to: { name: 'cms-user' },
        },
        {
          text: 'Usuário',
          active: true,
        },
      ],

      limits: {
        company: {
          max: {
            segments: 999,
            states: 999,
          },
          min: {
            segments: 1,
            states: 1,
          },
        },
        representative: {
          max: {
            segments: 999,
            states: 3,
          },
          min: {
            segments: 1,
            states: 1,
          },
        },
      },

      errorsSegments: '',
      errorsZones: '',
      errorsSegmentsPerfil: '',
      errorsZonesPerfil: '',

      zones: [], // zonas que são carregadas em cache
      segments: [], // segmentos que são carregados em cache
      presentationTemplate: [], // modelos de apresentação carregados em cache

      switches: {
        company_constituted: false,
        board_registration_core: false,
        representation_experience: false,
      },

      userCommercia: {},
      userBase: {},
      userEditing: {
        type: '',
        name: '',
        cpf: '',
        cnpj: '',
        company: '',
        cell_phone: '',
        phones: [],
        phones_cms: [],
        email: '',
        emails: [],
        emails_cms: [],
        list_emails: [],
        email_master: {},
        phone_master: {},
        password: '',
        status_reason: '',
        address: {
          cep: '',
          zip_code: '',
          state: '',
          city: '',
          neighbor: '',
          street: '',
          number: '',
          complement: '',
        },
        settings: {
          success_manager: {
            name: '',
            link_schedule_call: '',
          },
        },
        profile: {
          products_want_to_represent: '',
          represented_products: '',
          where_did_working: '',
          main_customers: '',
          products: '',
          company_brands: '',
          company_name: '',
          position_in_company: '',
          preferred_zones: [],
          preferred_segments: [],
          representation_experience: '',
        },
      },

      statistic_items: {
        by_date: [],
        static: [],
      },

      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },

      firstTab: false,
      openedProfileTab: false,
      openedDashboardTab: false,
      activeTab: null,
      // usado para redirecionar para uma detarminada tab após salvar os dados do usuário
      changeToTab: null,

      required,
      isNot,
      email,
      userTypes,
      userStatus,
      userTest,
      userBlockReasons,
      userStates,
      getInitials,
      userRepresentationExperience,
      userData: null,
      maskPhone8,
      maskPhone9,
      maskCep,
      maskCnpj,
      maskCpf,
      formatDateTimeDbToView,
    }
  },
  computed: {
    outdatedMessage() {
      if (!this.userBase) {
        return null
      }

      const outdated = []
      const hundredDaysAgo = moment().subtract(100, 'days')

      const emailDates = [moment(this.userBase.email_master.conferred_at), moment(this.userBase.email_master.verified_at)].filter(f => f.isValid()).sort((a, b) => b.diff(a))
      if (emailDates.length === 0 || emailDates[0].isBefore(hundredDaysAgo)) {
        outdated.push('email')
      }

      const phoneDates = [moment(this.userBase.phone_master.conferred_at), moment(this.userBase.phone_master.verified_at)].filter(f => f.isValid()).sort((a, b) => b.diff(a))
      if (phoneDates.length === 0 || phoneDates[0].isBefore(hundredDaysAgo)) {
        outdated.push('telefone')
      }

      const variant = [
        'success',
        'warning',
        'danger',
      ][outdated.length]

      const icon = [
        'CheckCircleIcon',
        'AlertTriangleIcon',
        'XCircleIcon',
      ][outdated.length]

      let text = 'Telefone e email confirmados nos últimos 100 dias'
      if (outdated.length === 1) {
        const fieldOutdated = `${outdated[0][0].toUpperCase()}${outdated[0].slice(1)}`
        text = `${fieldOutdated} não confirmado nos últimos 100 dias`
      } else if (outdated.length === 2) {
        const fieldsOutdated = `${outdated[0][0].toUpperCase()}${outdated[0].slice(1)} e ${outdated[1]}`
        text = `${fieldsOutdated} não confirmados nos últimos 100 dias`
      }

      return {
        variant,
        text,
        icon,
      }
    },
    disableNonRootSaveForBlocked() {
      return (
        this.userBase
                && this.userBase.status === 'blocked'
                && this.userBase.status_reason === 'double'

                && this.userData
                && (
                  this.userData.permission_type !== 'root'
                        && this.userData.permission_type !== 'management_processes'
                )
      )
    },
    disableNonQualificationSaveForBlocked() {
      return this.disableNonRootSaveForBlocked && this.userData.permission_type !== 'service_qualification'
    },
    showSuccessTools() {
      return this.userData && ['management_processes', 'service_qualification', 'success', 'mentoring', 'root'].includes(this.userData.permission_type)
    },
    cityOptions() {
      const state = this.zones.find(f => f.initials === this.userEditing.address.state)
      let cities = []
      if (state !== undefined && state.zones.length === 1) {
        cities = state.zones[0].cities
      } else if (state !== undefined && state.zones.length > 1) {
        cities = state.zones.reduce((accumulator, row, index) => {
          if (index === 1) {
            return accumulator.cities.concat(row.cities)
          }
          return accumulator.concat(row.cities)
        }).map(m => m.name)
      }

      return cities
    },

    statistic_fields() {
      const r = [
        { key: 'name', sortable: true },
        { key: 'first_4_days', sortable: true },
        { key: 'first_15_days', sortable: true },
        { key: 'last_30_days', sortable: true },
        { key: 'all_time', sortable: true },
      ]

      if (this.showStatisticsCustomPeriod) {
        r.push({ key: 'selected_time', sortable: true })
      }

      return r
    },
  },
  watch: {
    $route(to, from) {
      if (from.params.id !== to.params.id) {
        this.$options.created[0].bind(this)()
      }
    },
  },
  async created() {
    this.userService = new UserService()
    this.kanbanService = new KanbanService()
    this.zoneService = new ZoneService()
    this.presentationTemplateService = new PresentationTemplateService()
    this.segmentService = new SegmentService()

    const userDataString = await localStorage.getItem('userData')
    this.userData = JSON.parse(userDataString)

    // garante que a tela terá os dados necessários para sua execução
    this.zones = await this.zoneService.getAndStoreZones()
    this.segments = await this.segmentService.getAndStoreSegments()
    this.presentationTemplates = await this.presentationTemplateService.getAndStore()

    this.firstTab = true
    if (this.userData && this.userData.permission_type && ['success', 'mentoring'].includes(this.userData.permission_type)) {
      this.activeTab = 1
    } else {
      this.activeTab = 2
    }
  },
  methods: {
    hasAlertRegistration(field) {
      if (!this.userBase.settings.registration_warnings || !this.userBase.settings.registration_warnings.errors || this.userBase.settings.registration_warnings.errors.length === 0) {
        return ''
      }
      const filtered = this.userBase.settings.registration_warnings.errors.filter(f => f.field === field)
      if (filtered.length > 0) {
        return filtered.reduce((carry, item) => `${carry} ${item.error}`, '')
      }

      return ''
    },
    deleteWhatsappCampaign(campaignId) {
      this.$swal({
        title: 'Parar campanha?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim, parar campanha!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoadingRemoveWhatsappCampaign = true

          this.userService.deleteWhatsappCampaign(campaignId).then(response => {
            if (response.status === 200) {
              this.toastSuccess('Campanha removida', 'Campanha removida')
              this.whatsappCampaigns = this.whatsappCampaigns.filter(f => f.id !== campaignId)
            }
          }).finally(() => {
            this.isLoadingRemoveWhatsappCampaign = false
          })
        }
      }).catch(error => {
        this.toastSuccess('Erro', error.response.data.message)
      })
    },

    getIaFromRepresentedProducts() {
      this.isLoadingIaRepresentedProducts = true

      SusiService.byPrompt(
        'susi-prompt-rep-produtos-representei',
        { represented_products: this.userEditing.profile.represented_products },
      ).then(response => {
        this.susi.represented_products = response.data.data.response.split('\n').join('<BR>')
        // eslint-disable-next-line no-console
        console.log(response.data.data.log)
      }).finally(() => {
        this.isLoadingIaRepresentedProducts = false
      })
    },

    getIaFromProductsWantToRepresent() {
      this.isLoadingIaProductsWantToRepresent = true

      SusiService.byPrompt(
        'susi-prompt-rep-produtos-quero-representar',
        { products_want_to_represent: this.userEditing.profile.products_want_to_represent },
      ).then(response => {
        this.susi.products_want_to_represent = response.data.data.response.split('\n').join('<BR>')
        // eslint-disable-next-line no-console
        console.log(response.data.data.log)
      }).finally(() => {
        this.isLoadingIaProductsWantToRepresent = false
      })
    },

    getIaFromPresentation() {
      this.isLoadingIaPresentation = true

      SusiService.byPrompt(
        'susi-prompt-rep-apresentacao',
        { presentation: this.userEditing.profile.presentation },
      ).then(response => {
        this.susi.presentation = response.data.data.response.split('\n').join('<BR>')
        // eslint-disable-next-line no-console
        console.log(response.data.data.log)
      }).finally(() => {
        this.isLoadingIaPresentation = false
      })
    },
    getIaFromMainCustomers() {
      this.isLoadingIaMainCustomers = true

      SusiService.byPrompt(
        'susi-prompt-rep-clientes-ativos',
        { main_customers: this.userEditing.profile.main_customers },
      ).then(response => {
        this.susi.main_customers = response.data.data.response.split('\n').join('<BR>')
        // eslint-disable-next-line no-console
        console.log(response.data.data.log)
      }).finally(() => {
        this.isLoadingIaMainCustomers = false
      })
    },

    getIaFromWhereDidWorking() {
      this.isLoadingIaFromWhereDidWorking = true

      SusiService.byPrompt(
        'susi-prompt-rep-marcas',
        { where_did_working: this.userEditing.profile.where_did_working },
      ).then(response => {
        this.susi.where_did_working = response.data.data.response.split('\n').join('<BR>')
        // eslint-disable-next-line no-console
        console.log(response.data.data.log)
      }).finally(() => {
        this.isLoadingIaFromWhereDidWorking = false
      })
    },

    checkCnpj() {
      this.$refs.userCheckCnpj.check()
    },

    checkCnpjReview() {
      this.$refs.userCheckCnpjReview.check()
    },

    checkCoreReview(type) {
      if (type === 'core') {
        this.$refs.userCheckCoreReview.checkUserByCore()
      } else if (type === 'cpf') {
        this.$refs.userCheckCoreReview.checkUserByCpf()
      } else if (type === 'cnpj') {
        this.$refs.userCheckCoreReview.checkUserByCnpj()
      } else if (type === 'name') {
        this.$refs.userCheckCoreReview.checkUserByName()
      }
    },

    checkCoreProfile(type) {
      if (type === 'core') {
        this.$refs.userCheckCoreProfile.checkUserByCore()
      } else if (type === 'cpf') {
        this.$refs.userCheckCoreProfile.checkUserByCpf()
      } else if (type === 'cnpj') {
        this.$refs.userCheckCoreProfile.checkUserByCnpj()
      } else if (type === 'name') {
        this.$refs.userCheckCoreProfile.checkUserByName()
      }
    },

    setMasterPhone(value) {
      this.userEditing.list_phones = this.userEditing.list_phones.map(phone => ({
        ...phone,
        master: phone === value,
      }))

      this.userEditing.cell_phone = value.complete
    },
    setMasterEmail(value) {
      this.userEditing.list_emails = this.userEditing.list_emails.map(mail => ({
        ...mail,
        master: mail === value,
      }))

      this.userEditing.email = value.email
    },
    sortSuccessManager() {
      this.isLoadingSuccessManager = true
      this.kanbanService.sortSuccessManager(this.userEditing.id).then(response => {
        this.userEditing.settings.success_manager = response.data.data.user
        this.userBase.settings.success_manager = response.data.data.user

        this.$refs.userListSettings.reset()
      }).finally(() => {
        this.isLoadingSuccessManager = false
      })
    },
    confirmedBy(type) {
      if (type.verification_type === 'administration') {
        return type.account_manager.name
      }

      if (type.verification_type === 'own_user') {
        return this.userEditing.name
      }

      return 'Sistema'
    },

    /* eslint-disable no-param-reassign */
    sendConfirmationEmail() {
      this.$swal({
        title: 'Enviar e-mail?',
        text: 'Enviar e-mail para o usuário com link de confirmação para que ele possa confirmar o próprio e-mail?',
        icon: 'question',
        confirmButtonText: 'Sim, enviar link',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userEditing.email_master.isSubmitingConfirmation = true
          this.userService.sendConfirmationEmail(this.userEditing.email_master.id).then(response => {
            this.userEditing.email_master.isSubmitingConfirmation = false
            if (response.status) {
              this.toast('success', 'E-mail enviado!')
              response.data.data.user = null
              response.data.data.isSubmiting = false
              response.data.data.isSubmitingConfirmation = false
              response.data.data.old = response.data.data.email
              this.userEditing.email_master = response.data.data
            } else {
              this.toast('danger', 'Ops!', 'E-mail não enviado')
            }
          })
        }
      })
    },

    confirmEmail() {
      this.$swal({
        title: 'Confirmar e-mail?',
        text: 'Marcar este e-mail como confirmado?',
        icon: 'question',
        confirmButtonText: 'Sim, confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userEditing.email_master.isSubmiting = true
          this.userService.confirmEmail(this.userEditing.email_master.id).then(response => {
            this.userEditing.email_master.isSubmiting = false
            if (response.status) {
              const changedEmail = response.data.data
              changedEmail.old = changedEmail.email
              this.userEditing.email_master = changedEmail
              this.userEditing.list_emails = this.userEditing.list_emails.map(f => (f.master ? changedEmail : f))
              this.userBase.list_emails = this.userBase.list_emails.map(f => (f.master ? changedEmail : f))
            }
          })
        }
      })
    },

    sendConfirmationCellPone() {
      this.$swal({
        title: 'Enviar WhatsApp?',
        text: 'Enviar WhatsApp para o usuário pedindo a confirmação de seu número de telefone?',
        icon: 'question',
        confirmButtonText: 'Sim, enviar confirmação',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userEditing.phone_master.isSubmitingConfirmation = true
          this.userService.sendConfirmationWhatsApp(this.userEditing.phone_master.id).then(response => {
            this.userEditing.phone_master.isSubmitingConfirmation = false
            if (response.status) {
              this.toast('success', 'WhatsApp enviado!')
              response.data.data.user = null
              response.data.data.isSubmiting = false
              response.data.data.isSubmitingConfirmation = false
              response.data.data.old = response.data.data.complete
              this.userEditing.phone_master = response.data.data
            } else {
              this.toast('danger', 'Ops!', 'WhatsApp não enviado')
            }
          })
        }
      })
    },

    confirmCellPhone() {
      this.$swal({
        title: 'Confirmar whatsapp?',
        text: 'Marcar este WhatsApp como confirmado?',
        icon: 'question',
        confirmButtonText: 'Sim, confirmar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.userEditing.phone_master.isSubmiting = true
          this.userService.confirmWhatsApp(this.userEditing.phone_master.id).then(response => {
            this.userEditing.phone_master.isSubmiting = false
            if (response.status) {
              const changedCellPhone = response.data.data
              changedCellPhone.old = changedCellPhone.complete
              this.userEditing.phone_master = changedCellPhone
              this.userEditing.list_phones = this.userEditing.list_phones.map(f => (f.master ? changedCellPhone : f))
              this.userBase.list_phones = this.userBase.list_phones.map(f => (f.master ? changedCellPhone : f))
            }
          })
        }
      })
    },

    getTabFromId(id) {
      let index = null
      this.$refs.tabs.getTabs().forEach((f, i) => {
        if (f.id === id) {
          index = i
        }
      })

      return index
    },

    updateFileInUserBase(event) {
      if (this.userEditing.type === 'company') {
        this.userEditing.profile.catalog_url = event
      } else {
        this.userEditing.profile.curriculum_url = event
      }
    },

    previewAvatar(event) {
      this.userEditing.avatar = event
    },

    openTransaction(transactionId) {
      this.activeTab = this.getTabFromId('tab-financeiro')
      this.transactionId = transactionId
    },

    getAddressByCep(cep) {
      this.isLoadingCep = true
      this.zoneService.getAddressByCep(cep).then(response => {
        if (response) {
          this.userEditing.address = {
            zip_code: cep,
            state: response.uf,
            city: response.localidade,
            neighbor: response.bairro,
            street: response.logradouro,
          }
        } else {
          this.toast('danger', 'Ops!', 'Cep não encontrado')
        }

        this.isLoadingCep = false
      }).catch(() => {
        this.toast('danger', 'Ops!', 'Cep não encontrado')
        this.isLoadingCep = false
      })
    },

    getDataRegistrationInfo() {
      const ret = {
        status: this.userEditing.status,
        ...this.userEditing.status === 'blocked' ? {
          status_reason: this.userEditing.status_reason,
          ...this.userEditing.status_reason === 'double' ? {
            double_user_id: this.userEditing.settings.double_user_id,
          } : {},
        } : {},

        type: this.userEditing.type,
        name: this.userEditing.name,
        cpf: this.userEditing.cpf ?? '',
        cnpj: this.userEditing.cnpj ?? '',
        email: this.userEditing.email,
        emails_cms: this.userEditing.emails_cms,
        emails: this.userEditing.list_emails.filter(f => !f.master).map(f => f.email),
        list_emails: this.userEditing.list_emails ?? [],
        email_master: this.userEditing.list_emails ? this.userEditing.list_emails.find(f => f.master) : [],
        cell_phone: this.userEditing.cell_phone,
        phones: this.userEditing.list_phones.filter(f => !f.master).map(f => f.complete),
        phones_cms: this.userEditing.phones_cms,

        password: this.userEditing.password,
        test: this.userEditing.test ? parseInt(this.userEditing.test, 10) : 0,

        address: {
          zip_code: this.userEditing.address.zip_code ?? '',
          state: this.userEditing.address.state ?? '',
          city: this.userEditing.address.city ?? '',
          neighbor: this.userEditing.address.neighbor ?? '',
          street: this.userEditing.address.street ?? '',
          number: this.userEditing.address.number ?? '',
          complement: this.userEditing.address.complement ?? '',
        },

        profile: {
          company_constituted: this.userEditing.profile.company_constituted ?? '',
          company_name: this.userEditing.profile.company_name ?? '',
          position_in_company: this.userEditing.profile.position_in_company ?? '',
          link_site: this.userEditing.profile.link_site ?? '',
          link_facebook: this.userEditing.profile.link_facebook ?? '',
          link_linkedin: this.userEditing.profile.link_linkedin ?? '',
          link_instagram: this.userEditing.profile.link_instagram ?? '',
        },
      }

      return ret
    },

    sendPasswordRecovery(type) {
      this.isSubmitingPasswordRecovery = true
      this.userService.sendEmailToRecoverPassword(this.$route.params.id, type).then(() => {
        this.toast('success', 'Recuperação enviada!')
        this.isSubmitingPasswordRecovery = false
      }).catch(error => {
        this.swall('error', 'Ops - Algo deu errado', error.response.data.message ?? Object.values(error.response.data.errors).join(''))
        this.isSubmitingPasswordRecovery = false
      })
    },

    sendDirectAccess() {
      this.isSubmitingDirectAccess = true
      this.userService.sendEmailToEasyLogin(this.$route.params.id).then(() => {
        this.toast('success', 'E-mail enviado!')
        this.isSubmitingDirectAccess = false
      }).catch(error => {
        this.swall('error', 'Ops - Algo deu errado', error.response.data.message ?? Object.values(error.response.data.errors).join(''))
        this.isSubmitingDirectAccess = false
      })
    },

    migrateLogin() {
      this.isSubmitingMigrateLogin = true
      this.userService.migrateLogin(this.$route.params.id).then(() => {
        this.toast('success', 'Login migrado!')
        this.isSubmitingMigrateLogin = false
      }).catch(error => {
        this.swall('error', 'Ops - Algo deu errado', this.getResponseErrorMessage(error.response.data))
        this.isSubmitingMigrateLogin = false
      })
    },

    saveUserRegistration() {
      const user = this.getDataRegistrationInfo()

      return this.$refs.rulesRegistration.validate().then(success => {
        if (success) {
          this.isSubmiting = true
          this.userService.updateRegistration(this.$route.params.id, user).then(() => {
            this.isSubmiting = false
            this.userEditing.password = ''
            this.toast('success', 'Usuário alterado!')
            this.userBase = JSON.parse(JSON.stringify(this.userEditing))
            this.getUserBase()
          }).catch(error => {
            this.isSubmiting = false
            let msg = error.response.data.message && error.response.data.message.search('was invalid') === -1 ? error.response.data.message : ''
            if (Object.values(error.response.data.errors).length > 0) {
              msg = msg.length > 0 ? `${msg} - ` : msg
              msg = `${msg} ${Object.values(error.response.data.errors).join('')}`
            }

            this.swall('error', 'Ops - Algo deu errado', msg)
          })
        } else {
          this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
        }
      })
    },

    loadTagsPerfil() {
      this.loadTagsRaw(this.$refs.userEditSegmentsPerfil.selectedSegments)
    },

    loadTags() {
      this.loadTagsRaw(this.$refs.userEditSegments.selectedSegments)
    },

    loadTagsRaw(source) {
      let tags = []
      source.forEach(segment => {
        if (segment.automaticSearchTags) {
          tags.push(...segment.automaticSearchTags)
        }
        if (segment.fatherAutomaticSearchTags) {
          tags.push(...segment.fatherAutomaticSearchTags)
        }
      })
      tags = [...new Set(tags.filter(f => f !== ''))]

      if (tags.length === 0) {
        this.toast('danger', 'Ops!', 'Nenhuma tag encontrada para o perfil')
      } else {
        let newTags = this.userEditing.tags.split('\n')
        newTags = [...new Set([
          ...newTags,
          ...new Set(tags),
        ])].filter(f => f !== '')
        this.userEditing.tags = newTags.join('\n')
      }
    },

    loadPresentationTemplate() {
      const userVariables = {
        segment: {
          required: false,
          value: '',
          key: 'segmento',
          type: 'required',
        },
        state: {
          required: false,
          value: '',
          key: 'estado',
          type: 'random',
        },
        city: {
          required: false,
          value: '',
          key: 'cidade',
          type: 'random',
        },
        zone: {
          required: false,
          value: '',
          key: 'zona',
          type: 'random',
        },
        region: {
          required: false,
          value: '',
          key: 'regiao',
          type: 'random',
        },
        time: {
          required: false,
          value: '',
          key: 'tempo',
          type: 'required',
        },
        client: {
          required: false,
          value: '',
          key: 'cliente',
          type: 'required',
        },
        product: {
          required: false,
          value: '',
          key: 'produto',
          type: 'required',
        },
        brand: {
          required: false,
          value: '',
          key: 'marca',
          type: 'required',
        },
      }

      // const doNotConsider = Object.entries(userVariables).filter(f => f[1].type === 'random').map(m => (m[1].key))

      if (this.userEditing.profile.preferred_segments.length > 0) {
        userVariables.segment.required = true
        const segments = this.userEditing.profile.preferred_segments.filter(m => this.segments.find(f => f.id === m && f.parent_id === null))
        userVariables.segment.value = segments.map(m => this.segments.find(f => f.id === m)?.name).join(', ')
      }

      if (this.userEditing.address.state.length > 0 && this.userEditing.address.city.length > 0) {
        const state = this.zones.find(m => m.initials === this.userEditing.address.state)

        if (state !== undefined) {
          const zone = state.zones.find(f => f.cities.find(fCities => fCities.name.toLowerCase() === this.userEditing.address.city.toLowerCase()))

          if (zone !== undefined) {
            userVariables.zone.required = true
            userVariables.zone.value = zone.name
          }
        }
      }

      if (this.userEditing.type === 'company' && this.userEditing.profile.preferred_zones.length > 0) {
        userVariables.region.required = true
        if (this.$refs.userEditZonesPerfil) {
          userVariables.region.value = [...new Set(this.$refs.userEditZonesPerfil.selectedZones.map(m => m.zone_name).filter(f => f.trim() !== ''))].join(', ')
        } else if (this.$refs.userEditZones) {
          userVariables.region.value = [...new Set(this.$refs.userEditZones.selectedZones.map(m => m.zone_name).filter(f => f.trim() !== ''))].join(', ')
        }
      }

      if (this.userEditing.address.state.length > 0) {
        userVariables.state.required = true
        userVariables.state.value = this.userEditing.address.state
      }

      if (this.userEditing.address.city.length > 0) {
        userVariables.city.required = true
        userVariables.city.value = this.userEditing.address.city
      }

      if (this.userEditing.type === 'representative' && this.userEditing.profile.representation_experience !== null && this.userEditing.profile.representation_experience !== '') {
        if (this.userEditing.profile.representation_experience.indexOf('05 anos') > -1) {
          userVariables.time.required = true
          userVariables.time.value = '5 anos'
        } else if (this.userEditing.profile.representation_experience.indexOf('10 anos') > -1) {
          userVariables.time.required = true
          userVariables.time.value = '10 anos'
        } else if (this.userEditing.profile.representation_experience.indexOf('15 anos') > -1) {
          userVariables.time.required = true
          userVariables.time.value = '15 anos'
        } else if (this.userEditing.profile.representation_experience.indexOf('20 anos') > -1) {
          userVariables.time.required = true
          userVariables.time.value = '20 anos'
        }
      }

      if (this.userEditing.profile.main_customers.length > 0) {
        userVariables.client.required = true
        userVariables.client.value = this.userEditing.profile.main_customers.split('\n').join(', ')
      }

      if (this.userEditing.type === 'company' && this.userEditing.profile.products.length > 0) {
        userVariables.product.required = true
        userVariables.product.value = this.userEditing.profile.products.split('\n').join(', ')
      } else if (this.userEditing.type === 'representative' && this.userEditing.profile.represented_products.length > 0) {
        userVariables.product.required = true
        userVariables.product.value = this.userEditing.profile.represented_products.split('\n').join(', ')
      }

      if (this.userEditing.type === 'company' && this.userEditing.profile.company_brands.length > 0) {
        userVariables.brand.required = true
        userVariables.brand.value = this.userEditing.profile.company_brands.split('\n').join(', ')
      } if (this.userEditing.type === 'representative' && this.userEditing.profile.where_did_working.length > 0) {
        userVariables.brand.required = true
        userVariables.brand.value = this.userEditing.profile.where_did_working.split('\n').join(', ')
      }

      let avaiableTemplates = this.presentationTemplates.map(template => {
        const matches = Object.entries(userVariables).filter(userVariable => userVariable[1].required && template.variables.includes(userVariable[1].key)).length
        return {
          ...template,
          matches: matches > 0 ? (matches * 100) / template.variables.length : 0,
        }
      })

      if (avaiableTemplates.filter(template => template.type === this.userEditing.type).length > 0) {
        avaiableTemplates = avaiableTemplates.filter(template => template.type === this.userEditing.type)
      }
      avaiableTemplates = avaiableTemplates.filter(template => template.matches === 100)
      avaiableTemplates = avaiableTemplates.sort((a, b) => b.variables.length - a.variables.length)

      if (avaiableTemplates.length === 0) {
        this.swall('error', 'Nenhum template encontrado com os requisitos do cadastro')
      } else {
        const selectedTemplate = avaiableTemplates[Math.round(Math.random() * (avaiableTemplates.length - 1))]

        let text = selectedTemplate.template
        Object.entries(userVariables).forEach(userVariable => {
          text = text.replaceAll(`%${userVariable[1].key}%`, userVariable[1].value)
        })
        this.userEditing.profile.presentation = text
      }
    },

    loadSegments(ref) {
      let keys = []
      if (this.userEditing.type === 'representative') {
        keys = keys.concat(this.userEditing.profile.products_want_to_represent.split('\n')).concat(this.userEditing.profile.represented_products.split('\n'))
      } else {
        keys = keys.concat(this.userEditing.profile.products.split('\n')).concat(this.userEditing.profile.company_brands.split('\n'))
      }

      keys = [...new Set(keys)].map(m => m.trim().toLowerCase())

      let matches = []
      keys.forEach(key => {
        const match = this.segments.filter(f => f.parent_id !== null
                  && (
                    f.name.trim().toLowerCase() === key
                          || (f.tags && f.tags.find(fTag => key.search(fTag.trim().toLowerCase()) >= 0))
                  ))

        if (match) {
          matches = matches.concat(match)
        }
      })

      let matchSegments = []
      matches.forEach(key => {
        matchSegments.push(key.parent_id, key.id)
      })
      matchSegments = [...new Set(matchSegments)]
      ref.setSegments(matchSegments)
    },

    loadZones(ref) {
      const state = this.zones.find(f => f.initials.toLowerCase() === this.userEditing.address.state.toLowerCase())
      if (state !== undefined) {
        const zone = state.zones.find(z => z.cities.find(c => c.name.toLowerCase() === this.userEditing.address.city.toLowerCase()))

        if (zone !== undefined) {
          ref.setZones([
            state.initials,
            zone.id,
          ])
        }
      }
    },

    getDataPrimary() {
      return {
        name: this.userEditing.name,
        company_name: this.userEditing.profile.company_name ?? '',
        position_in_company: this.userEditing.profile.position_in_company ?? '',
        type: this.userEditing.type,
        state: this.userEditing.address.state,
        city: this.userEditing.address.city,
        neighbor: this.userEditing.address.neighbor,
        street: this.userEditing.address.street,
        zip_code: this.userEditing.address.zip_code,
        cell_phone: this.userEditing.cell_phone,
        phones: this.userEditing.list_phones.filter(f => !f.master).map(f => f.complete),
        phones_cms: this.userEditing.phones_cms,
        email: this.userEditing.email,
        emails_cms: this.userEditing.emails_cms,
        emails: this.userEditing.list_emails.filter(f => !f.master).map(f => f.email),
      }
    },

    getDataCommercial() {
      if (this.userEditing.type === 'company') {
        return {
          type: this.userEditing.type,
          company_brands: this.userEditing.profile.company_brands.split('\n').filter(f => (f.trim().length > 0)),
          zones_where_reps_are_looking: this.userEditing.profile.zones_where_reps_are_looking.split('\n').filter(f => (f.trim().length > 0)),
          products: this.userEditing.profile.products.split('\n').filter(f => (f.trim().length > 0)),
          main_customers: this.userEditing.profile.main_customers.split('\n').filter(f => (f.trim().length > 0)),
        }
      }

      return {
        type: this.userEditing.type,
        products_want_to_represent: this.userEditing.profile.products_want_to_represent.split('\n').filter(f => (f.trim().length > 0)),
        represented_products: this.userEditing.profile.represented_products.split('\n').filter(f => (f.trim().length > 0)),
        where_did_working: this.userEditing.profile.where_did_working.split('\n').filter(f => (f.trim().length > 0)),
        main_customers: this.userEditing.profile.main_customers.split('\n').filter(f => (f.trim().length > 0)),
        company_constituted: this.userEditing.profile.company_constituted,
        board_registration_core: this.userEditing.profile.board_registration_core,
        representation_experience: this.userEditing.profile.representation_experience,
        has_a_customer_portfolio: this.userEditing.profile.has_a_customer_portfolio,
        has_own_vehicle: this.userEditing.profile.has_own_vehicle,
      }
    },

    getDataZones() {
      return {
        preferred_segments: this.userEditing.profile.preferred_segments,
        preferred_zones: this.userEditing.profile.preferred_zones,
      }
    },

    getDataAdditions() {
      return {
        presentation: this.userEditing.profile.presentation,
        tags: this.userEditing.tags.split('\n'),
      }
    },

    getDataSocials() {
      return {
        link_site: this.userEditing.profile.link_site,
        link_facebook: this.userEditing.profile.link_facebook,
        link_instagram: this.userEditing.profile.link_instagram,
        link_linkedin: this.userEditing.profile.link_linkedin,
      }
    },

    getDataApproval() {
      return {
        status: this.userEditing.status,
        status_reason: this.userEditing.status_reason,
        double_user_id: this.userEditing.settings.double_user_id,
      }
    },

    getDataSearchProfile() {
      const commercial = this.getDataCommercial()

      return {
        type: commercial.type,
        profile: {
          presentation: this.userEditing.profile.presentation,
          ...this.getDataZones(),
          ...{ ...commercial, type: null },
        },

        tags: {
          ...this.userEditing.tags.split('\n'),
        },
      }
    },

    handleResponse(promise, reloadUser) {
      this.isSubmiting = true
      return promise.then(response => {
        if (response.status) {
          this.toast('success', 'Dados salvos!', '')
          this.userBase = JSON.parse(JSON.stringify(this.userEditing))
        } else {
          this.toast('danger', 'Ops!', 'Algum erro aconteceu')
        }

        if (reloadUser) {
          this.getUserBase().then(() => {
            this.isSubmiting = false
          })
        } else {
          this.isSubmiting = false
        }
      }).catch(error => {
        if (error.response) {
          this.swall('error', 'Ops - Existem erros de validação', Object.values(error.response.data.errors))
        }

        this.isSubmiting = false
      })
    },

    saveUserSearchProfile(forceThroughPlannerStrategyRemoval) {
      const limits = this.limits[this.userEditing.type]

      this.errorsSegmentsPerfil = ''
      this.errorsZonesPerfil = ''

      if (this.$refs.userEditSegmentsPerfil.countSelectedSegments() < limits.min.segments) {
        this.errorsSegmentsPerfil = `Selecione pelo menos ${limits.min.segments} segmentos`
        this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
      }

      if (this.$refs.userEditSegmentsPerfil.countSelectedSegments() > limits.max.segments) {
        this.errorsSegmentsPerfil = `Selecione no máximo ${limits.max.segments} segmentos`
        this.toast('danger', 'Erros de validação', 'Preencha corretamente todos os campos')
      }

      if (this.$refs.userEditZonesPerfil.countSelectedStates() < limits.min.states) {
        this.errorsZonesPerfil = `Selecione pelo menos ${limits.min.states} zonas`
        this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
      }

      if (this.$refs.userEditZonesPerfil.countSelectedStates() > limits.max.states) {
        this.errorsZonesPerfil = `Selecione no máximo ${limits.max.states} estados`
        this.toast('danger', 'Erros de validação', 'Preencha corretamente todos os campos')
      }

      if (this.errorsZonesPerfil === '' && this.errorsSegmentsPerfil === '') {
        this.$refs.rulesSearchProfile.validate().then(success => {
          if (success) {
            this.handleResponse(
              this.userService.updateSearchProfile(this.$route.params.id, {
                ...this.getDataSearchProfile(),
                ...forceThroughPlannerStrategyRemoval ? { force_through_planner_strategy_removal: 1 } : {},
              }).catch(error => {
                if (error.response.data.errors.planner_campaign_zone_not_in_profile) {
                  this.$refs['modal-planner-strategy-removal-search-profile'].$refs.modal.show()
                } else {
                  throw error
                }
              }),
            )
          } else {
            this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
          }
        })
      }
    },

    async validateApproval() {
      return this.$refs.rulesApproval.validate().then(success => {
        if (!success) {
          this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
        }

        return success
      })
    },

    async saveUserReview(formZone, forceThroughPlannerStrategyRemoval) {
      const type = this.userBase.status

      switch (formZone) {
        case 'all': {
          if (type !== 'filter') {
            if (!this.validateSegments() || !this.validateZones()) {
              return
            }
          }

          if (!await this.validateApproval()) {
            return
          }

          this.isSubmiting = true
          try {
            this.submittingProgressed = 0
            this.submittingTotal = 6

            await this.userService.updateReview(this.$route.params.id, type, this.getDataPrimary()).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })
            this.submittingProgressed += 1
            await this.userService.updateReviewCommercial(this.$route.params.id, type, this.getDataCommercial()).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })
            this.submittingProgressed += 1

            let plannerCampaignZoneNotInProfile = false
            await this.userService.updateReviewZones(this.$route.params.id, type, {
              ...this.getDataZones(),
              ...forceThroughPlannerStrategyRemoval ? { force_through_planner_strategy_removal: 1 } : {},
            }).catch(error => {
              if (error.response.data.errors.planner_campaign_zone_not_in_profile) {
                plannerCampaignZoneNotInProfile = true
                this.$refs['modal-planner-strategy-removal-user-review-all'].$refs.modal.show()
              } else {
                throw error
              }
            }).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })
            if (plannerCampaignZoneNotInProfile) {
              throw new Error('don\'t show modal')
            }

            this.submittingProgressed += 1
            await this.userService.updateReviewAdditions(this.$route.params.id, type, this.getDataAdditions()).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })
            this.submittingProgressed += 1
            await this.userService.updateReviewSocials(this.$route.params.id, type, this.getDataSocials()).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })
            this.submittingProgressed += 1
            await this.userService.updateReviewApproval(this.$route.params.id, type, this.getDataApproval()).catch(response => {
              throw new Error(this.getResponseErrorMessage(response.response.data))
            })

            const wasReview = this.userBase.status === 'review'
            this.userBase = JSON.parse(JSON.stringify(this.userEditing))
            if (wasReview) {
              await this.$refs['user-review-warning-modal'].verify(this.userBase, formZone)
            }
          } catch (error) {
            if (error.message !== 'don\'t show modal') {
              this.swall('error', 'Existem erros de validação', error.message)
            }
          }

          this.submittingTotal = 0
          this.isSubmiting = false
          break
        }
        case 'primary':
          this.$refs.rulesPrimary.validate().then(success => {
            if (success) {
              const wasReview = this.userBase.status === 'review'
              this.handleResponse(this.userService.updateReview(this.$route.params.id, type, this.getDataPrimary()), true)
                .then(async () => {
                  if (wasReview) {
                    this.isSubmiting = true
                    await this.$refs['user-review-warning-modal'].verify(this.userBase, formZone)
                    this.isSubmiting = false
                  }
                })
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })
          break

        case 'commercial':
          this.$refs.rulesCommercial.validate().then(success => {
            if (success) {
              this.handleResponse(this.userService.updateReviewCommercial(this.$route.params.id, type, this.getDataCommercial()))
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })
          break

        case 'zones':
          if (type !== 'filter') {
            if (!this.validateSegments() || !this.validateZones()) {
              return
            }
          }

          this.$refs.rulesZones.validate().then(success => {
            if (success) {
              const wasReview = this.userBase.status === 'review'
              this.handleResponse(
                this.userService.updateReviewZones(this.$route.params.id, type, {
                  ...this.getDataZones(),
                  ...forceThroughPlannerStrategyRemoval ? { force_through_planner_strategy_removal: 1 } : {},
                }).catch(error => {
                  if (error.response.data.errors.planner_campaign_zone_not_in_profile) {
                    this.$refs['modal-planner-strategy-removal-user-review-zones'].$refs.modal.show()
                  } else {
                    throw error
                  }
                }),
              ).then(async () => {
                if (wasReview) {
                  this.isSubmiting = true
                  await this.$refs['user-review-warning-modal'].verify(this.userBase, formZone)
                  this.isSubmiting = false
                }
              })
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })

          break

        case 'additions':
          this.$refs.rulesAdditions.validate().then(success => {
            if (success) {
              const wasReview = this.userBase.status === 'review'
              this.handleResponse(this.userService.updateReviewAdditions(this.$route.params.id, type, this.getDataAdditions()))
                .then(async () => {
                  if (wasReview) {
                    this.isSubmiting = true
                    await this.$refs['user-review-warning-modal'].verify(this.userBase, formZone)
                    this.isSubmiting = false
                  }
                })
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })
          break

        case 'socials':
          this.$refs.rulesSocials.validate().then(success => {
            if (success) {
              const wasReview = this.userBase.status === 'review'
              this.handleResponse(this.userService.updateReviewSocials(this.$route.params.id, type, this.getDataSocials()))
                .then(async () => {
                  if (wasReview) {
                    this.isSubmiting = true
                    await this.$refs['user-review-warning-modal'].verify(this.userBase, formZone)
                    this.isSubmiting = false
                  }
                })
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })
          break

        case 'approval':
          this.$refs.rulesApproval.validate().then(success => {
            if (success) {
              this.handleResponse(this.userService.updateReviewApproval(this.$route.params.id, type, this.getDataApproval()))
            } else {
              this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
            }
          })
          break

        default:
          break
      }
    },

    validateZones() {
      const limits = this.limits[this.userEditing.type]
      this.errorsZones = ''
      if (this.$refs.userEditZones.countSelectedStates() < limits.min.states) {
        this.errorsZones = `Selecione pelo menos ${limits.min.states} zonas`
        this.toast('danger', 'Erros de validação', 'Preencha todos os campos obrigatórios')
      }

      if (this.$refs.userEditZones.countSelectedStates() > limits.max.states) {
        this.errorsZones = `Selecione no máximo ${limits.max.states} estados`
        this.toast('danger', 'Erros de validação', 'Preencha corretamente todos os campos')
      }

      return (this.errorsZones === '')
    },

    validateSegments() {
      const limits = this.limits[this.userEditing.type]
      this.errorsSegments = ''
      if (this.$refs.userEditSegments.countSelectedSegments() < limits.min.segments) {
        this.errorsSegments = `Selecione pelo menos ${limits.min.segments} segmentos`
        this.toast('danger', 'Erros de validação', 'Preencha corretamente os segmentos do usuário')
      }

      if (this.$refs.userEditSegments.countSelectedSegments() > limits.max.segments) {
        this.errorsSegments = `Selecione no máximo ${limits.max.segments} segmentos`
        this.toast('danger', 'Erros de validação', 'Preencha corretamente as regiões do usuário')
      }

      return (this.errorsSegments === '')
    },

    selectState() {
      this.userEditing.address.city = ''
      this.userEditing.address.zip_code = ''
    },
    selectCity(value) {
      this.userEditing.address.zip_code = ''
      this.userEditing.address.city = value
    },
    changedCore(value) {
      if (!value) {
        this.userEditing.profile.board_registration_core = ''
      }
    },
    changedCompany(value) {
      if (!value) {
        this.userEditing.profile.company_constituted = ''
      }
    },
    changedExperience(value) {
      if (!value) {
        this.userEditing.profile.representation_experience = ''
      }
    },
    changedPreferredZones(value) {
      this.userEditing.profile.preferred_zones = value
    },
    changedPreferredSegments(value) {
      this.userEditing.profile.preferred_segments = value
    },
    getWhatsappCampaigns() {
      this.isLoadingWhatsappCampaigns = true
      this.userService.getWhatsappCampaigns(this.$route.params.id).then(response => {
        this.whatsappCampaigns = response.data.data.campaigns
      }).finally(() => {
        this.isLoadingWhatsappCampaigns = false
      })
    },
    getStatistics() {
      let rangeStart = ''
      let rangeEnd = ''
      if (this.rangeDate.split('até').length === 2) {
        rangeStart = this.rangeDate.split('até')[0].trim()
        rangeEnd = this.rangeDate.split('até')[1].trim()
        this.showStatisticsCustomPeriod = true
      } else {
        this.showStatisticsCustomPeriod = false
      }

      this.isLoadingStatistics = true
      return this.userService.getStatistics(this.$route.params.id, rangeStart, rangeEnd).then(response => {
        this.statistic_items = response.data.data.statistics
        this.isLoadingStatistics = false
      })
    },
    async updateUserBase() {
      this.isLoading = true
      await this.getUserBase()
      this.isLoading = false
    },
    getUserBase() {
      CommerciaService.getUser(this.$route.params.id).then(response => {
        this.userCommercia = response.data.data.user
      })

      return this.userService.getAllInformations(this.$route.params.id).then(response => {
        const { user } = response.data.data
        const emailMaster = user.list_emails ? user.list_emails.find(f => f.master) : null
        const phoneMaster = user.list_phones ? user.list_phones.find(f => f.master) : null
        this.userBase = {
          ...user,
          tags: user.tags ? user.tags.join('\n') : '',
          cell_phone: user.cell_phone ? user.cell_phone.match(/[0-9]*/g).join('') : '',
          phones: user.phones ? user.phones.map(m => m.match(/[0-9]*/g).join('')) : [],
          phones_cms: user.phones_cms ?? [],
          emails: user.emails ?? [],
          emails_cms: user.emails_cms ?? [],
          list_emails: user.list_emails.map(m => ({
            ...m, isSubmiting: false, isSubmitingConfirmation: false, old: m.email,
          })) ?? [],
          email_master: user.list_emails ? {
            ...emailMaster, isSubmiting: false, isSubmitingConfirmation: false, old: emailMaster?.email ?? '',
          } : [],
          phone_master: phoneMaster ? {
            ...phoneMaster, isSubmiting: false, isSubmitingConfirmation: false, old: phoneMaster?.complete ?? '',
          } : [],
          list_phones: user.list_phones.map(m => ({
            ...m, isSubmiting: false, isSubmitingConfirmation: false, old: m.complete,
          })) ?? [],
          status_reason: user.settings.status_reason ?? '',
          address: {
            city: user.address.city ?? '',
            complement: user.address.complement ?? '',
            neighbor: user.address.neighbor ?? '',
            number: user.address.number ?? '',
            state: user.address.state ?? '',
            street: user.address.street ?? '',
            zip_code: user.address.zip_code ?? '',
          },
          profile: {
            ...user.profile,
            preferred_zones: user.profile.preferred_zones ?? [],
            preferred_segments: user.profile.preferred_segments ?? [],
            presentation: user.profile.presentation ?? '',
            // representative
            products_want_to_represent: user.profile.products_want_to_represent?.join('\n') ?? '',
            represented_products: user.profile.represented_products?.join('\n') ?? '',
            where_did_working: user.profile.where_did_working?.join('\n') ?? '',
            // company
            company_brands: user.profile.company_brands?.join('\n') ?? '',
            products: user.profile.products?.join('\n') ?? '',
            zones_where_reps_are_looking: user.profile.zones_where_reps_are_looking?.join('\n') ?? '',
            // representative and company
            main_customers: user.profile.main_customers?.join('\n') ?? '',
          },
          settings: {
            ...user.settings,
          },
        }

        this.switches.company_constituted = this.userBase.profile.company_constituted?.length > 0
        this.switches.board_registration_core = this.userBase.profile.board_registration_core?.length > 0
        this.switches.representation_experience = this.userBase.profile.representation_experience?.length > 0

        this.userEditing = JSON.parse(JSON.stringify(this.userBase))

        // redireciona para a tab certa após executar um salvamento de dados
        if (this.changeToTab !== null) {
          this.activeTab = this.changeToTab
          this.changeToTab = null
        }
      })
    },

    showDiff(base, editing) {
      let ret = ''
      Object.entries(base).forEach(b => {
        if (b[1] !== null && typeof b[1] === 'object') {
          ret += `${this.showDiff(b[1], editing[b[0]])}`
        } else if (editing[b[0]] !== b[1]) {
          if (typeof editing[b[0]] === typeof b[1]) {
            ret += `diferença em ${b[0]}: de ${typeof b[1]} '${b[1]}' para ${typeof editing[b[0]]} '${editing[b[0]]}'\n`
          } else {
            const diffa = typeof b[1] === 'number' ? b[1].toString() : b[1]
            const diffb = typeof editing[b[0]] === 'number' ? editing[b[0]].toString() : editing[b[0]]
            if (diffa !== diffb) {
              ret += `diferença em ${b[0]}: de ${typeof b[1]} '${b[1]}' para ${typeof editing[b[0]]} '${editing[b[0]]}'\n`
            }
          }
        }
      })

      return ret
    },

    async changeTab(newTab, prevTab, event) {
      // const newTabTitle = this.$refs.tabs.getTabs()[newTab].title
      // const oldTabTitle = this.$refs.tabs.getTabs()[this.activeTab].title
      const newTabId = this.$refs.tabs.getTabs()[newTab].id
      // const oldTabId = this.$refs.tabs.getTabs()[this.activeTab].id

      if (this.isLoading && !this.firstTab) {
        event.preventDefault()
        return
      }

      if (!this.openedProfileTab && ['tab-principal', 'tab-dados-cadastrais', 'tab-perfil-de-busca', 'tab-email', 'tab-dados-fiscais', 'tab-configuracoes'].indexOf(newTabId) >= 0) {
        this.openedProfileTab = true

        this.isLoading = true
        await this.getUserBase()
        this.isLoading = false
      }

      if (newTab === 0) {
        event.preventDefault()
        this.activeTab = 1
      }

      if (['tab-mod-oportunidade', 'tab-mod-sucesso', 'tab-mod-crm'].includes(newTabId)) {
        event.preventDefault()
        this.activeTab = newTab + 1
      }

      this.userEditing = JSON.parse(JSON.stringify(this.userBase))

      // se mudar para tab Perfil de busca, recarregar segments e zones
      if (newTabId === 'tab-perfil-de-busca') {
        this.$refs.userEditSegmentsPerfil.setSegments(this.userEditing.profile.preferred_segments, true, false)
        this.$refs.userEditZonesPerfil.setZones(this.userEditing.profile.preferred_zones, true, false)
      }

      // se mudar para tab revisão, recarregar segments e zones
      if (newTabId === 'tab-revisao') {
        this.$refs.userEditSegments.setSegments(this.userEditing.profile.preferred_segments, true, false)
        this.$refs.userEditZones.setZones(this.userEditing.profile.preferred_zones, true, false)
      }
      // }

      if (!this.openedDashboardTab && newTabId === 'tab-principal') {
        this.openedDashboardTab = true

        this.isLoadingCard = true
        this.kanbanService.getKanbanCard(this.$route.params.id).then(response => {
          this.cardData = response.data.data.card

          if (!this.cardData) {
            this.cardData = {
              user_id: this.$route.params.id,
              role: null,
              column: null,
              account_manager_id: null,
            }
          }

          this.isLoadingCard = false
        })
        await this.getStatistics()
        await this.getWhatsappCampaigns()
      }

      if (newTabId === 'tab-email') {
        this.$refs.userEmailTool.init()
      }

      if (newTabId === 'tab-planos') {
        this.$refs.userListQuotas.init()
      }

      if (newTabId === 'tab-favoritos') {
        this.$refs.userListFavorites.init()
      }

      if (newTabId === 'tab-configuracoes') {
        this.$refs.userListSettings.init()
      }

      if (newTabId === 'tab-demografico') {
        this.$refs.userListDemographic.init()
      }
    },

    toast(type, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text,
          icon: type === 'success' ? 'EditIcon' : 'BellIcon',
          variant: type,
        },
      })
    },

    swall(type, title, text) {
      this.$swal({
        title,
        text,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .tabs a[aria-controls="tab-foto"] {
    background-color: transparent!important;
    border: 0px solid transparent!important;
    box-shadow: none!important;
  }

  .tabs .col-auto{
    border-right: 1px solid #cecece;
    padding-right: 10px;
  }

  .tabs .col-auto ul li a{
    justify-content: flex-start!important;
  }

  h4{
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .text-button{
    margin-top: 20px;

    span{
      margin-right: 20px;
      font-size: 0.857rem;
      margin-bottom: 4px;
    }
  }

  .scroll-area {
    position: relative;
    margin: auto;
    width: 100%;
    height: 300px;
  }

  .ul-list{
    list-style-type: none;
    padding-left: 0px;
  }

  .ul-list-padding-1{
    padding-left: 20px;
  }

  .ul-list-padding-2{
    padding-left: 40px;
  }

  .list-group-item{
    cursor: pointer;
  }

  .switches{
    margin-bottom: 10px;
  }

  .switches-select{
    margin-left: 40px;
  }

  .b-avatar svg{
    margin-right: 0px!important;
  }

  .swal2-actions{
    justify-content: space-evenly;
  }

  .card-test {
    background-color: #FFBBBB;
  }
</style>
