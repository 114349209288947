<template>
  <b-modal
    ref="modal"
    hide-header
    hide-header-close
    content-class="text-center"
    footer-class="justify-content-center"
    cancel-title="Voltar"
    ok-title="Excluir estratégia e salvar"
    ok-variant="danger"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <h2>Cuidado!</h2>
    <br>
    Você está tentando remover do perfil de busca uma região utilizada na estratégia do planejador.<br>
    <br>
    <b>Isso causará a exclusão da estratégia no planejador.</b>
  </b-modal>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  name: 'ModalPlannerStrategyRemoval',
  components: {
    BModal,
  },
}
</script>
