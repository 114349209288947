<template>
  <b-alert
    v-if="registrationWarnings && registrationWarnings.errors && registrationWarnings.errors.length > 0"
    show
    variant="warning"
  >
    <div class="d-flex align-items-center">
      <div class="pl-2">
        <feather-icon
          icon="AlertTriangleIcon"
          class="mr-50"
          size="28"
        />
      </div>

      <div class="alert-body font-small-3">
        <span class="font-medium-2 pb-1 d-block">Alertas da validação de cadastro</span>
        <span
          v-for="error in registrationWarnings.errors"
          :key="`${error.field}-${error.validation}`"
          class="d-block"
        >
          - {{ getTitle(error.field) }}: {{ error.error }}
        </span>

        <b-button
          class="mt-1"
          size="sm"
          :disabled="isLoading"
          @click="runAllValidations()"
        >
          Executar novamente as automações
          <b-spinner
            v-if="isLoading"
            small
          />
        </b-button>
      </div>
    </div>
  </b-alert>
</template>

<script>
import {
  BAlert,
  BButton, BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'

export default {
  components: {
    BSpinner,
    BAlert,
    BButton,
  },

  props: {
    registrationWarnings: {
      type: Object,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      userService: null,
      isLoading: false,
      fields: {
        products_want_to_represent: 'Produtos que deseja representar',
        represented_products: 'Produtos que já representou',
        main_customers: 'Principais clientes',
        where_did_working: 'Marcas',
        email: 'E-mail',
        emails: 'E-mail Adicional',
        link_site: 'Site',
        link_instagram: 'Instagram',
        link_linkedin: 'Linkedin',
        link_facebook: 'Facebook',
        presentation: 'Apresentação',
      },
    }
  },

  created() {
    this.userService = new UserService()
  },

  methods: {
    runAllValidations() {
      this.isLoading = true
      this.userService.runAllValidations(this.userId).finally(() => {
        this.isLoading = false
        this.$emit('updated', true)
      })
    },

    getTitle(field) {
      if (this.fields[field]) {
        return this.fields[field]
      }

      return field
    },
  },
}
</script>
<style lang="scss">
</style>
