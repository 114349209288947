<template>
  <div>
    <b-tab
      id="tab-mod-crm"
      title="CRM"
    >
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        CRM
      </template>
    </b-tab>

    <b-tab
      id="tab-clients"
      title="Contas do usuário"
      lazy
    >
      <template #title>
        <span class="pl-1">Contas</span>
      </template>

      <crm-user
        :user-id="userId"
      />
    </b-tab>
  </div>
</template>

<script>
import {
  BTab,
} from 'bootstrap-vue'
import CrmUser from './User.vue'

export default {
  components: {
    BTab,
    CrmUser,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
}
</script>
