<template>
  <b-card
    v-if="user.coreResponse || isLoading"
    border-variant="primary"
    bg-variant="transparent"
    class="shadow-none mt-2"
  >
    <div class="d-flex justify-content-between">
      <h4>
        Consulta ao Core
      </h4>

      <b-button
        variant="flat-primary"
        class="btn-icon"
        @click="close"
      >
        <feather-icon
          v-if="!isLoading"
          animation="spin-pulse"
          icon="XIcon"
        />
      </b-button>
    </div>

    <div v-if="isLoading">
      <div class="text-center">
        <b-spinner />
      </div>
    </div>
    <div v-else>
      <div v-if="user.coreResponse.error">
        {{ user.coreResponse.error }}
      </div>

      <div v-if="user.coreResponse.registro">
        <b-row>
          <b-col>
            <h5 class="mb-0">
              Core:
            </h5>
            <span class="card-text">{{ user.coreResponse.registro }}</span>
          </b-col>

          <b-col>
            <h5 class="mb-0">
              Nome da Empresa:
            </h5>
            <span class="card-text">{{ user.coreResponse.nome }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col>
            <h5 class="mb-0">
              CPF/CNPJ:
            </h5>
            <span class="card-text">{{ user.coreResponse.cpfCnpj }}</span>
          </b-col>

          <b-col>
            <h5 class="mb-0">
              Conselho ID:
            </h5>
            <span class="card-text">{{ user.coreResponse.conselhoId }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <h5 class="mb-0">
              Estado do Core:
            </h5>
            <span class="card-text">{{ user.coreResponse.core }}</span>
          </b-col>
        </b-row>
      </div>
    </div>

  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'

export default {
  name: 'UserCheckCore',
  components: {
    BSpinner,
    BButton,
    BRow,
    BCol,
    BCard,
  },
  mixins: [],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      userService: null,
    }
  },
  created() {
    this.userService = new UserService()
  },
  methods: {
    close() {
      const newUser = {
        ...this.user,
        coreResponse: null,
      }
      this.$emit('update:user', newUser)
    },

    checkUserByCore() {
      this.isLoading = true
      this.userService.checkRepByCore(this.user.profile.board_registration_core).then(response => {
        if (response.data.length === 0) {
          const newUser = {
            ...this.user,
            coreResponse: {
              error: 'Core não encontrado',
            },
          }
          this.$emit('update:user', newUser)
        } else {
          const newUser = {
            ...this.user,
            coreResponse: response.data[0],
          }
          this.$emit('update:user', newUser)
        }
      }).catch(() => {
        const newUser = {
          ...this.user,
          coreResponse: {
            error: 'Core não encontrado',
          },
        }
        this.$emit('update:user', newUser)
      }).finally(() => {
        this.isLoading = false
      })
    },

    checkUserByCpf() {
      this.isLoading = true
      this.userService.checkRepByCpfCnpj(this.user.cpf).then(response => {
        if (response.data.length === 0) {
          const newUser = {
            ...this.user,
            coreResponse: {
              error: 'CPF não encontrado',
            },
          }
          this.$emit('update:user', newUser)
        } else {
          const newUser = {
            ...this.user,
            coreResponse: response.data[0],
          }
          this.$emit('update:user', newUser)
        }
      }).catch(() => {
        const newUser = {
          ...this.user,
          coreResponse: {
            error: 'CPF não encontrado',
          },
        }
        this.$emit('update:user', newUser)
      }).finally(() => {
        this.isLoading = false
      })
    },

    checkUserByCnpj() {
      this.isLoading = true
      this.userService.checkRepByCpfCnpj(this.user.cnpj).then(response => {
        if (response.data.length === 0) {
          const newUser = {
            ...this.user,
            coreResponse: {
              error: 'CNPJ não encontrado',
            },
          }
          this.$emit('update:user', newUser)
        } else {
          const newUser = {
            ...this.user,
            coreResponse: response.data[0],
          }
          this.$emit('update:user', newUser)
        }
      }).catch(() => {
        const newUser = {
          ...this.user,
          coreResponse: {
            error: 'CNPJ não encontrado',
          },
        }
        this.$emit('update:user', newUser)
      }).finally(() => {
        this.isLoading = false
      })
    },

    checkUserByName() {
      this.isLoading = true
      this.userService.checkRepByName(this.user.name).then(response => {
        if (response.data.length === 0) {
          const newUser = {
            ...this.user,
            coreResponse: {
              error: 'Nome não encontrado',
            },
          }
          this.$emit('update:user', newUser)
        } else {
          const newUser = {
            ...this.user,
            coreResponse: response.data[0],
          }
          this.$emit('update:user', newUser)
        }
      }).catch(() => {
        const newUser = {
          ...this.user,
          coreResponse: {
            error: 'Nome não encontrado',
          },
        }
        this.$emit('update:user', newUser)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
