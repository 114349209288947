<template>
  <div>
    <b-spinner
      v-if="isLoading"
      small
    />
    <div
      v-else
    >
      <b-badge
        v-for="segment in pillSegments"
        :key="segment.id"
        pill
        class="mr-1"
        :variant="!segment.isSub ? 'success' : 'warning'"
      >
        {{ segment.name }}
      </b-badge>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import SegmentService from '@/services/segmentService'
import LocalStorageService from '@/services/localStorageService'
import {
  BSpinner,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BBadge,
  },
  props: {
    segments: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      segmentService: null,
      availableSegments: [],
    }
  },
  computed: {
    pillSegments() {
      return [...new Set(this.segments.map(segment => (
        this.availableSegments.find(f => f.id.toString() === segment.toString())
      )))]
    },
  },
  async created() {
    this.isLoading = true
    this.segmentService = new SegmentService()

    await this.segmentService.getAndStoreSegments()

    const segments = LocalStorageService.getArray(LocalStorageService.segments)

    this.availableSegments = segments.map(segment => ({
      id: segment.id,
      name: segment.name,
      isSub: typeof segment.parent_id === 'number',
    }))

    this.isLoading = false
  },
}
</script>
