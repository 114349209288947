<template>
  <div class="planner-action-history">
    <b-table
      :items="actions"
      :fields="actionFields"
      :tbody-tr-attr="rowAttributes"
      table-class="planner-action-history__table"
    >
      <template #cell(status)="row">
        {{ $t(`planner.action.status.${row.item.status}`) }}
      </template>

      <template #cell(opened_at)="row">
        {{ formatDateTimeDbToView(row.item.opened_at) }}
      </template>

      <template #cell(closed_at)="row">
        {{ formatDateTimeDbToView(row.item.closed_at) }}
      </template>

      <template #cell(actions)="row">
        <b-button
          variant="gradient-primary"
          @click="row.toggleDetails()"
        >
          <feather-icon
            icon="ChevronDownIcon"
          />
        </b-button>
      </template>

      <template #row-details="row">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="row.item.text" />

        <div
          v-if="row.item.goals"
          class="goals mt-1"
        >
          <div
            v-for="goal in row.item.goals"
            :key="goal.id"
            :class="`goal goal--${
              goal.reached_at ? 'reached' : 'not-reached'
            }`"
          >
            <div class="icon">
              <svg
                v-if="goal.reached_at"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 23 24"
              >
                <path
                  fill="currentColor"
                  d="M11.5 23.5a11.5 11.5 0 1 0 0-23 11.5 11.5 0 0 0 0 23Zm5-13.6-5.7 5.7a1 1 0 0 1-1.5 0l-2.9-2.8A1 1 0 0 1 8 11.3l2.2 2 5-5a1 1 0 0 1 1.5 1.5Z"
                />
              </svg>
            </div>
            <div class="text">
              {{ goal.text }}
              <span v-if="goal.reached < goal.goal">
              &nbsp;
                <span v-if="goal.goal - goal.reached !== 1">
                  (Faltam &nbsp;{{ goal.goal - goal.reached }})
                </span>
                <span v-else>
                  (Falta &nbsp;{{ goal.goal - goal.reached }})
                </span>
              </span>
            </div>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import moment from 'moment'
import { BButton, BTable } from 'bootstrap-vue'
import { formatDateTimeDbToView } from '@/utils/helpers'
import difficulties from './difficulties.json'

export default {
  name: 'PlannerActionsHistory',
  components: {
    BButton,
    BTable,
  },
  props: {
    campaign: {
      type: Object,
      default: null,
    },
    userScore: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      actionFields: [
        { key: 'title', label: 'Título' },
        { key: 'status', label: 'Estado' },
        { key: 'opened_at', label: 'Abertura' },
        { key: 'closed_at', label: 'Encerramento' },
        { key: 'actions', label: '', thStyle: 'width: 100px' },
      ],
    }
  },
  computed: {
    actions() {
      return (this.campaign.actions || []).sort((a, b) => moment(b.opened_at).diff(moment(a.opened_at)))
    },
  },
  methods: {
    formatDateTimeDbToView,
    rowAttributes({
      type,
      difficulty,
    }, tableRowType) {
      if (!['row', 'row-details'].includes(tableRowType)) {
        return {}
      }

      const { style } = document.createElement('tr')

      if (type === 'stage') {
        style.setProperty('--background-color', difficulties[difficulty].colors.primary)
        style.setProperty('--color', difficulties[difficulty].colors.primaryText)

        if (tableRowType === 'row-details') {
          style.setProperty('--background-color', difficulties[difficulty].colors.secondary)
          style.setProperty('--color', difficulties[difficulty].colors.secondaryText)
        }
      } else if (type === 'task') {
        style.setProperty('--background-color', difficulties[this.userScore.ranking].colors.secondary)
        style.setProperty('--color', difficulties[this.userScore.ranking].colors.secondaryText)
      }

      return {
        style: style.cssText,
        type,
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.planner-action-history {
  &::v-deep &__table {
    tr[type="stage"],
    tr[type="task"] {
      td {
        background-color: var(--background-color) !important;
        color: var(--color) !important;
      }
    }

    tr[type="stage"] {
      .goals {
        counter-reset: counter-goals;

        --icon-margin: 5px;
        --indicator-size: 18px;

        .goal {
          display: grid;
          align-items: center;
          justify-content: start;
          grid-auto-flow: column;
          margin-top: 5px;

          &.goal {
            counter-increment: counter-goals;
          }

          .icon {
            margin-right: var(--icon-margin);
            color: #01d8ae;

            svg {
              width: var(--indicator-size);
              height: var(--indicator-size);
            }
          }

          &--reached {
            font-weight: 400;
          }

          &--not-reached {
            font-weight: 700;

            .icon {
              --indicator-color: #d71920;

              width: var(--indicator-size);
              height: var(--indicator-size);

              background-color: var(--indicator-color);
              border-radius: 9999px;
              display: grid;
              place-items: center;
              color: white;

              &:before {
                content: counter(counter-goals);
              }
            }
          }
        }
      }
    }
  }
}
</style>
