<template>
  <b-card
    v-if="user.cnpjResponse || isLoading"
    border-variant="primary"
    bg-variant="transparent"
    class="shadow-none mt-2"
  >
    <div class="d-flex justify-content-between">
      <h4>
        Consulta ao CNPJ
      </h4>

      <b-button
        variant="flat-primary"
        class="btn-icon"
        @click="close"
      >
        <feather-icon
          v-if="!isLoading"
          animation="spin-pulse"
          icon="XIcon"
        />
      </b-button>
    </div>

    <div v-if="isLoading">
      <div class="text-center">
        <b-spinner />
      </div>
    </div>

    <div v-else>
      <div v-if="user.cnpjResponse.error">
        {{ user.cnpjResponse.error }}
      </div>

      <div v-if="user.cnpjResponse.success">
        <b-row>
          <b-col
            v-for="(item, index) in Object.entries(user.cnpjResponse).filter(f => f[0] !== 'success')"
            :key="index"
            cols="6"
            class="mt-1"
          >
            <h5 class="mb-0">
              {{ item[0].replace(/\_/g, ' ') }}:
            </h5>
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="card-text"
              v-html="item[1]"
            ></span>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard, BButton, BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'

export default {
  name: 'UserCheckCnpj',
  components: {
    BSpinner,
    BButton,
    BRow,
    BCol,
    BCard,
  },
  mixins: [],
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      userService: null,
    }
  },
  created() {
    this.userService = new UserService()
  },
  methods: {
    close() {
      const newUser = {
        ...this.user,
        cnpjResponse: null,
      }
      this.$emit('update:user', newUser)
    },
    async check() {
      this.isLoading = true
      this.userService.checkCnpj(this.user.cnpj).then(response => {
        if (response.data.error) {
          const newUser = {
            ...this.user,
            cnpjResponse: {
              error: 'CNPJ não encontrado',
            },
          }
          this.$emit('update:user', newUser)
        } else {
          const newUser = {
            ...this.user,
            cnpjResponse: {
              success: true,
              ...response.data,
            },
          }

          if (newUser.cnpjResponse.atividade_principal) {
            newUser.cnpjResponse.atividade_principal = newUser.cnpjResponse.atividade_principal.reduce((carry, item) => `${carry}${item.code} - ${item.text}<BR>`, '')
          }
          if (newUser.cnpjResponse.atividades_secundarias) {
            newUser.cnpjResponse.atividades_secundarias = newUser.cnpjResponse.atividades_secundarias.reduce((carry, item) => `${carry}${item.code} - ${item.text}<BR>`, '')
          }
          if (newUser.cnpjResponse.qsa) {
            newUser.cnpjResponse.qsa = newUser.cnpjResponse.qsa.reduce((carry, item) => `${carry}${item.qual} - ${item.nome}<BR>`, '')
          }

          this.$emit('update:user', newUser)
        }
      }).catch(() => {
        const newUser = {
          ...this.user,
          cnpjResponse: {
            error: 'CNPJ não encontrado',
          },
        }
        this.$emit('update:user', newUser)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
</style>
