<!-- eslint-disable vue/no-v-html -->
<template xmlns:b-row="http://www.w3.org/1999/html">
  <div class="user-list-activities">
    <b-jumbotron
      class="m-0 p-1 rounded-0"
      :bg-variant="skin"
    >
      <b-row>
        <b-col
          :cols="small ? 6 : 4"
        >
          <b-card>
            <b-card-title
              v-b-toggle.filter_collapse
              class="d-flex justify-content-between align-items-center m-0"
            >
              <div>
                Filtros
              </div>
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                class="when-closed"
              />
              <feather-icon
                icon="ChevronUpIcon"
                size="16"
                class="when-open"
              />
            </b-card-title>
            <b-card-body class="p-0">
              <b-collapse
                :id="`filter_collapse`"
              >
                <div class="my-1">
                  <b-form-checkbox
                    v-model="areAllFiltersSelected"
                    switch
                    :disabled="isLoading"
                    @change="onFilterAll"
                  >
                    Todos
                  </b-form-checkbox>
                </div>
                <div
                  v-for="option in filter_options"
                  :key="option.value"
                  class="my-1"
                >
                  <b-form-checkbox
                    v-model="filter_active[option.value]"
                    switch
                    :disabled="isLoading"
                    @change="onFilterChange"
                  >
                    {{ option.text }}
                  </b-form-checkbox>
                </div>
              </b-collapse>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          :cols="small ? 6 : 4"
        >
          <b-card>
            <b-card-title
              v-b-toggle.filter_thrower_collapse
              class="d-flex justify-content-between align-items-center m-0"
            >
              <div>
                Por
              </div>
              <feather-icon
                icon="ChevronDownIcon"
                size="16"
                class="when-closed"
              />
              <feather-icon
                icon="ChevronUpIcon"
                size="16"
                class="when-open"
              />
            </b-card-title>
            <b-card-body class="p-0">
              <b-collapse
                :id="`filter_thrower_collapse`"
              >
                <b-spinner
                  v-if="Object.keys(filter_thrower).length === 0"
                  small
                  label="Carregando..."
                />
                <div v-else>
                  <div class="my-1">
                    <b-form-checkbox
                      v-model="areAllThrowersSelected"
                      switch
                      :disabled="isLoading"
                      @change="onThrowerAll"
                    >
                      Todos
                    </b-form-checkbox>
                  </div>
                  <div
                    v-for="option in filter_thrower_options"
                    :key="option.value"
                    class="my-1"
                  >
                    <b-form-checkbox
                      v-model="filter_thrower[option.value]"
                      switch
                      :disabled="isLoading"
                      @change="onFilterChange"
                    >
                      {{ option.text.trim() === '' ? '-- Vazio/Trigger --' : option.text }}
                    </b-form-checkbox>
                  </div>
                </div>
              </b-collapse>
            </b-card-body>
          </b-card>

        </b-col>
      </b-row>
    </b-jumbotron>

    <b-table
      :busy="isLoading"
      fixed
      show-empty
      :items="slicedActivities"
      :fields="resultFields"
      :tbody-tr-class="rowClass"
      sort-icon-left
    >
      <template #empty>
        <h4 class="text-center mt-2 mb-2">
          Nenhum resultado encontrado
        </h4>
      </template>

      <template #table-busy>
        <div class="text-center table-spinner mt-2 mb-2">
          <b-spinner label="Loading..." />
          <strong>Carregando...</strong>
        </div>
      </template>

      <template
        #cell(created_at)="row"
        variant="info"
      >
        {{ row.item.created_at | date }}
      </template>

      <template
        #row-details="row"
      >
        <user-list-audits
          :user-id="userId"
          :date="row.item.created_at"
        />
      </template>

      <!-- eslint-disable vue/no-v-html -->
      <template #cell(activity)="row">
        <div v-if="row.item.type === 'kanban_column' && card && card.column !== 'finished' && (userData.permission_type === 'root' || card.account_manager_id === userData.id)">
          <div v-if="!loadingCard">
            <div class="d-flex justify-content-between">
              <span v-html="row.item.activity" />
              <b-button
                v-b-toggle="user.settings ? `activities-row-${row.index}` : false"
                size="sm"
                variant="primary"
                title="Ver card"
              >
                <feather-icon
                  v-if="user.settings"
                  icon="ChevronDownIcon"
                  size="16"
                  class="align-middle"
                />
                <b-spinner
                  v-else
                  size="16"
                />
              </b-button>
            </div>
            <b-collapse
              v-if="user.settings"
              :id="`activities-row-${row.index}`"
            >
              <kanban-card
                :data="{...card, user}"
                :users="[user]"
                class="mt-1 cursor-pointer"
                @open-side-bar="openSideBar"
              />
              <b-sidebar
                id="sidebar"
                ref="sidebar"
                bg-variant="white"
                title="Opções do card"
                width="430px"
                :no-close-on-backdrop="preventCloseSidebar"
                :no-close-on-esc="preventCloseSidebar"
                no-header
                lazy
                right
                backdrop
                shadow
              >
                <kanban-card-sidebar
                  name="kanbanCardSidebar"
                  :card="card"
                  :simple="true"
                  @prevent-close-sidebar="changePreventCloseSidebar"
                  @kanban-refresh="refreshCard"
                />
              </b-sidebar>
            </b-collapse>
          </div>
          <b-spinner
            v-else
            size="16"
          />
        </div>
        <div v-else-if="row.item.type === 'affected_by_issue'">
          <span
            v-html="row.item.activity"
          />
          <b-button
            variant="primary"
            size="sm"
            class="mx-1"
            @click="$root.$emit('cms::issue-sidebar', { issue: row.item.extra.issue, open: true })"
          >
            #{{ row.item.extra.issue.id }}
            <feather-icon
              icon="AlignRightIcon"
              size="14"
            />
          </b-button>
          <span>
            Título: {{ row.item.extra.issue.title }}
          </span>
        </div>
        <div v-else-if="row.item.type === 'directly_indicated'">
          <div v-html="row.item.activity" />
          <div>
            Zonas
          </div>
          <zone-pills
            v-if="row.item.extra && row.item.extra.preferred_zones"
            :zones="row.item.extra.preferred_zones"
          />
          <div>
            Segmentos
          </div>
          <segment-pills
            v-if="row.item.extra && row.item.extra.preferred_segments"
            :segments="row.item.extra.preferred_segments"
          />
        </div>
        <div v-else-if="row.item.type === 'user_email_changed'">
          <span v-html="row.item.activity" />
          de
          <b-form-input
            :value="row.item.extra.old || 'Erro ao obter e-mail original'"
            readonly
            size="sm"
            style="opacity: 1"
            class="d-inline-block w-auto"
            @focus.native="$event.target.select()"
          />
          para
          <b-form-input
            :value="row.item.extra.new || 'Erro ao obter e-mail novo'"
            readonly
            size="sm"
            style="opacity: 1"
            class="d-inline-block w-auto"
            @focus.native="$event.target.select()"
          />
        </div>
        <div v-else-if="['system_email', 'individual_email', 'manager_sent_payment_link', 'manager_sent_proposal'].includes(row.item.type)">
          <div class="d-flex justify-content-between">
            <span v-html="row.item.activity" />
            <b-button
              v-if="row.item.type !== 'system_email'"
              size="sm"
              variant="primary"
              title="Ver e-mail"
              @click="showEmail(row.item.extra.email_id)"
            >
              <feather-icon
                icon="SendIcon"
                size="16"
                class="align-middle"
              />
            </b-button>
          </div>
          <div>
            <b-badge
              v-if="row.item.extra && row.item.extra.status"
              class="mr-1"
              :title="formatDateTimeDbToView(row.item.extra.delivered_at)"
              :variant="{
                created: 'primary',
                delivered: 'success',
                sent: 'success',
                error: 'danger',
              }[row.item.extra.status]"
            >
              {{ $t(`emails.columns.status.${row.item.extra.status}`) }}
            </b-badge>

            <b-badge
              v-if="row.item.extra && row.item.extra.mandrill_status"
              class="mr-1"
              :title="formatDateTimeDbToView(row.item.extra.delivered_at)"
              :variant="{
                created: 'primary',
                delivered: 'success',
                sent: 'success',
                error: 'danger',
                reject: 'danger',
              }[row.item.extra.mandrill_status]"
            >
              {{ $t(`emails.columns.mandrill_status.${row.item.extra.mandrill_status}`) }}
            </b-badge>

            <b-badge
              v-if="row.item.extra && row.item.extra.open_at"
              class="mr-1"
              :title="formatDateTimeDbToView(row.item.extra.open_at)"
              variant="info"
            >
              Aberto
            </b-badge>

            <b-badge
              v-if="row.item.extra && row.item.extra.click_at"
              class="mr-1"
              :title="formatDateTimeDbToView(row.item.extra.click_at)"
              variant="info"
            >
              Link Clicado
            </b-badge>
          </div>
        </div>
        <div
          v-else-if="row.item.type === 'save_profile'"
          class="d-flex justify-content-between"
        >
          <span v-html="row.item.activity" />
          <b-button
            size="sm"
            variant="primary"
            @click="openDetails(row)"
          >
            <feather-icon
              icon="ChevronDownIcon"
              size="16"
              class="align-middle text-body"
            />
          </b-button>
        </div>
        <div v-else-if="row.item.type === 'search'">
          <div class="d-flex align-items-center">
            <div>
              Buscou por
            </div>
            <div class="flex-grow-1 mx-1">
              <b-form-input
                :value="getSearchLinkFromActivity(row.item.activity)"
                readonly
                size="sm"
                style="opacity: 1"
                @focus.native="$event.target.select()"
              />
            </div>
            <div>
              <b-button
                v-b-toggle="`activities-row-${row.index}`"
                variant="primary"
                size="sm"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </div>
          <b-collapse :id="`activities-row-${row.index}`">
            <b-table-simple
              small
              stacked
              responsive
            >
              <b-tbody>
                <!-- users: searchParams.getAll('user_id[]'), -->
                <b-tr
                  v-for="(values, key) in parseSearchLink(getSearchLinkFromActivity(row.item.activity))"
                  :key="key"
                >
                  <b-td
                    v-if="key === 'segments' && values.length > 0"
                    stacked-heading="Segmentos"
                  >
                    <segment-pills :segments="values" />
                  </b-td>
                  <b-td
                    v-else-if="key === 'zones' && values.length > 0"
                    stacked-heading="Zonas"
                  >
                    <zone-pills :zones="values" />
                  </b-td>
                  <b-td
                    v-else-if="key === 'optionals' && values.length > 0"
                    stacked-heading="Opcionais"
                  >
                    <div
                      v-for="optional in values"
                      :key="optional"
                    >
                      {{ optionalOptions.find(f => f.value === optional).text }}
                    </div>
                  </b-td>
                  <b-td
                    v-else-if="key === 'differentials' && values.length > 0"
                    stacked-heading="Diferenciais"
                  >
                    <div
                      v-for="differential in values"
                      :key="differential"
                    >
                      {{ diferentialOptions.find(f => f.value === differential).text }}
                    </div>
                  </b-td>
                  <b-td
                    v-else-if="key === 'tags' && values.length > 0"
                    stacked-heading="Palavra chave"
                  >
                    <div
                      v-for="tag in values"
                      :key="tag"
                    >
                      {{ tag }}
                    </div>
                  </b-td>
                  <b-td
                    v-else-if="key === 'page' && values.length > 0"
                    stacked-heading="Página"
                  >
                    <div
                      v-for="page in values"
                      :key="page"
                    >
                      {{ page }}
                    </div>
                  </b-td>
                  <b-td
                    v-else-if="key === 'order' && values.length > 0"
                    stacked-heading="Ordenado por"
                  >
                    <div
                      v-for="order in values"
                      :key="order"
                    >
                      {{ orderByOptions.find(f => f.value === order).text }}
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-collapse>
        </div>
        <div v-else-if="row.item.type === 'reported_user'">
          <div class="d-flex align-items-center justify-content-between">
            <div>{{ row.item.activity }}</div>
            <div>
              <b-button
                v-b-toggle="`activities-row-${row.index}`"
                variant="primary"
                size="sm"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </b-button>
            </div>
          </div>
          <b-collapse :id="`activities-row-${row.index}`">
            <b-card
              no-body
              class="mt-1 p-0"
              :bg-variant="skin"
            >
              <b-card-body
                class="d-flex flex-column align-items-center"
              >
                <b-avatar
                  size="40px"
                  :src="row.item.extra.avatar ? row.item.extra.avatar.sm : ''"
                  variant="light-success"
                  class="mb-1"
                  :to="{ name: 'cms-user-form-update', params: { id: row.item.extra.id } }"
                  target="_blank"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="22"
                  />
                </b-avatar>
                <div class="pb-1 border-bottom-1">
                  {{ row.item.extra.name }}
                </div>
                <p class="m-0">
                  {{ row.item.extra.reason }}
                </p>
              </b-card-body>
            </b-card>
          </b-collapse>
        </div>
        <span
          v-else
          v-html="row.item.activity"
        />
      </template>

      <template #cell(small)="row">
        <span>{{ row.item.created_at | date }}</span><br>
        <span v-html="row.item.thrower" /><br>
        <span v-html="row.item.activity" />
      </template>
      <!-- eslint-enable vue/no-v-html -->

      <template #head(created_at)>
        Criado em
      </template>

      <template #head(thrower)>
        Por
      </template>

      <template #head(activity)>
        Descrição
      </template>

      <template #head(small)>
        Atividades
      </template>
    </b-table>

    <b-row>
      <b-col cols="12">
        <!-- pagination nav -->
        <b-pagination-nav
          v-model="currentPage"
          :number-of-pages="lastPage"
          :link-gen="linkGen"
          align="end"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-email"
      ref="modal-email"
      content-class="shadow"
      title="E-mail"
      no-fade
      ok-only
      ok-title="Fechar"
    >
      <b-card-text>
        <div
          v-if="isLoadingEmail"
          class="text-center"
        >
          <b-spinner
            label="Carregando..."
          />
        </div>
        <div v-if="!isLoadingEmail">
          <p class="font-weight-bolder">
            {{ email.subject }}
          </p>

          <!-- eslint-disable vue/no-v-html -->
          <p v-html="email.message" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import KanbanService from '@/services/kanbanService'
import UserService from '@/services/userService'
import EmailService from '@/services/emailService'
import { formatDateTimeDbToView } from '@/utils/helpers'
import {
  BCardTitle,
  BFormCheckbox,
  BBadge,
  BCardText,
  BTable,
  BCol,
  BRow,
  BFormInput,
  BSpinner,
  BButton,
  BCollapse,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BPaginationNav,
  BJumbotron,
  BCard,
  BCardBody,
  BSidebar,
  BAvatar,
  VBToggle,
} from 'bootstrap-vue'
import ZonePills from '@/views/cms/components/ZonePills.vue'
import SegmentPills from '@/views/cms/components/SegmentPills.vue'
import UserListAudits from '@/views/cms/components/UserListAudits.vue'
import {
  orderByOptions,
  optionalOptions,
  diferentialOptions,
} from '@/utils/options'
import KanbanCard from '@/views/cms/kanban/Card.vue'
import KanbanCardSidebar from '@/views/cms/kanban/Sidebar.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import requestErrorMessage from '../../../mixins/requestErrorMessage'

export default {
  components: {
    BCardTitle,
    BFormCheckbox,
    BBadge,
    BCardText,
    BTable,
    BCol,
    BRow,
    BFormInput,
    BSpinner,
    BButton,
    BCollapse,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BPaginationNav,
    BJumbotron,
    BCard,
    BCardBody,
    BSidebar,
    BAvatar,
    ZonePills,
    SegmentPills,
    UserListAudits,
    KanbanCard,
    KanbanCardSidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [requestErrorMessage],

  props: {
    user: {
      type: Object,
      default: null,
    },
    userId: {
      type: Number,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const filterOptions = [
      { value: 'kanban', text: 'Kanban' },
      { value: 'update', text: 'Atualização' },
      { value: 'search', text: 'Busca' },
      { value: 'email', text: 'E-mail' },
      { value: 'financial', text: 'Financeiro' },
      { value: 'issue', text: 'Chamado' },
      { value: 'planner', text: 'Planejador' },
      { value: 'contact', text: 'Contato' },
    ]

    return {
      currentPage: 1,
      isLoading: true,
      isLoadingEmail: false,
      card: null,
      userData: {
        id: null,
        name: null,
        permission_type: null,
      },
      emailService: null,
      email: {
        subject: '',
        message: '',
      },
      kanbanService: null,
      initExecuted: false,
      showingAnnotation: false,
      activities: [],
      pages: {
        last_page: 1,
      },
      results: [],
      annotation: {},
      filter_options: filterOptions,
      filter_active: filterOptions.reduce((obj, option) => ({ ...obj, [option.value]: true }), {}),

      filter_thrower: {},

      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      formatDateTimeDbToView,

      orderByOptions,
      optionalOptions,
      diferentialOptions,

      preventCloseSidebar: false,
      managers: [],
      loadingCard: true,
    }
  },

  computed: {
    filter_thrower_options() {
      return [
        ...new Set(this.activities.map(a => a.thrower.name).sort()),
      ].map(t => ({ value: t, text: t }))
    },
    resultFields() {
      if (this.small) {
        return [
          { key: 'small', sortable: false },
        ]
      }

      return [
        { key: 'created_at', sortable: false, thStyle: 'width: 200px' },
        { key: 'thrower', sortable: false, thStyle: 'width: 200px' },
        { key: 'activity', sortable: false },
      ]
    },

    lastPage() {
      const pages = Math.ceil(this.filteredActivities.length / 30)
      return pages > 0 ? pages : 1
    },

    slicedActivities() {
      return this.filteredActivities.slice((this.currentPage - 1) * 30, (this.currentPage) * 30)
    },

    getActualKanbanColumn() {
      if (this.card) {
        let role = this.$t(`roles.${this.card.role}`)
        let column = this.$t(`kanban.column.${this.card.column}`)
        let hidden = this.card.hide_until && (new Date(this.card.hide_until)) > (new Date()) ? `Omitido até ${this.formatDateTimeDbToView(this.card.hide_until)}` : ''

        if (this.card.role === 'relationship') {
          role = 'A&Q'

          if (this.card.column === 'follow_up') {
            column = 'Follow Onboarding'
          }
        }

        if (this.card.role === 'success') {
          if (this.hasNoActivePlanForAtLeastFiveDays() || this.user.canceled_at !== null || this.user.quotas?.canceled_at !== null) {
            column = 'Churn/Cancelamento'
          } else if (this.card.hide_until && (new Date(this.card.hide_until)) > (new Date())) {
            column = 'Agendamentos futuros'
            hidden = ''
          } else if (this.card.column === 'new_subscriber') {
            column = 'Apresentação CMS'
          } else if (this.card.column === 'follow_up') {
            column = 'Follow CSM'
          }
        }

        let manager = this.managers.find(f => f.id === this.card.account_manager_id)
        if (manager) {
          manager = ` (com ${manager.name})`
        }

        return `Card atualmente em: ${role} - ${column}${manager}. ${hidden}`
      }
      return ''
    },

    filteredActivities() {
      const allFiltersAreChecked = Object.values(this.filter_active).every(g => g)
      const allThrowersAreChecked = Object.values(this.filter_thrower).every(g => g)

      const activities = this.activities.filter(f => (
        allFiltersAreChecked
          || this.filter_active[f.filter]
          || (
            this.filter_active.financial && f.type && f.type.includes('link')
          )
      ) && (
        allThrowersAreChecked
          || this.filter_thrower[f.thrower.name]
      ))

      if (this.filter_active.kanban) {
        let dt = new Date()
        dt.setHours(dt.getHours() - 3)
        dt = dt.toISOString().replace('Z', '').replace('T', ' ')
        activities.unshift({
          sales_last_try: dt.sales_last_try,
          date: dt.substring(0, dt.length - 4),
          description: this.getActualKanbanColumn,
          extra: '',
          variant: 'info',
          filter: 'kanban',
          type: 'kanban_column',
          id: 0,
          thrower: { name: 'Sistema', avatar: '' },
          user_id: 489,
          who_trigger_that: 'system',
        })
      }

      return activities.map(m => {
        const { type, extra } = m
        let thrower = m.thrower.name ?? ''
        if (m.who_trigger_that === 'own_user' && thrower === '') {
          thrower = this.user?.name
        }
        let activity = ''

        if (m.filter !== 'kanban') {
          switch (type) {
            case 'system_email':
              activity = 'Enviou um e-mail'
              break
            case 'individual_email':
              activity = 'Enviou um e-mail'
              break
            case 'search':
              activity = m.description
              break
            case 'registration_approved':
              activity = 'Registro aprovado'
              break
            case 'forgot_password':
              activity = 'Solicitou uma nova senha'
              break
            case 'registration_reviewed':
            case 'registration_active_reviewed':
              activity = `Cadastro revisado. ${m.description}`
              break
            case 'save_profile':
              activity = m.description ? m.description : 'Alterou o perfil.'
              break
            case 'page_checkout_without_plan':
              activity = 'Acessou a página de pagamentos sem ter plano'
              break
            case 'page_plans':
              activity = 'Acessou a página de planos'
              break
            case 'login':
              activity = 'Logou'
              break
            case 'tried_view_phone':
              activity = `${m.description}. Usuário ID: ${extra && extra.user_id ? extra.user_id : ''}`
              break
            case 'view_phone':
              activity = `${m.description}. Usuário ID: ${extra && extra.user_id ? extra.user_id : ''}`
              break
            case 'manager_contact_attempt':
              activity = `${m.description}. Tentativa de contato`
              break
            case 'payment_approved_manually':
              activity = `${m.description}. Pagamento aprovado manualmente`
              break
            case 'payment_change_manager':
              activity = `${m.description}. Consultor alterado manualmente`
              break
            case 'end_of_plan':
              activity = `${m.description}. Alteração da data final do plano`
              break
            case 'subscription_canceled':
              activity = `${m.description}. Assinatura cancelada`
              break
            case 'manager_created_payment_link':
              activity = `Link de pagamento gerado ${m.description}`
              break
            case 'manager_sent_payment_link':
              activity = `Link de pagamento enviado. ${m.description}`
              break
            case 'manager_sent_proposal':
              activity = `Proposta enviada. ${m.description}`
              break
            case 'plan_quotas_updated':
              activity = `${m.description}. Plano atualizado`
              break
            case 'user_status_blocked':
              activity = `${m.description}. Usuário bloqueado`
              break
            case 'kanban_card_created':
              activity = `${m.description}. Card criado no Kanban`
              break
            case 'kanban_forced_assign':
              activity = `Atribuição forçada. ${m.description}`
              break
            case 'kanban_onboarding_tour':
              activity = `Tour realizado. ${m.description}`
              break
            case 'kanban_onboarding_vip':
              activity = `Canal VIP realizado. ${m.description}`
              break
            case 'user_status_changed':
              activity = `${m.description}`
              break
            case 'messages_migrate':
              activity = `${m.description}.`
              break
            case 'user_migrate':
              activity = `${m.description}.`
              break
            case 'manager_sent_internal_search':
              activity = `${m.description} (ID da busca: ${extra.search})`
              break
            default:
              activity = m.description
              thrower = m.thrower.name ?? ''
              break
          }

          if (extra && extra.subject) {
            activity = `${activity}<BR>Assunto: ${extra.subject}`
            if (extra && extra.to) {
              activity = `${activity}<BR>Destinatário: ${extra.to}`
            }
          }
        } else if (m.filter === 'kanban') {
          if (m.type === 'kanban_column') {
            activity = m.description
          }

          if (m.action) {
            const action = this.$t(`kanban.action.${m.action}`)
            const column = m.column ? this.$t(`kanban.column.${m.column}`) : ''
            const role = m.role ? this.$t(`roles.${m.role}`) : ''
            activity = `Card ${action}`
            if (m.column) {
              activity = `${activity} movido para coluna <strong>${column}</strong> `
              if (role) {
                activity = `${activity} do quadro <strong>${role}</strong>`
              }
            }

            if (m.card && m.card.role === 'sales' && ['follow_up', 'following'].includes(m.card.column) && m.last_move_action) {
              if (m.last_move_action === 'contacted') {
                activity = `${activity}<br><strong>Contato efetuado</strong>`
              } else {
                activity = `${activity}<br><strong>Sem contato</strong>`
              }
            }

            if (m.schedule_at) {
              activity = `${activity} e agendado para ${this.formatDateTimeDbToView(m.schedule_at)}`
            }

            if (m.sales_inactive_manager_migration_at && m.manager) {
              activity = `${activity}<br>Migrado para ${m.manager.name} após inatividade na coluna de novos leads`
            }

            if (m.success_follow_up_tries) {
              activity = `${activity}<br>${m.success_follow_up_tries}ª Tentativa de contato para acompanhamento`
              if (m.success_follow_up_last_try) {
                activity = `${activity} em ${this.formatDateTimeDbToView(m.success_follow_up_last_try)}`
              }
            }

            if (m.relationship_schedule_tries) {
              activity = `${activity}<br>${m.relationship_schedule_tries}ª Tentativa de contato para marcar onboarding`
              if (m.relationship_schedule_last_try) {
                activity = `${activity} em ${this.formatDateTimeDbToView(m.relationship_schedule_last_try)}`
              }
            }

            if (m.relationship_follow_up_tries) {
              activity = `${activity}<br>${m.relationship_follow_up_tries}º Retorno ao Acompanhamento`
              if (m.relationship_follow_up_at) {
                activity = `${activity} em ${this.formatDateTimeDbToView(m.relationship_follow_up_at)}`
              }
            }

            if (m.relationship_onboarding_contact_made) {
              activity = `${activity} <br><strong>Contato realizado com sucesso! Mais detalhes nas anotações</strong>`
            }

            if (m.hide_until) {
              activity = `${activity}<br>Omitiu o card até ${this.formatDateTimeDbToView(m.hide_until)} `
            }

            if (m.description) {
              activity = `${activity} <br><br><strong>Descrição</strong><br> ${m.description}`
            }

            // activity = `${type} - ${m.filter} - ${activity} <br><br> ${JSON.stringify(m)}`
          }
        }

        return {
          created_at: m.date,
          thrower,
          activity,
          type,
          extra,
        }
      })
    },
    areAllFiltersSelected: {
      get() {
        return Object.entries(this.filter_active).filter(f => f[1]).length === Object.entries(this.filter_active).length
      },
      set() {
        return Object.entries(this.filter_active).filter(f => f[1]).length === Object.entries(this.filter_active).length
      },
    },
    areAllThrowersSelected: {
      get() {
        return Object.entries(this.filter_thrower).filter(f => f[1]).length === Object.entries(this.filter_thrower).length
      },
      set() {
        return Object.entries(this.filter_thrower).filter(f => f[1]).length === Object.entries(this.filter_thrower).length
      },
    },
  },

  watch: {
  },

  setup() {
    const { skin } = useAppConfig()

    return { skin }
  },

  async created() {
    this.kanbanService = new KanbanService()
    this.userService = new UserService()
    this.emailService = new EmailService()

    this.managers = await this.userService.getAndStoreManagers()

    this.userData = JSON.parse(localStorage.getItem('userData'))

    this.getKanbanCard()
    await this.getActivities()

    this.filter_thrower = this.filter_thrower_options.reduce((obj, option) => ({ ...obj, [option.value]: true }), {})
  },

  methods: {
    changePreventCloseSidebar(event) {
      this.preventCloseSidebar = event
    },
    openSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar')
    },

    hasActivePlan() {
      return new Date() < new Date(this.user.quotas?.end_of_plan || 0)
    },

    hasNoActivePlanForAtLeastFiveDays() {
      const today = new Date()

      const fiveDaysAgo = new Date()
      fiveDaysAgo.setDate(today.getDate() - 5)

      return (
        this.user?.quotas?.end_of_plan
        && (new Date(this.user.quotas?.end_of_plan)) < fiveDaysAgo
      )
    },

    linkGen() {
      return '#'
    },

    rowClass(item) {
      if (item && item.type === 'kanban_column') {
        return 'bg-success table-success'
      }

      return ''
    },

    showEmail(id) {
      this.isLoadingEmail = true

      this.emailService.getEmail(id).then(response => {
        this.isLoadingEmail = false
        this.email = response.data.data.email
      })

      this.$refs['modal-email'].show()
    },

    init() {
      if (!this.initExecuted) {
        this.getActivities()
        this.initExecuted = true
      }
    },

    getSearchLinkFromActivity(activity) {
      return activity.replace('Buscou por ', '')
        .replace(/,/g, '&')
        .replace('/buscar?', '/busca?')
        .replace('page[]', 'page')
        .replace('order_by[]', 'order_by')
        .replace('tags[]', 'tags')
        .replace('type[]', 'type')
    },

    parseSearchLink(link) {
      const searchParams = new URLSearchParams(link.replace(/.*\?/, ''))

      return {
        users: searchParams.getAll('user_id[]'),
        segments: searchParams.getAll('preferred_segments[]'),
        zones: searchParams.getAll('preferred_zones[]'),
        page: searchParams.getAll('page'),
        order: searchParams.getAll('order_by'),
        tags: searchParams.getAll('tags'),
        type: searchParams.getAll('type'),
        optionals: searchParams.getAll('optionals[]'),
        differentials: searchParams.getAll('differentials[]'),
      }
    },

    getKanbanCard() {
      this.loadingCard = true
      this.kanbanService.getKanbanCard(this.userId).then(response => {
        this.card = response.data.data.card
        this.loadingCard = false
      })
    },

    refreshCard() {
      this.card = null
      this.getKanbanCard()
    },

    openDetails(row) {
      row.toggleDetails()
    },

    getActivities() {
      this.isLoading = true
      return this.userService.listActivities(this.userId).then(response => {
        if (response.status === 200) {
          this.activities = response.data.data.activities
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    onFilterAll(e) {
      this.currentPage = 1
      this.filter_active = Object.fromEntries(Object.entries(this.filter_active).map(f => [f[0], e]))
    },
    onThrowerAll(e) {
      this.currentPage = 1
      this.filter_thrower = Object.fromEntries(Object.entries(this.filter_thrower).map(f => [f[0], e]))
    },
    onFilterChange() {
      this.currentPage = 1
      this.activities = [...this.activities]
    },
  },
}
</script>

<style lang="scss">
  .user-list-activities{
    .b-table{
      td{
        padding: 0.8rem 1rem;

        .dark-layout & {
          color: white;
        }
      }
    }
  }
</style>

<style scoped>
  .text-danger{
    min-height: 20px;
    display: block;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }
</style>
