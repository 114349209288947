<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-spinner />
      <h4 class="mt-1">
        Carregando usuário
      </h4>
    </div>
    <div v-else>

      <div class="text-center">
        <b-avatar
          size="100px"
          :src="user.avatar ? user.avatar.sm : ''"
          variant="light-success"
        >
          <feather-icon
            icon="UserIcon"
            size="40"
          />
        </b-avatar>
        <h4
          class="mt-1"
        >
          {{ user.name }}
        </h4>
        <p class="text-break">
          <small>
            <feather-icon
              icon="MapPinIcon"
              class="d-inline-block"
            />
            {{ user.city }} - {{ user.state }}
          </small>
        </p>

      </div>

      <div>
        <hr class="mt-2 mb-1">

        <div class="mb-1 font-weight-bolder text-dark">
          <span v-if="user.type === 'company'">Apresentação</span>
          <span v-else>Sobre mim</span>
        </div>
        <p class="text-break">
          {{ userFetched.presentation }}
        </p>
      </div>

      <div
        v-if="user.type === 'company' && userFetched.details && userFetched.details.catalog_url !== ''"
      >
        <hr class="mt-2 mb-1">

        <div class="mb-1 font-weight-bolder text-dark">
          Catálogo
        </div>
        <p>
          <b-button
            variant="gradient-primary"
            :href="userFetched.details.catalog_url"
            target="_blank"
          >
            Baixar catálogo
          </b-button>
        </p>
      </div>

      <div v-if="preferred_zones.states.length || preferred_zones.states.length">
        <hr class="mt-2 mb-1">

        <div class="mb-1 font-weight-bolder text-dark">
          <span v-if="user.type === 'company'">Regiões onde buscamos vender mais</span>
          <span v-else>Regiões de Atuação</span>
        </div>

        <div
          v-for="state in preferred_zones.states"
          :key="state.initials"
          class="mb-1"
        >
          <b-form-checkbox
            disabled
            :checked="true"
          >
            {{ state.name }}
          </b-form-checkbox>
        </div>
        <div>
          <b-badge
            v-for="zone in preferred_zones.zones"
            :key="zone.id"
            pill
            variant="success"
            class="mb-1 mr-1"
          >
            {{ zone.name }}
          </b-badge>
        </div>
      </div>

      <div v-if="preferred_segments.length">
        <hr class="mt-2 mb-1">

        <div class="mb-1 font-weight-bolder text-dark">
          Segmentos de Atuação
        </div>

        <div
          v-for="segment in preferred_segments"
          :key="segment.id"
          class="mb-1"
        >
          <div
            class="mb-1"
          >
            <b-form-checkbox
              disabled
              :checked="true"
            >
              {{ segment.name }}
            </b-form-checkbox>
          </div>
          <b-badge
            v-for="subsegment in preferred_subsegments[segment.id]"
            :key="subsegment.id"
            pill
            variant="success"
            class="mb-1 mr-1"
          >
            {{ subsegment.name }}
          </b-badge>
        </div>
      </div>

      <div v-if="user.type === 'representative'">
        <div v-if="userFetched.differentials">
          <hr class="mt-2 mb-1">

          <div class="mb-1 font-weight-bolder text-dark">
            Diferenciais
          </div>
          <p v-if="userFetched.differentials.board_registration_core">
            <b-badge
              pill
              variant="success"
              class="text-break"
            >
              Registro no Conselho: {{ userFetched.differentials.board_registration_core }}
            </b-badge>
          </p>
          <p v-if="userFetched.differentials.company_constituted">
            <b-badge
              pill
              variant="success"
              class="text-break"
            >
              Empresa constituida {{ userFetched.differentials.company_constituted !== '' ? ': '+userFetched.differentials.company_constituted : '' }}
            </b-badge>
          </p>
          <p v-if="userFetched.differentials.has_own_vehicle">
            <b-badge
              pill
              variant="success"
              class="text-break"
            >
              Veículo próprio
            </b-badge>
          </p>
          <p v-if="userFetched.differentials.has_a_customer_portfolio">
            <b-badge
              pill
              variant="success"
              class="text-break"
            >
              Cartela de Clientes própria
            </b-badge>
          </p>
          <p v-if="userFetched.differentials.representation_experience">
            <b-badge
              pill
              variant="success"
              class="text-break"
            >
              {{ userFetched.differentials.representation_experience }}
            </b-badge>
          </p>

          <div v-if="userFetched.differentials.represented_products && userFetched.differentials.represented_products.length">
            <hr class="mt-2 mb-1">

            <div class="mb-1 font-weight-bolder text-dark">
              Produtos que já representei
            </div>

            <div>
              <b-badge
                v-for="product in userFetched.differentials.represented_products"
                :key="product"
                pill
                variant="secondary"
                class="text-break mr-1 mb-1"
              >
                {{ product }}
              </b-badge>
            </div>
          </div>
          <div v-if="userFetched.differentials.products_want_to_represent && userFetched.differentials.products_want_to_represent.length">
            <hr class="mt-2 mb-1">

            <div class="mb-1 font-weight-bolder text-dark">
              Produtos que desejo representar
            </div>

            <div>
              <b-badge
                v-for="product in userFetched.differentials.products_want_to_represent"
                :key="product"
                pill
                variant="secondary"
                class="text-break mr-1 mb-1"
              >
                {{ product }}
              </b-badge>
            </div>
          </div>
          <div v-if="userFetched.differentials.where_did_working && userFetched.differentials.where_did_working.length">
            <hr class="mt-2 mb-1">

            <div class="mb-1 font-weight-bolder text-dark">
              Marcas e Empresas que fazem parte da minha história
            </div>

            <div>
              <b-badge
                v-for="workplace in userFetched.differentials.where_did_working"
                :key="workplace"
                pill
                variant="secondary"
                class="text-break mr-1 mb-1"
              >
                {{ workplace }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="userFetched.type === 'company'"
      >
        <div v-if="userFetched.details.products && userFetched.details.products.length">
          <div>
            <hr class="mt-2 mb-1">

            <div class="mb-1 font-weight-bolder text-dark">
              Produtos
            </div>

            <div>
              <b-badge
                v-for="product in userFetched.details.products"
                :key="product"
                pill
                variant="secondary"
                class="text-break mr-1 mb-1"
              >
                {{ product }}
              </b-badge>
            </div>
          </div>
        </div>
        <div v-if="userFetched.details.company_brands && userFetched.details.company_brands.length">
          <div>
            <hr class="mt-2 mb-1">

            <div class="mb-1 font-weight-bolder text-dark">
              Marcas
            </div>

            <div>
              <b-badge
                v-for="brand in userFetched.details.company_brands"
                :key="brand"
                pill
                variant="secondary"
                class="text-break mr-1 mb-1"
              >
                {{ brand }}
              </b-badge>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="userFetched.main_customers && userFetched.main_customers.length && userFetched.main_customers.filter(f => f && f.trim().length > 0).length"
      >
        <hr class="mt-2 mb-1">

        <div class="mb-1 font-weight-bolder text-dark">
          Principais clientes ativos
        </div>
        <div>
          <b-badge
            v-for="customer in userFetched.main_customers.filter(f => f && f.trim().length > 0)"
            :key="customer"
            pill
            variant="secondary"
            class="text-break mr-1 mb-1"
          >
            {{ customer }}
          </b-badge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BAvatar,
  BBadge,
  BButton,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'
import LocalStorageService from '@/services/localStorageService'

export default {
  components: {
    BAvatar,
    BBadge,
    BButton,
    BFormCheckbox,
    BSpinner,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userFetched: null,
      isLoading: true,
      userService: null,
      preferred_zones: [],
      preferred_segments: [],
      preferred_subsegments: {},
    }
  },
  async created() {
    this.userService = new UserService()

    const states = [...LocalStorageService.getArray(LocalStorageService.zones)].filter(state => (
      this.user.preferred_zones.includes(state.initials) || this.user.preferred_zones.includes(`${state.initials}-ALL`)
    ))
    const zones = states.reduce((carry, state) => [...carry, ...state.zones.filter(zone => (
      this.user.preferred_zones.includes(zone.id) || this.user.preferred_zones.includes(`${zone.id}-ALL`)
    ))], [])

    this.preferred_zones = {
      states,
      zones,
    }

    this.user.preferred_segments
      .forEach(s => {
        const segment = [...LocalStorageService.getArray(LocalStorageService.segments)].find(f => f.id.toString() === s.toString())

        if (!segment.parent_id) {
          this.preferred_segments = [
            ...this.preferred_segments,
            segment,
          ]

          return
        }

        this.preferred_subsegments[segment.parent_id] = [
          ...this.preferred_subsegments[segment.parent_id] || [],
          segment,
        ]
      })

    await this.userService.peloId(this.user.id)
      .then(response => {
        this.userFetched = response.data.data.user
      })
      .finally(() => {
        this.isLoading = false
      })
  },
}
</script>
