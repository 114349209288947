<template>
  <div v-if="['individual_email', 'manager_sent_payment_link', 'manager_sent_proposal'].includes(item.type)">
    <div>
      Enviou um e-mail
    </div>
    <div v-if="item.extra && item.extra.subject">
      Assunto: {{ item.extra.subject }}
    </div>
    <div>
      <b-badge
        v-if="item.extra && item.extra.status"
        class="mr-1"
        :title="formatDateTimeDbToView(item.extra.delivered_at)"
        :variant="{
          created: 'primary',
          delivered: 'success',
          sent: 'success',
          error: 'danger',
        }[item.extra.status]"
      >
        {{ $t(`emails.columns.status.${item.extra.status}`) }}
      </b-badge>

      <b-badge
        v-if="item.extra && item.extra.mandrill_status"
        class="mr-1"
        :title="formatDateTimeDbToView(item.extra.delivered_at)"
        :variant="{
          created: 'primary',
          delivered: 'success',
          sent: 'success',
          error: 'danger',
          reject: 'danger',
        }[item.extra.mandrill_status]"
      >
        {{ $t(`emails.columns.mandrill_status.${item.extra.mandrill_status}`) }}
      </b-badge>

      <b-badge
        v-if="item.extra && item.extra.open_at"
        class="mr-1"
        :title="formatDateTimeDbToView(item.extra.open_at)"
        variant="info"
      >
        Aberto
      </b-badge>

      <b-badge
        v-if="item.extra && item.extra.click_at"
        class="mr-1"
        :title="formatDateTimeDbToView(item.extra.click_at)"
        variant="info"
      >
        Link Clicado
      </b-badge>
    </div>
  </div>
  <div v-else-if="item.type === 'manager_sent_internal_search'">
    {{ item.annotation }} <span v-if="item.extra && item.extra.search">(ID da busca: {{ item.extra.search }})</span>
  </div>
  <div v-else-if="item.type === 'directly_indicated'">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="item.annotation" />
    <!-- eslint-enable vue/no-v-html -->

    <div>
      Zonas
    </div>
    <zone-pills
      v-if="item.extra.preferred_zones"
      :zones="item.extra.preferred_zones"
    />
    <div>
      Segmentos
    </div>
    <segment-pills
      v-if="item.extra.preferred_segments"
      :segments="item.extra.preferred_segments"
    />
  </div>
  <div v-else>
    {{ item.annotation }}
  </div>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import ZonePills from '@/views/cms/components/ZonePills.vue'
import SegmentPills from '@/views/cms/components/SegmentPills.vue'
import { formatDateTimeDbToView } from '@/utils/helpers'

export default {
  components: {
    BBadge,
    ZonePills,
    SegmentPills,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    formatDateTimeDbToView,
  }),
}
</script>
