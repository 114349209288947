<template>
  <div>
    <b-card-actions
      ref="objectiveResults"
      title="Planejador"
      class="form-filters"
      :action-close="false"
      :action-refresh="true"
      :action-collapse="false"
      @refresh="getObjectives()"
    >
      <div v-if="diffs === null" />
      <div v-else-if="!diffs || diffs.length < 1">
        Nenhum resultado encontrado.
      </div>
      <div v-else>
        <b-row>
          <b-col cols="6">
            <b-form-select
              v-model="selectedIndex"
              :options="objectiveOptions"
              class="mb-1"
            />
          </b-col>
          <b-col
            cols="6"
            class="text-left"
          >
            <b-button
              size="sm mr-1"
              :disabled="selectedIndex === objectiveOptions.length - 1"
              @click="previous()"
            >
              Anterior
            </b-button>
            <b-button
              size="sm mr-1"
              :disabled="selectedIndex === 0"
              @click="next()"
            >
              Próximo
            </b-button>
            <b-button
              size="sm mr-1"
              :disabled="selectedIndex === 0"
              @click="last()"
            >
              Último
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <h3>Nome da Estratégia: {{ objectiveOptions[selectedIndex].name }}</h3>
          </b-col>
        </b-row>

        <b-table
          responsive
          :items="currentDiff"
          :fields="['key', 'value']"
          show-empty
          no-local-sorting
        >
          <template #empty>
            <div class="text-center mt-3 mb-3">
              <span>Nenhum histórico disponível</span>
            </div>
          </template>

          <template #head(key)>
            Campo
          </template>

          <template #head(value)>
            Alteração
          </template>

          <template v-slot:cell(value)="data">
            <span :class="{ 'text-success': data.item.changed }">
              <feather-icon
                v-if="data.item.changed"
                icon="EditIcon"
                size="18"
              />
              {{ data.item.value }}
            </span>
          </template>
        </b-table>

        <div>
          <h4>
            Campanhas
          </h4>
          <b-tabs>
            <b-tab
              v-for="campaign in objectives[selectedIndex].campaigns"
              :key="campaign.id"
            >
              <template #title>
                Campanha {{ campaign.campaign.toString().padStart(2, '0') }}
                &nbsp;
                <b-badge
                  :variant="campaign.deleted_at ? 'danger' : 'success'"
                >
                  {{ campaign.deleted_at ? 'Encerrada' : 'Aberta' }}
                </b-badge>
              </template>
              <b-table
                responsive
                :items="[
                  { label: 'Cobertura', key: 'coverage' },
                  { label: 'Posições', key: 'positions' },
                  ...campaign.closing_reason ? [{ label: 'Motivo do encerramento', key: 'reason' }] : [],
                ]"
                show-empty
                no-local-sorting
                thead-class="hidden"
              >
                <template v-slot:cell(key)="data">
                  <div v-if="data.item.key === 'coverage'">
                    <strong>
                      {{ campaign.state }}:
                      <strong v-if="!campaign.zone_id">
                        Todo o estado
                      </strong>
                      <strong v-else>
                        {{ campaign.zone.name }}
                      </strong>
                    </strong>
                  </div>
                  <div v-else-if="data.item.key === 'positions'">
                    <strong>
                      {{ campaign.vacancies }}
                    </strong>
                  </div>
                  <div v-else-if="data.item.key === 'reason'">
                    <strong>
                      {{ $t(`planner.campaign.closing_reason.${campaign.closing_reason}`) }}
                    </strong>
                    <div>
                      {{ campaign.closing_comment }}
                    </div>
                  </div>
                </template>
              </b-table>

              <planner-actions-history
                :campaign="campaign"
                :user-score="userScore"
              />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-card-actions>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormSelect,
  BButton,
  BTable,
  BBadge,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import PlannerService from '@/services/plannerService'
import { formatDateDbToView } from '@/utils/helpers'
import PlannerActionsHistory from './PlannerActionsHistory.vue'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BFormSelect,
    BButton,
    BTable,
    BBadge,
    BTabs,
    BTab,
    PlannerActionsHistory,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
      objectives: null,
      diffs: null,
      userScore: null,
    }
  },

  computed: {
    objectiveOptions() {
      return (this.objectives || []).map((objective, index) => ({
        value: index,
        text: `${formatDateDbToView(objective.created_at)} - ${objective.name}`,
        name: objective.name,
      }))
    },

    currentDiff() {
      const fieldsChanged = Object.keys(this.diffs[this.selectedIndex])
      const ignoreFields = [
        'id',
        'user_id',
        'name',
        'deleted_at',
        'created_at',
        'updated_at',
        'campaigns',
      ]
      return Object.entries(this.objectives[this.selectedIndex]).reduce(
        (carry, [key, value]) => {
          if (ignoreFields.includes(key)) {
            return carry
          }

          return [
            ...carry,
            {
              key: this.$t(`planner.objective.key.${key}`),
              value: this.$t(`planner.objective.value.${key}.${value}`),
              changed: fieldsChanged.includes(key),
            },
          ]
        },
        [],
      )
    },
  },

  mounted() {
    this.getObjectives()
  },

  methods: {
    async getObjectives() {
      this.$refs.objectiveResults.showLoading = true

      try {
        const response = await PlannerService.objective.list({
          user_id: this.userId,
        })
        this.selectedIndex = 0
        this.objectives = response.data.data.objectives
        this.diffs = response.data.data.diffs
        this.userScore = response.data.data.userScore
      } catch (error) {
        const modal = {}
        if (error.response) {
          Object.assign(modal, {
            title: 'Erro ao carregar demográfico do planejador',
            text: Object.values(error.response.data.errors),
            icon: 'error',
          })
        } else {
          Object.assign(modal, {
            title: 'Erro ao carregar demográfico do planejador',
            text: error.message,
            icon: 'error',
          })
        }

        this.$swal({
          ...modal,
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } finally {
        this.$refs.objectiveResults.showLoading = false
      }
    },
    last() {
      this.selectedIndex = 0
    },
    next() {
      this.selectedIndex -= 1
    },
    previous() {
      this.selectedIndex += 1
    },
  },
}
</script>

<style lang="scss" scoped>
.campaign-fields {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  gap: 0.5rem;
}
</style>
