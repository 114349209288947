<template>
  <div class="file-input">
    <h4>Currículo / Catálogo</h4>
    <input
      id="file-input"
      type="file"
      class="d-none"
      @change="handleFileInputChangeAndSave"
    >

    <b-spinner v-if="isLoading" />

    <div v-else>
      <label
        for="file-input"
        class="label-for cursor-pointer text-primary"
        title="Enviar"
      >
        <feather-icon
          size="26"
          icon="EditIcon"
          class="feather change"
        />
      </label>

      <div
        v-if="(user.type === 'company' && user.profile.catalog_url !== '' && user.profile.catalog_url !== null) || user.type === 'representative' && user.profile.curriculum_url !== '' && user.profile.curriculum_url !== null"
        class="d-inline"
      >
        <a
          v-if="user.type === 'company'"
          class="ml-2"
          title="Baixar"
          :href="user.profile.catalog_url"
          download
          target="_blank"
        >
          <feather-icon
            size="26"
            icon="DownloadIcon"
            class="feather change"
          />
        </a>

        <a
          v-if="user.type === 'representative'"
          class="ml-2"
          title="Baixar"
          :href="user.profile.curriculum_url"
          download
          target="_blank"
        >
          <feather-icon
            size="26"
            icon="DownloadIcon"
            class="feather change"
          />
        </a>

        <label
          class="label-for ml-2 cursor-pointer text-primary"
          title="Remover"
          @click="removeFile"
        >
          <feather-icon
            size="26"
            icon="TrashIcon"
            class="feather change"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'
import UserService from '@/services/userService'

export default {
  name: 'AvatarInput',
  components: {
    BSpinner,
  },
  mixins: [],
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      userService: null,
      isLoading: false,
      file: null,
    }
  },
  created() {
    this.userService = new UserService()
  },
  methods: {
    removeFile() {
      this.$swal({
        title: 'Remover arquivo?',
        text: 'Atenção! Esta ação é permanete!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true

          this.userService.removeFile(this.user.id).then(response => {
            if (response.status) {
              this.file = null
              this.$emit('update', this.file)
            }
            this.isLoading = false
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },
    handleFileInputChangeAndSave(e) {
      if (!e.target.files.length) {
        this.file = null
        return false
      }
      this.isLoading = true
      const file = e.target.files[0]
      this.file = file

      this.saveFile(file)
      return true
    },
    saveFile(file) {
      if (this.file == null) {
        return
      }

      const acceptedTypes = [
        'image',
        'pdf',
        'officedocument',
        'msword',
      ]
      const filtered = acceptedTypes.filter(item => file.type.includes(item))

      if (filtered.length === 0) {
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      this.userService.saveFile(this.user.id, formData).then(response => {
        if (response.status) {
          this.file = response.data.data.file
          this.$emit('update', this.file)
        }
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style lang="scss">
.file-input {
}
</style>
