<template>
  <div>
    <b-spinner
      v-if="isLoading"
      small
    />
    <div
      v-else
    >
      <b-badge
        v-for="zone in pillZones"
        :key="zone.id"
        pill
        class="mr-1"
        :variant="{
          'state': 'success',
          'zone': 'warning',
          'city': 'info',
        }[zone.type]"
      >
        {{ zone.name }}
      </b-badge>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import ZoneService from '@/services/zoneService'
import LocalStorageService from '@/services/localStorageService'
import {
  BSpinner,
  BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
    BBadge,
  },
  props: {
    zones: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
      zoneService: null,
      availableZones: [],
    }
  },
  computed: {
    pillZones() {
      return [...new Set(this.zones.map(zone => (
        this.availableZones.find(f => f.id.toString() === zone.toString())
      )).filter(f => f !== undefined))]
    },
  },
  async created() {
    this.isLoading = true
    this.zoneService = new ZoneService()

    await this.zoneService.getAndStoreZones()

    const zones = LocalStorageService.getArray(LocalStorageService.zones)

    this.availableZones = zones.reduce((carryStates, states) => ([
      ...carryStates,
      {
        id: states.initials,
        name: states.name,
        type: 'state',
      },
      ...!states.zones ? [] : states.zones.reduce((carryZones, zone) => ([
        ...carryZones,
        {
          id: zone.id,
          name: zone.name,
          type: 'zone',
        },
        ...!zone.cities ? [] : zone.cities.reduce((carryCities, city) => ([
          ...carryCities,
          {
            id: city.id,
            name: city.name,
            type: 'city',
          },
        ]), []),
      ]), []),
    ]), [])

    this.isLoading = false
  },
}
</script>
