var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (['individual_email', 'manager_sent_payment_link', 'manager_sent_proposal'].includes(_vm.item.type))?_c('div',[_c('div',[_vm._v(" Enviou um e-mail ")]),(_vm.item.extra && _vm.item.extra.subject)?_c('div',[_vm._v(" Assunto: "+_vm._s(_vm.item.extra.subject)+" ")]):_vm._e(),_c('div',[(_vm.item.extra && _vm.item.extra.status)?_c('b-badge',{staticClass:"mr-1",attrs:{"title":_vm.formatDateTimeDbToView(_vm.item.extra.delivered_at),"variant":{
        created: 'primary',
        delivered: 'success',
        sent: 'success',
        error: 'danger',
      }[_vm.item.extra.status]}},[_vm._v(" "+_vm._s(_vm.$t(("emails.columns.status." + (_vm.item.extra.status))))+" ")]):_vm._e(),(_vm.item.extra && _vm.item.extra.mandrill_status)?_c('b-badge',{staticClass:"mr-1",attrs:{"title":_vm.formatDateTimeDbToView(_vm.item.extra.delivered_at),"variant":{
        created: 'primary',
        delivered: 'success',
        sent: 'success',
        error: 'danger',
        reject: 'danger',
      }[_vm.item.extra.mandrill_status]}},[_vm._v(" "+_vm._s(_vm.$t(("emails.columns.mandrill_status." + (_vm.item.extra.mandrill_status))))+" ")]):_vm._e(),(_vm.item.extra && _vm.item.extra.open_at)?_c('b-badge',{staticClass:"mr-1",attrs:{"title":_vm.formatDateTimeDbToView(_vm.item.extra.open_at),"variant":"info"}},[_vm._v(" Aberto ")]):_vm._e(),(_vm.item.extra && _vm.item.extra.click_at)?_c('b-badge',{staticClass:"mr-1",attrs:{"title":_vm.formatDateTimeDbToView(_vm.item.extra.click_at),"variant":"info"}},[_vm._v(" Link Clicado ")]):_vm._e()],1)]):(_vm.item.type === 'manager_sent_internal_search')?_c('div',[_vm._v(" "+_vm._s(_vm.item.annotation)+" "),(_vm.item.extra && _vm.item.extra.search)?_c('span',[_vm._v("(ID da busca: "+_vm._s(_vm.item.extra.search)+")")]):_vm._e()]):(_vm.item.type === 'directly_indicated')?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.annotation)}}),_c('div',[_vm._v(" Zonas ")]),(_vm.item.extra.preferred_zones)?_c('zone-pills',{attrs:{"zones":_vm.item.extra.preferred_zones}}):_vm._e(),_c('div',[_vm._v(" Segmentos ")]),(_vm.item.extra.preferred_segments)?_c('segment-pills',{attrs:{"segments":_vm.item.extra.preferred_segments}}):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(_vm.item.annotation)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }