<template>
  <div>
    <div
      v-if="isLoading"
      class="text-center"
    >
      <b-card>
        <b-spinner />
        <p>Carregando...</p>
      </b-card>

    </div>

    <div
      v-if="!isLoading"
      :class="{
        'pointer-events-none': isSubmiting
      }"
    >
      <b-card>
        <h4>Indicação Direta</h4>
        <b-alert
          v-if="!!lastIndication"
          show
          variant="info"
          class="p-1"
        >
          <strong>Última indicação direta:</strong><br>
          <b-spinner v-if="isLoadingLastIndication" />
          <div v-else>
            {{ lastIndication.thrower.name }} ({{ formatDateTimeDbToView(lastIndication.date) }})
            <br><br>
            <div>
              Zonas
            </div>
            <zone-pills
              v-if="lastIndication.extra && lastIndication.extra.preferred_zones"
              :zones="lastIndication.extra.preferred_zones"
            />
            <div>
              Segmentos
            </div>
            <segment-pills
              v-if="lastIndication.extra && lastIndication.extra.preferred_segments"
              :segments="lastIndication.extra.preferred_segments"
            />
          </div>
        </b-alert>
        <div>
          <div class="text-button">
            <p>
              <span>Regiões</span>
              <b-button
                size="sm"
                :disabled="isSubmiting"
                @click="loadZones($refs.userProfileZones)"
              >
                CARREGAR
              </b-button>
            </p>
            <small class="text-danger">{{ errorsZones }}</small>
          </div>

          <user-edit-zones
            ref="userProfileZones"
            :preferred-zones="preferred_zones"
            :is-submiting="isSubmiting"
            @changed="(v) => preferred_zones = v"
          />
        </div>
        <div>
          <div class="text-button">
            <p>
              <span>Segmentos</span>
              <b-button
                size="sm"
                :disabled="isSubmiting"
                @click="loadSegments($refs.userProfileSegments)"
              >
                CARREGAR
              </b-button>
            </p>
            <small class="text-danger">{{ errorsSegments }}</small>
          </div>

          <user-edit-segments
            ref="userProfileSegments"
            :preferred-segments="preferred_segments"
            :is-submiting="isSubmiting"
            @changed="(v) => preferred_segments = v"
          />
        </div>
        <div class="text-right">
          <b-button
            variant="gradient-primary"
            :disabled="isSubmiting"
            @click="directIndication(false)"
          >
            Buscar
            <b-spinner
              v-if="isSubmiting"
              class="ml-1"
              small
            />
          </b-button>
        </div>
      </b-card>

      <b-modal
        ref="modal-direct-indication"
        title="Indicação Direta"
        ok-title="Sim"
        cancel-title="Não"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        no-close-on-esc
        hide-header-close
        @ok="directIndication(true)"
      >
        <p>
          Você está prestes a fazer essa indicação para {{ affectedUsers }} usuários. Deseja prosseguir?
        </p>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BAlert,
  BButton,
  BCard,
  BSpinner,
} from 'bootstrap-vue'
import LocalStorageService from '@/services/localStorageService'
import UserService from '@/services/userService'
import ZoneService from '@/services/zoneService'
import SegmentService from '@/services/segmentService'
import UserEditZones from '@/views/cms/components/UserEditZones.vue'
import UserEditSegments from '@/views/cms/components/UserEditSegments.vue'
import ZonePills from '@/views/cms/components/ZonePills.vue'
import SegmentPills from '@/views/cms/components/SegmentPills.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { formatDateTimeDbToView } from '@/utils/helpers'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BSpinner,
    UserEditZones,
    UserEditSegments,
    ZonePills,
    SegmentPills,
  },
  data() {
    return {
      formatDateTimeDbToView,

      isLoading: true,
      isSubmiting: false,
      isLoadingLastIndication: false,

      user: null,

      userService: null,
      zoneService: null,

      errorsSegments: '',
      errorsZones: '',

      lastIndication: null,
      preferred_zones: [],
      preferred_segments: [],

      affectedUsers: 0,
    }
  },
  async created() {
    this.isLoading = true

    this.userService = new UserService()
    this.zoneService = new ZoneService()
    this.segmentService = new SegmentService()

    await Promise.allSettled([
      this.getUser(),
      this.zoneService.getAndStoreZones(),
      this.segmentService.getAndStoreSegments(),
    ])

    await this.loadLastIndication()

    this.preferred_zones = this.user.profile.preferred_zones
    this.preferred_segments = this.user.profile.preferred_segments

    this.isLoading = false
  },
  methods: {
    async loadLastIndication() {
      this.isLoadingLastIndication = true
      return this.userService.listActivities(this.user.id, 'directly_indicated').then(response => {
        if (response.status === 200) {
          this.lastIndication = response.data.data.activities.length > 0 ? response.data.data.activities[0] : null
        }

        this.isLoadingLastIndication = false
      })
    },
    getUser() {
      return this.userService.getAllInformations(this.$route.params.id).then(response => {
        const { user } = response.data.data
        this.user = user
      })
    },
    loadZones(ref) {
      const zones = LocalStorageService.getArray(LocalStorageService.zones)
      const state = zones.find(zone => (
        zone.initials.trim().toLowerCase() === this.user.address.state.trim().toLowerCase()
      ))
      if (state) {
        const zone = state.zones.find(stateZone => (
          stateZone.cities.find(city => (
            city.name.trim().toLowerCase() === this.user.address.city.trim().toLowerCase()
          ))
        ))

        if (zone) {
          ref.setZones([
            state.initials,
            zone.id,
          ])
        }
      }
    },
    loadSegments(ref) {
      let userSegs = []
      if (this.user.type === 'representative') {
        const { products_want_to_represent, represented_products } = this.user.profile

        /* eslint-disable camelcase */
        userSegs = [
          ...userSegs,
          ...products_want_to_represent || [],
          ...represented_products || [],
        ]
        /* eslint-enable camelcase */
      } else {
        const { products, company_brands } = this.user.profile

        /* eslint-disable camelcase */
        userSegs = [
          ...userSegs,
          ...products || [],
          ...company_brands || [],
        ]
        /* eslint-enable camelcase */
      }

      const segs = LocalStorageService.getArray(LocalStorageService.segments)

      // Não incluir duplicatas de segmentos
      const matchedSegs = [...new Set(
        // Para cada segmento no perfil do usuário
        userSegs.reduce((carryMatchedSegs, userSeg) => ([
          ...carryMatchedSegs,

          // Procurar nos segmentos existentes
          ...segs.reduce((carrySegs, seg) => ([
            ...carrySegs,
            ...(
              // Que não sejam estejam no primeiro nível
              seg.parent_id !== null
              && (
                // Cujo nome bata com a chave salva
                userSeg.trim().toLowerCase() === seg.name.trim().toLowerCase()
                || (
                  // Ou pelo menos uma das tags bata com a chave salva
                  seg.tags && seg.tags.find(tag => (
                    userSeg.trim().toLowerCase() === tag.trim().toLowerCase()
                  ))
                )
              )
            ) ? [
                // Para cada segmento encontrado, salvar o próprio id e id do segmento pai
                seg.parent_id,
                seg.id,
              ] : [],
          ]), []),
        ]), []),
      )]

      ref.setSegments(matchedSegs)
    },
    directIndication(send) {
      this.isSubmiting = true

      const { preferred_zones, preferred_segments } = this
      const { type } = this.user

      return this.userService.directIndication(this.$route.params.id, {
        send, type, preferred_zones, preferred_segments,
      }).then(async response => {
        if (!send) {
          this.affectedUsers = response.data.data.count
          this.$refs['modal-direct-indication'].show()
        } else if (response.data.status) {
          await this.loadLastIndication()

          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Indicações enviadas com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          this.$refs['modal-direct-indication'].hide()
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }

        this.isSubmiting = false
      }).catch(response => {
        this.isSubmiting = false

        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })
    },
  },
}
</script>
