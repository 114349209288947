<template>
  <b-badge
    pill
    :variant="successSubscriberOrigin.variant"
  >
    <feather-icon
      :icon="successSubscriberOrigin.icon"
      size="16"
    />
    {{ successSubscriberOrigin.text }}
  </b-badge>
</template>

<script>
import {
  BBadge,
} from 'bootstrap-vue'
import {
  roleSuccessSubscriberOrigins,
} from '@/utils/options'

export default {
  components: {
    BBadge,
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      roleSuccessSubscriberOrigins,
    }
  },
  computed: {
    successSubscriberOrigin() {
      const role = roleSuccessSubscriberOrigins.find(f => f.value === this.card.success_subscriber_origin)
      if (!role) {
        return roleSuccessSubscriberOrigins[0]
      }
      return role
    },
  },
}
</script>
