<template>
  <b-table
    fixed
    class="table-responsive-xl"
    :busy="isLoading"
    :items="results"
    :fields="fields"
    no-local-sorting
    show-empty
  >
    <template #empty>
      <h4>Nenhum health score encontrado</h4>
    </template>

    <template #table-busy>
      <div class="text-center table-spinner">
        <b-spinner label="Loading..." />
        <strong>Carregando...</strong>
      </div>
    </template>

    <template #head(date)>
      <div class="text-nowrap">
        Data de<br>
        cálculo
      </div>
    </template>

    <template #cell(date)="row">
      {{ formatDateDbToView(row.item.created_at) }}
    </template>

    <template #head(user)>
      <div class="text-nowrap">
        Usuário
      </div>
    </template>

    <template #cell(user)="row">
      <b-link
        :to="{ name: 'cms-user-form-update', params: { id: row.item.user.id } }"
        target="_blank"
        class="d-flex flex-column align-items-center text-break text-center text-body"
      >
        <b-avatar
          size="40"
          :src="row.item.user.avatar ? row.item.user.avatar.sm : ''"
          variant="light-primary"
        >
          <feather-icon
            v-if="!row.item.user.name"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
        <div>
          ({{ row.item.user.id }})<br>
          {{ row.item.user.name }}
        </div>
      </b-link>
    </template>

    <template #head(cs_contact)>
      <div class="text-nowrap">
        GC
        <feather-icon
          v-b-popover.hover.top="'Gerente de conta fez anotação ou enviou email nos últimos 30 dias'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(cs_contact)="row">
      <b-badge
        v-b-popover.hover.top="'Gerente de conta fez anotação ou enviou email nos últimos 30 dias'"
        :variant="row.item.cs_contact ? 'success' : 'danger'"
      >
        {{ row.item.cs_contact }}/2
      </b-badge>
    </template>

    <template #head(aud)>
      <div class="text-nowrap">
        AUD
        <feather-icon
          v-b-popover.hover.top="'Logou em dois dias diferentes nos últimos 10 dias'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(aud)="row">
      <b-badge
        v-b-popover.hover.top="'Logou em dois dias diferentes nos últimos 10 dias'"
        :variant="row.item.aud ? 'success' : 'danger'"
      >
        {{ row.item.aud }}/2
      </b-badge>
    </template>

    <template #head(connections)>
      <div class="text-nowrap">
        MSG
        <feather-icon
          v-b-popover.hover.top="'Enviou duas mensagens nos últimos 7 dias'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(connections)="row">
      <b-badge
        v-b-popover.hover.top="'Enviou duas mensagens nos últimos 7 dias'"
        :variant="row.item.connections ? 'success' : 'danger'"
      >
        {{ row.item.connections }}/1
      </b-badge>
    </template>

    <template #head(advanced_tools)>
      <div class="text-nowrap">
        FA
        <feather-icon
          v-b-popover.hover.top="'Usa pelo menos duas ferramentas avançadas (Bot de Resposta, Mensagem Padrão e Favoritos)'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(advanced_tools)="row">
      <b-badge
        v-b-popover.hover.top="'Usa pelo menos duas ferramentas avançadas (Bot de Resposta, Mensagem Padrão e Favoritos)'"
        :variant="row.item.advanced_tools ? 'success' : 'danger'"
      >
        {{ row.item.advanced_tools }}/1
      </b-badge>
    </template>

    <template #head(recurrences)>
      <div class="text-nowrap">
        REC
        <feather-icon
          v-b-popover.hover.top="'Recorrências (a partir da segunda recorrência, com gap 30 dias para boleto/PIX)'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(recurrences)="row">
      <b-badge
        v-b-popover.hover.top="'Recorrências (a partir da segunda recorrência, com gap 30 dias para boleto/PIX)'"
        :variant="row.item.recurrences ? 'success' : 'danger'"
      >
        {{ row.item.recurrences }}/2
      </b-badge>
    </template>

    <template #head(messages_read)>
      <div class="text-nowrap">
        SED
        <feather-icon
          v-b-popover.hover.top="'Acima de 70% de leitura de mensagens nos últimos 10 dias'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(messages_read)="row">
      <b-badge
        v-b-popover.hover.top="'Acima de 70% de leitura de mensagens nos últimos 10 dias'"
        :variant="row.item.messages_read ? 'success' : 'danger'"
      >
        {{ row.item.messages_read }}/1
      </b-badge>
    </template>

    <template #head(best_ranking)>
      <div class="text-nowrap">
        RKG
        <feather-icon
          v-b-popover.hover.top="'Ranking acima da maioria'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(best_ranking)="row">
      <b-badge
        v-b-popover.hover.top="'Ranking acima da maioria'"
        :variant="row.item.best_ranking ? 'success' : 'danger'"
      >
        {{ row.item.best_ranking }}/1
      </b-badge>
    </template>

    <template #head(add_ons)>
      <div class="text-nowrap">
        SAD
        <feather-icon
          v-b-popover.hover.top="'Contratou algum serviço adicional (Turbine seu perfil, Super conexão ou botão Whats)'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(add_ons)="row">
      <b-badge
        v-b-popover.hover.top="'Contratou algum serviço adicional (Turbine seu perfil, Super conexão ou botão Whats)'"
        :variant="row.item.add_ons ? 'success' : 'danger'"
      >
        {{ row.item.add_ons }}/1
      </b-badge>
    </template>

    <template #head(email_opening)>
      <div class="text-nowrap">
        AE
        <feather-icon
          v-b-popover.hover.top="'Abriu algum email nos últimos 10 dias'"
          icon="InfoIcon"
          size="16"
        />
      </div>
    </template>

    <template #cell(email_opening)="row">
      <b-badge
        v-b-popover.hover.top="'Abriu algum email nos últimos 10 dias'"
        :variant="row.item.email_opening ? 'success' : 'danger'"
      >
        {{ row.item.email_opening }}/1
      </b-badge>
    </template>

    <template #cell(score)="row">
      <b-badge
        :variant="row.item.score ? 'success' : 'danger'"
      >
        {{ row.item.score }}/12
      </b-badge>
    </template>

    <template #head(actions)>
      <div class="text-nowrap">
        Ações
      </div>
    </template>

    <template #cell(actions)="row">
      <b-button
        variant="primary"
        size="sm"
        @click="toggleDetails(row)"
      >
        <feather-icon
          icon="ChevronDownIcon"
          size="16"
          class="align-middle text-body"
        />
      </b-button>
    </template>

    <template #row-details="row">
      <health-score-list
        :is-loading="!row.item.latestHealthScores"
        :results="row.item.latestHealthScores"
        :fields="[
          { key: 'date', sortable: false, thStyle: 'vertical-align: middle; width: 150px;' },
          { key: 'cs_contact', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'aud', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'connections', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'advanced_tools', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'recurrences', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'messages_read', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'best_ranking', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'add_ons', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'email_opening', sortable: false, thStyle: 'vertical-align: middle;' },
          { key: 'score', sortable: false, thStyle: 'vertical-align: middle; white-space: nowrap;' },
        ]"
      />
    </template>
  </b-table>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BLink,
  BSpinner,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import { formatDateDbToView } from '@/utils/helpers'
import UserService from '@/services/userService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const components = {
  BBadge,
  BAvatar,
  BButton,
  BLink,
  BSpinner,
  BTable,
}

export default {
  name: 'HealthScoreList',
  components,
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    results: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    formatDateDbToView,
  }),

  methods: {
    toggleDetails(row) {
      UserService.getHealthScoreFromUser({
        user_id: row.item.user.id,
      }).then(response => {
        if (response.data !== null) {
          this.$set(this.results, row.index, {
            ...this.results[row.index],
            pages: response.data.data.health_scores,
            latestHealthScores: response.data.data.health_scores.data,
          })
        }
      }).catch(response => {
        if (response.response.data.errors) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ops!',
              icon: 'BellIcon',
              variant: 'danger',
              text: response.response.data.errors.join('\n'),
            },
          })
        }
      })

      row.toggleDetails()
    },
  },
}
</script>
