<template>
  <div>
    <b-tab
      id="tab-mod-sucesso"
      title="Ferr. Sucesso"
    >
      <template #title>
        <feather-icon
          icon="ToolIcon"
          size="18"
          class="mr-50"
        />
        Ferr. Sucesso
      </template>
    </b-tab>

    <b-tab
      id="tab-internal-search"
      title="Busca Interna"
      lazy
    >
      <template #title>
        <span class="pl-1">Busca Interna</span>
      </template>

      <internal-search />
    </b-tab>

    <b-tab
      id="tab-direct-indication"
      lazy
    >
      <template #title>
        <span class="pl-1">Indicação Direta</span>
      </template>

      <direct-indication />
    </b-tab>

    <b-tab
      id="tab-interest-reinforcement"
      lazy
    >
      <template #title>
        <span class="pl-1">Ref. de Interesse</span>
      </template>

      <interest-reinforcement />
    </b-tab>
  </div>
</template>

<script>
import {
  BTab,
} from 'bootstrap-vue'
import InternalSearch from './InternalSearch.vue'
import DirectIndication from './DirectIndication.vue'
import InterestReinforcement from './InterestReinforcement.vue'

export default {
  components: {
    BTab,
    InternalSearch,
    DirectIndication,
    InterestReinforcement,
  },
}
</script>
